import React, { useState,useRef ,useEffect} from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  CardFooter,
  CardBody,
  Card,
  CardHeader,
} from "antstrap";
import { USERTYPE } from "./constants";

export const TicketInputMessage = (props) => {
  const postNoteRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const ticket = props.ticket;
  const [ticketMessage, setTicketMessage] = useState("");

  const toggle = () => setIsOpen(!isOpen);

  const handleReply = () => {
   props.handleReply(ticketMessage)
    setTicketMessage("");
  };

  useEffect(() => {
		console.log(postNoteRef.current.clientHeight);
		postNoteRef.current.style.height = "60px";
		postNoteRef.current.style.height = `${postNoteRef.current.scrollHeight}px`;
	}, [ticketMessage]);
  return (
    <Card className="mt-3 mb-5">
      <CardHeader className="d-flex py-0">
        {/* <i className="material-icons mr-2">reply</i>
        <p className="mb-0 mt-2">
          Replying to{" "}
          <b>
            {ticket
              ? ticket.userType == USERTYPE.SUBCRIBERUSER
                ? ticket.subscriberUser.firstName +
                  " " +
                  ticket.subscriberUser.lastName
                : ticket.organizationUser.firstName +
                  " " +
                  ticket.organizationUser.lastName
              : "Anonymous"}
          </b>
        </p> */}
      </CardHeader>

      <CardBody>
        <FormGroup row>
          <Col sm={12}>
          <div className="d-flex input-area ">
						<FormGroup>
							<Input
								innerRef={postNoteRef}
								type="textarea"
								name="text"
								id="exampleText"
								placeholder="Type your comment here"
							  value={ticketMessage}
              onChange={(e) => setTicketMessage(e.target.value)}
							/>
						</FormGroup>
            </div>
           
          </Col>
        </FormGroup>
        {/* <Row>
          <div className="badge badge-ant ml-3 mb-2">Lorem ipsm.pdf(10kb)</div>
        </Row> */}
        <Row>
          <div className="col-md-4 no-gutters">
            <Button color="primary"  onClick={handleReply}>
              {props.isPrivateNote?"Save":"Reply"}
            </Button>
            <Button color="link" className="py-0">
              <i className="material-icons mr-2">attachment</i>
            </Button>
          </div>
          <div className="col-md-8">
           
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};
