import React, { useState } from "react";
import EditAddress from "./edit-address";
import ViewAddress from "./view-address";
import SectionCard from "../section-card";

const AddressCard = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setIsEdit(!isEdit);
  return (
    <SectionCard toggle={toggle} title={"Address"} isEdit={isEdit}>
      {isEdit ? (
        <EditAddress {...props} toggle={toggle} />
      ) : (
        <ViewAddress {...props} />
      )}
    </SectionCard>
  );
};

export default AddressCard;
