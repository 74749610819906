
export const TICKET_STATUS = {
    NEW: 1,
    OPEN: 2,
    ONHOLD: 3,
    SOLVED: 4,
    CLOSED: 5
}

export const TICKET_STATUS_TEXT = {
  [TICKET_STATUS.NEW]: "new",
  [TICKET_STATUS.OPEN]: "open",
  [TICKET_STATUS.ONHOLD]: "on-hold",
  [TICKET_STATUS.SOLVED]: "solved",
  [TICKET_STATUS.CLOSED]: "closed",
};
