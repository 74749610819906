import React, { useEffect, useState } from "react";
import { Row, Col } from "antstrap";
import ChatUserListContainer from "./user-list/chat-user-list-container";
import ChatHeader from "./chat-header";
import ChatConversation from "./chat-conversation";
import ChatMessage from "./chat-message";
import { UserInfo } from "./user-info";
import { useSetActiveChat, useSetupChat } from "../../redux/actions/chat";
import { useSelector } from "react-redux";

export const Chat = () => {
	const activeChat = useSelector(state => state.activeChat);
	const [user, setUser] = useState(null);
	const [isShowUser, showUser] = useState(true);
	const currentUser = useSelector((state) => state.user);
	const [message, setMessage] = useState("Hello!!");
	const setActiveChat = useSetActiveChat();
	const getMessage = () => {
		return message
	}
	const [userInfo, setToggleUserInfo] = useState(true);
	const [activeTab, setActiveTab] = useState(1)
	const setupChatMethod = useSetupChat(setActiveTab, getMessage, currentUser._id, setMessage);
	const toggleUserInfo = () => {
		setToggleUserInfo(!userInfo);
	};
	const setupChat = () => {
		console.log(user);
		let userIds = [currentUser._id, user._id];

		setupChatMethod.setupChat({
			variables: {
				userId: currentUser._id,
				userIds: userIds,
			},
		});
	};

	useEffect(() => {
		if (activeTab == 1) {
			setToggleUserInfo(false)
		} else {
			setToggleUserInfo(true)
		}
	}, [activeTab])

	const closeChat = () => {
		setUser(null);
		setActiveChat(null)
		setToggleUserInfo(false)
	}

	useEffect(() => {

		setToggleUserInfo(false)
	}, [activeChat])

	if (currentUser && currentUser._id) {
		return (
			<Row className="chatWrapper">
				<ChatUserListContainer
					setUser={setUser}
					currentUser={currentUser}
					chat={activeChat}
					setActiveTab={setActiveTab}
					activeTab={activeTab}
					user={user}
				/>
				<Col className="mainContent chat-conversation-wrapper border-left">
					{activeChat != null || user != null ? (
						<>
							<Row className="chat-window border-right">
								<Col className=" border-right">
									<Row className="chat-user-header ">
										<ChatHeader
											toggleUserInfo={toggleUserInfo}
											chat={activeChat}
											userInfo={userInfo}
											currentUser={currentUser}
											user={user}
											activeTab={activeTab}
											closeChat={closeChat}
										/>
									</Row>
									<ChatConversation
										showUser={showUser}
										toUser={user}
										currentUser={currentUser}
										chat={activeChat}
										settingUpChatLoading={setupChatMethod.loading}

									/>
									<ChatMessage
										setupChat={setupChat}
										chatId={activeChat ? activeChat._id : null}
										currentUser={currentUser}
									/>
								</Col>

								{(userInfo && user != null) ||
									(userInfo && activeChat != null) ? (
									<Col className="chat-user-info p-0">
										<UserInfo user={user} chat={activeChat} currentUser={currentUser} toggleUserInfo={toggleUserInfo} />{" "}
									</Col>
								) : null}
							</Row>
						</>
					) : null}
				</Col>
			</Row>
		);
	} else {
		return <></>;
	}
};
