import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Button, Card, CardHeader, CardBody } from "antstrap";
import * as constants from "./ticket-constants";
import { Form } from "reactstrap";
import { USERTYPE, PRIORITY, generateTicketNUmberToDisplay } from "./constants";
import moment from "moment";
import { useSelector } from "react-redux";
export const SingleTicketCard = ({
	ticket,
	toggle,
	isRedirect,
	type,
	isSelfTicket,
}) => {
	let statusClass = `status-banner solved ${
		ticket.status && constants.TICKET_STATUS_TEXT[ticket.status]
	}`;
	const helpdeskCategories = useSelector((state) => state.helpdeskCategories);
	let history = useHistory();
	const openTicket = () => {
		if (isSelfTicket) {
			if (isRedirect) history.push(`/basic/my-space/${type}/${ticket._id}`);
		} else {
			if (isRedirect) history.push(`/basic/ticket/${type}/${ticket._id}`);
		}
	};
	return (
		<Card className="mb-3 ticket cursor-pointer" onClick={openTicket}>
			<CardHeader>
				<Row className="ticket-header">
					<div className="ticket-banner">
						<Col sm="12">
							<Row className="ticket-number align-items-center justify-content-center">
								{/* <p className="ml-4 mb-0">#KIT000321</p> */}
								<p className=" mb-0">{`${generateTicketNUmberToDisplay(
									ticket.ticketNumber
								)}`}</p>
							</Row>
							<Row>
								<div className={`${statusClass} text-capitalize`}>
									{ticket.status && constants.TICKET_STATUS_TEXT[ticket.status]}
								</div>
							</Row>
						</Col>
					</div>
					<div className="ticket-body">
						<Row>
							<p className="ticket-title font-weight-bold mb-0">
								{ticket.title}
							</p>
						</Row>
						<Row>
							<p className="mb-0 text-secondary">{ticket.description}</p>
						</Row>
					</div>
					<div className="ticket-actions ">
						<p className="mb-0 small text-secondary">
							{moment(new Date(Number(ticket.createdAt))).format("lll")}
						</p>
						{/* <Link to={`/basic/ticket/${ticket._id}`}> */}
						{/* {ticket.status < 5 ? (
							<Button outline color="primary" onClick={toggle}>
								<FormattedMessage id="edit" />
							</Button>
						) : null} */}
						{/* </Link> */}
					</div>
				</Row>
			</CardHeader>
			<CardBody className=" p-0 ticket-footer border-top no-gutters">
				<TicketFooterSection
					label={<FormattedMessage id="assigned_to" />}
					value={
						ticket && ticket.assignedUser != null
							? ticket.assignedUser.firstName +
							  " " +
							  ticket.assignedUser.lastName
							: "--"
					}
					size={"medium"}
					thumbnail={true}
				/>
				<TicketFooterSection
					label={<FormattedMessage id="raised_by" />}
					value={
						ticket.userType == USERTYPE.SUBCRIBERUSER
							? ticket.subscriberUser.firstName +
							  " " +
							  ticket.subscriberUser.lastName
							: ticket.organizationUser.firstName +
							  " " +
							  ticket.organizationUser.lastName
					}
					size={"medium"}
					thumbnail={true}
				/>
				<TicketFooterSection
					label={<FormattedMessage id="priority" />}
					value={PRIORITY[ticket.priority]}
					size={"sml"}
				/>
				<TicketFooterSection
					label={"Category"}
					value={
						ticket.category &&
						ticket.categoryDetails &&
						ticket.categoryDetails._id
							? ticket.categoryDetails.name
							: "--"
					}
					size={"large"}
				/>
				<TicketFooterSection
					label={<FormattedMessage id="due_date" />}
					value={
						ticket.dueDate == null
							? "--"
							: moment(new Date(Number(ticket.dueDate))).format("ll")
					}
					size={"sml"}
				/>
				{isSelfTicket ? null : <TicketFooterEditSection toggle={toggle} />}
			</CardBody>
		</Card>
	);
};

const TicketFooterSection = ({ label, value, size, thumbnail }) => {
	return (
		<div className={"ticket-footer-section border-right " + size}>
			{thumbnail ? (
				<img
					className="image-rounded mr-1 align-self-center"
					src={require("assets/images/no-photo.svg")}
				/>
			) : null}
			<div className="d-flex flex-column p-1 py-2">
				<small className="text-secondary">{label}</small>
				<p className="mb-0 small font-weight-bold value">{value}</p>
			</div>
		</div>
	);
};

const TicketFooterEditSection = ({ label, value, size, toggle }) => {
	return (
		<div className={"ticket-footer-section xsm"}>
			<div className="d-flex ">
				<div>
					<Button color="link" className="link">
						<i className="material-icons" onClick={toggle}>
							edit
						</i>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default TicketFooterSection;
