import { useDispatch } from "react-redux";
import { getSocket, SocketEvents } from "../services/socket-service";
import { hideNotification, showNotification } from "./redux-actions/notification";
import { READ_CHAT, RECEIVE_MESSAGE, RECEIVE_MESSAGE_DELIVERED_STATUS, RECEIVE_MESSAGE_READ_STATUS, RECEIVE_MESSAGE_SENT_STATUS, SEND_DELIEVRED_ALL_RECEIPT, SEND_READ_ALL_RECEIPT, SET_USER_ONLINE } from "./types";

let socket = getSocket()

export const useSocketListener = () => {
  const dispatch = useDispatch()
  return () => {

    if (!socket) {
      socket = getSocket()
    }

    socket.on(SocketEvents.RECEIVE_MESSAGE, (data) => {
      dispatch({
        type: RECEIVE_MESSAGE,
        payload: {
          chatId: data.chatId,
          message: data.message,
          chatReports: data.chatReports
        },
      });

      socket.emit(SocketEvents.SEND_DELIVERY_RECEIPT, { messageId: data.message._id, userId: data.message.userId });

      dispatch(showNotification({ name: data.user.name, chatId: data.chatId, message: data.message }))
      // dispatch(hideNotification())
      console.log(data)
    });

    socket.on(SocketEvents.SEND_SENT_RECEIPT, (data) => {
      dispatch({
        type: RECEIVE_MESSAGE_SENT_STATUS,
        payload: {
          chatId: data.chatId,
          message: data.message
        },
      });
    });

    socket.on(SocketEvents.SEND_READ_RECEIPT, (data) => {
      dispatch({
        type: RECEIVE_MESSAGE_READ_STATUS,
        payload: {
          chatId: data.chatId,
          messageId: data.messageId
        },
      });
    });

    socket.on(SocketEvents.RECEIVE_DELIVERY_RECEIPT, (data) => {
      dispatch({
        type: RECEIVE_MESSAGE_DELIVERED_STATUS,
        payload: {
          chatId: data.chatId,
          messageId: data.messageId
        },
      });
    });

    socket.on(SocketEvents.SEND_DELIVERY_RECEIPT, (data) => {
      dispatch({
        type: SEND_DELIEVRED_ALL_RECEIPT,
        payload: {
          chatId: data.chatId
        },
      });
    });



    socket.on(SocketEvents.SEND_READ_ALL_RECEIPT, (data) => {
      dispatch({
        type: SEND_READ_ALL_RECEIPT,
        payload: {
          chatId: data.chatId
        },
      });
    });

    socket.on(SocketEvents.USER_ONLINE, (data) => {
      console.log("SDsdfdfsdfsdfsd Dhiraj Kate", data)
      dispatch({
        type: SET_USER_ONLINE,
        payload: {
          userId: data.userId,
          isOnline: data.isOnline
        },
      });

      console.log(data)
    });


  };
};

export const useSendMessageHandler = () => {
  const dispatch = useDispatch()
  return (message, userName) => {
    message.createdAt = new Date().getTime()
    socket.emit(SocketEvents.SEND_MESSAGE, { message, userName });

    dispatch({
      type: RECEIVE_MESSAGE,
      payload: {
        chatId: message.chatId,
        message: message,
      },
    });
  }

}

export const useReadChatHandler = () => {
  const dispatch = useDispatch()
  return (chatId, userId) => {
    socket.emit(SocketEvents.READ_CHAT, { chatId, userId });
    dispatch({
      type: READ_CHAT,
      payload: {
        chatId,
        userId,
      },
    });
  }

}

export const useReadMessageHandler = () => {
  // const dispatch = useDispatch()
  return (messageId, userId) => {
    socket.emit(SocketEvents.READ_MESSAGE, { messageId, userId });
  }

}

export const useDeliveredMessagesHandler = () => {
  // const dispatch = useDispatch()
  return (chatId,userId) => {
    socket.emit(SocketEvents.SEND_DELIVERY_REPORT, { chatId,userId });
  }

}

//work in progress. this method is incomplete and need to check while implementing read receipt
// export const useClearUnreadCount = () => {
//   const dispatch = useDispatch()
//   return (message, userName) => {
//     socket.emit(SocketEvents.SEND_DELIVERY_REPORT, { message, userName });
//     message.createdAt = new Date()
//     dispatch({
//       type: RECEIVE_MESSAGE,
//       payload: {
//         chatId: message.chatId,
//         message: message,
//       },
//     });
//   }

// }

export const useClearSocketConnection = () => {
  return () => {
    if (!socket) {
      socket = getSocket()
    }


    socket.disconnect();
  };
};