import { Button, Col, Row } from "antstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { USERTYPE } from "./constants";

export const TicketSideMenu = ({ ticket }) => {
  return (
    <Row className="m-auto  bg-white py-3 helpdesk-user-info">
      <Col sm="12">
        <p className={"text-uppercase section-header"}>
          <FormattedMessage id="raised_by" />
        </p>
      </Col>
      <Col sm="9" className="mx-auto text-center">
        <Row>
          <Col sm="12">
            <img
              className="rounded-circle mx-auto col-sm-8"
              src={require("assets/images/no-photo.svg")}
            />
            <h6 className="font-weight-bold mt-2 mb-0">
              {" "}
              {ticket
                ? ticket.userType == USERTYPE.SUBCRIBERUSER
                  ? ticket.subscriberUser.firstName +
                    " " +
                    ticket.subscriberUser.lastName
                  : ticket.organizationUser.firstName +
                    " " +
                    ticket.organizationUser.lastName
                : ""}
            </h6>
            <p>
              {ticket ? (
                ticket.userType == USERTYPE.ORGANIZATIONUSER ? (
                  <FormattedMessage id="orgUser" />
                ) : (
                  <FormattedMessage id="subscriber" />
                )
              ) : (
                ""
              )}
            </p>
          </Col>

          <Col sm="6">
            <h6 className="font-weight-bold mt-2 mb-0">2</h6>
            <p>
              <FormattedMessage id="requests" />
            </p>
          </Col>
          <Col sm="6">
            <h6 className="font-weight-bold mt-2 mb-0">0</h6>
            <p>
              <FormattedMessage id="complaints" />
            </p>
          </Col>
          <Col sm="12">
            <Button color="link">
              <FormattedMessage id="view_all" />
            </Button>
          </Col>
        </Row>
      </Col>
      <Row className="ml-0 border-top p-3">
        <p className="section-header">PROXIMA PROFILE</p>
        <div class="d-flex w-100 section-profile-item">
          <i className="material-icons mr-2">email</i>
          <p >
            {" "}
            {ticket
              ? ticket.userType == USERTYPE.SUBCRIBERUSER
                ? ticket.subscriberUser.email
                : ticket.organizationUser.email
              : ""}
          </p>
        </div>
        <div class="d-flex w-100 section-profile-item">
          <i className="material-icons mr-2">local_phone</i>
          <p >
            {" "}
            {ticket
              ? ticket.userType == USERTYPE.SUBCRIBERUSER
                ? ticket.subscriberUser.fullNumber?ticket.subscriberUser.fullNumber:"NA"
                : ticket.organizationUser.fullNumber?ticket.organizationUser.fullNumber:"NA"
              : ""}
          </p>
        </div>
      </Row>
    </Row>
  );
};
