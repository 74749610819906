import {
	Button,
	Col,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Label,
	FormGroup
} from "antstrap";
import React, { useState } from "react";
import { useEffect } from "react";
import { useEditHelpdeskCategory } from "../../../redux/actions/helpdesk-categories";
const EditCategory = (props) => {
	const [categoryName, setCategoryName] = useState("");
	useEffect(()=>{
		setCategoryName(props.category?props.category.name:"")
	},[props.category])
	const editCategoryMethod = useEditHelpdeskCategory()
	const onEditCategory = () => {
		if (categoryName.trim() != "") {
			editCategoryMethod.editHelpdeskCategory({
			    variables: {
			      name: categoryName,
			      categoryId: props.category._id,
			    },
			  });
			props.toggle();
		}
	};
	return (
		<Modal
			centered
			isOpen={props.isOpen}
			toggle={props.toggle}
			className="ml-modal custom-modal"
		>
			<ModalHeader  toggle={props.toggle}>
				Edit Category
			</ModalHeader>

			<ModalBody className="py-1">
				<Row className="pt-4">
					<Col sm="12">
						<FormGroup>
						<Label>Category Name</Label>

						<Input
							type="text"
							defaultValue={categoryName}
							onChange={(e) => setCategoryName(e.target.value)}
						/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>

			<ModalFooter >
				<Button
					color="primary"
					onClick={() => onEditCategory()}
					disabled={ categoryName === ""}
				>
					Save
				</Button>{" "}
				<Button color="link" onClick={props.toggle}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EditCategory;
