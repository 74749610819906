import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "antstrap";
import React, { Fragment, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useFetchActivities } from "redux/actions/graphql-action";
import { useFetchRoles, useRemoveRole } from "../../../redux/actions/roles";
import MenuButton from "../../common/menu-button";
import CreateRoleModal from "./create-role-modal";
import EditRoleModal from "./edit-role-modal";

const ConfirmationModal = (props) => {
  return (
    <Modal centered isOpen={props.isOpen} toggle={props.toggle} className="ml-modal custom-modal">
      <ModalHeader toggle={props.toggle}>
        Delete
      </ModalHeader>

      <ModalBody className=" py-1">
        Are you sure you want to delete "{props.role?props.role.name:""}" role?
      </ModalBody>

      <ModalFooter >
        <Button color="primary" onClick={props.onConfirm}>
          Delete
        </Button>{" "}
        <Button color="link" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export const RolesData = ({ roles, activitiesList, onUpdate, intl }) => {
  const [modal, setModal] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const toggle = () => setModal(!modal);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const toggleConfirmationModal = () =>
    setIsConfirmationModalOpen(!isConfirmationModalOpen);

  const { removeRole } = useRemoveRole();

  const handleDelete = () => {
    removeRole({
      variables: {
        roleId: currentRole._id,
      },
    });
    setCurrentRole(null);
    toggleConfirmationModal();
  };
  useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
  //TODO what is users?
  return (
    <Fragment>
      <Table className="px-2 border table-antstrap">
        <thead className="bg-light">
          <tr>
            <th>ROLE NAME</th>
            <th>USERS</th>
            <th>PERMISSIONS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(roles) &&
            roles.map((role) => {
              return (
                <tr>
                  <td>{role.name}</td>
                  <td>1</td>
                  <td>
                    {role.activities &&
                      role.activities
                        .map((activity) => {
                          return activitiesList[activity]
                            ? `${intl.formatMessage({
                                id: activitiesList[activity].name,
                              })} ${intl.formatMessage({
                                id: activitiesList[activity].featureName,
                              })}`
                            : "";
                        })
                        .join(",")}
                  </td>
                  <td>
                    {role && !role.isSuperAdmin && (
                      <MenuButton
                        menuButtons={[
                          {
                            name: "Edit",
                            onClick: () => {
                              setCurrentRole(role);
                              toggle();
                            },
                          },
                          {
                            name: "Delete",
                            onClick: () => {
                              setCurrentRole(role);
                              toggleConfirmationModal();
                            },
                          },
                         
                        ]}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <EditRoleModal currentRole={currentRole} isOpen={modal} toggle={toggle} />
      <ConfirmationModal
         role={currentRole}
        isOpen={isConfirmationModalOpen}
        toggle={toggleConfirmationModal}
        onConfirm={handleDelete}
      />
    </Fragment>
  );
};

const UserRoles = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const activities = useSelector((state) => state.activities);
  const [transformActivities, setTrasnsformedActivities] = useState({});
  const { fetchRoles } = useFetchRoles();
  const fetchActivities = useFetchActivities();

  useEffect(() => {
    fetchActivities.fetchActivities();
    fetchRoles();
  }, []);

  const handleUpdate = (role) => {
    setModal(!modal);
  };

  useEffect(() => {
    let tempActivities = {};
    activities &&
      activities.map((activity) => {
        tempActivities[activity._id] = activity;
      });
    setTrasnsformedActivities(tempActivities);
  }, [activities]);

  const roles = useSelector((state) => state.roles);

  useEffect(()=>{
    roles.sort((a,b)=>{
      if(a.createdAt<b.createdAt){
        return -1
      }
      return 1
    })


  },[roles])

  return (
    <div>
      <Row className="align-items-baseline mb-3">
        <Col sm="9">
          <h2>Role Management</h2>
          <p>Manage user roles</p>
        </Col>
        <Col sm="3" className="text-right ">
          <Button color="primary" onClick={toggle}>
            Create Role
          </Button>
        </Col>
      </Row>
      <RolesData
        {...props}
        roles={roles}
        activitiesList={transformActivities}
        onUpdate={handleUpdate}
      />

      {modal ? <CreateRoleModal isOpen={modal} toggle={toggle} /> : void 0}
    </div>
  );
};

export default injectIntl(UserRoles);
