import React from "react";
import { Row, Col, CardText } from "antstrap";

const ViewAbout = ({ about }) => {
  return (
    <Row>
      <Col sm="12">
        <CardText>{about}</CardText>
      </Col>
    </Row>
  );
};

export default ViewAbout;
