import { Input } from "antstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEditChildSubOrganization } from "../../../redux/actions/sub-organization";

const EditChildSubOrganization = (props) => {
  let { subOrganization } = props;
  const [name, setName] = useState(subOrganization.name);
  const { updateChildSubOrganization } = useEditChildSubOrganization(
    props.toggle
  );
  const updateChildSubOrg = () => {
    updateChildSubOrganization({
      variables: {
        subOrganizationId: props.subOrganizationId,
        childSubOrganization: {
          _id: subOrganization._id,
          name,
          description: "",
        },
      },
    });
  };

  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle} className={"custom-modal"}>
      <ModalHeader toggle={props.toggle}>
        <FormattedMessage
          id="subOrganization.edit.title"
          defaultMessage="Edit Sub Organization"
        />
      </ModalHeader>
      <ModalBody>
        <Input
          type="text"
          value={name}
          maxLength={250}
          onChange={(e) => setName(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={updateChildSubOrg}>
          <FormattedMessage id="common.save" defaultMessage="Save " />
        </Button>{" "}
        <Button color="link" onClick={props.toggle}>
          <FormattedMessage id="cancel" defaultMessage="Cancel " />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditChildSubOrganization;
