import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col, Row } from "reactstrap";

const DashboardHeader = (props) => {
  const { toggle, subOrganizationLabel } = props;
  return (
    <>
      <Row className="align-items-baseline">
        <Col sm="9">
          <h2>
            <FormattedMessage
              id="subOrganization"
              defaultMessage="Sub Organizations"
            />
          </h2>
          <p>
            <FormattedMessage
              id="subOrganization.hierarchy"
              defaultMessage="Sub organizations define your organization hierarchy"
            />
          </p>
        </Col>
        <Col sm="3" className="text-right">
          <Button outline color="primary" onClick={props.toggleConfigureModal}>
            <FormattedMessage id="configure" defaultMessage="Configure" />
          </Button>
        </Col>
      </Row>
      <Row className="align-items-baseline mt-4">
        <Col sm="8">
          <h3>{subOrganizationLabel.plural}</h3>
          <p>
            <FormattedMessage
              id="subOrganization.manage"
              defaultMessage="Manage your {subOrganizationLabel}."
              values={{
                subOrganizationLabel:subOrganizationLabel.plural,
              }}
            />
          </p>
        </Col>

        <Col sm="4" className="text-right">
          <Button color="primary" onClick={toggle}>
            <FormattedMessage id="create" defaultMessage="Create" />{' '}
            <FormattedMessage
              id="{subOrganizationLabel}"
              defaultMessage="{subOrganizationLabel}"
              values={{
                subOrganizationLabel: subOrganizationLabel.name,
              }}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DashboardHeader;
