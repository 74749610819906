import { gql } from "apollo-boost";

export const FETCH_SUBSCRIBERS = gql`
  query fetchSubscribersByStatus($status: Int) {
    fetchSubscribersByStatus(status: $status) {
      _id
      organizationId
      userId
      status
      subscribedDate
      user {
        _id
        firstName
        lastName
        image
        countryCode
        mobileNumber
        fullNumber
        email
      }
    }
  }
`;

export const CHANGE_SUBSCRIBER_STATUS = gql`
  mutation changeSubscriberStatus(
    $organizationId: String
    $status: Int
    $userId: String
  ) {
    changeSubscriberStatus(
      organizationId: $organizationId
      status: $status
      userId: $userId
    ) {
      _id
      organizationId
      subscriberList {
        _id
        userId
        status
        subscribedDate
        user {
          firstName
          lastName
          image
          countryCode
          mobileNumber
          email
          isActive
        }
      }
    }
  }
`;
