import { Col, Row } from "antstrap";
import React from "react";
import moment from "moment";
import { useFetchChatByUserIds } from "../../../redux/actions/chat";
import { useSelector } from "react-redux";
import classnames from "classnames";
const SingleUser = (props) => {
	const fetchChatByUserIdsMethod = useFetchChatByUserIds();

	const activeChat = useSelector((state) => state.activeChat);
	const fetchChat = () => {
		let userIds = [props.currentUser._id, props.user.userId];
		fetchChatByUserIdsMethod.fetchChat({
			variables: {
				userIds: userIds,
			},
		});
		props.setUser(props.user.user);
	};
	return (
		<Row
			className={classnames("chat-user-list-item m-0", {
				active:
					(props.user &&
						activeChat &&
						activeChat.userIds.indexOf(props.user.userId) > -1) ||
					(props.activeUser &&
						props.user &&
						props.user.userId == props.activeUser._id),
			})}
			onClick={() => fetchChat()}
		>
			<Col className={"d-flex flex-row flex-1 p-1 left-data"}>
				<div className="chat-thumbnail">
					<img
						className="chat-user-image mr-3"
						src={require("assets/images/no-photo.svg")}
					/>
					<div className={props.user.isOnline ? "online" : "offline"}></div>
				</div>

				<div className="d-flex flex-column ">
					<p className="m-0  user-name text-ellipsis">
						{props.user.user.firstName + " " + props.user.user.lastName}
					</p>
				</div>
			</Col>
			
		</Row>
	);
};

export default SingleUser;
