import React, { useState } from "react";
import { Row, Col, CardText, Button, Form, Input, Label } from "antstrap";
import { FormattedMessage } from "react-intl";
import { useUpdateContact } from "../../../../redux/actions/organization";

const EditContact = (props) => {
  let contactToEdit = { ...props.contact };
  delete contactToEdit.__typename;
  delete contactToEdit.fullNumber;

  const [contact, setContact] = useState(contactToEdit);

  const updateContact = useUpdateContact(contact);

  const onSave = () => {
    updateContact.updateContact();
    props.toggle();
  };

  return (
    <Form>
      <Row>
        <Col sm="12">
          <CardText>
            <Row>
              <Col sm="4">
                <Label>
                  <small>
                    <FormattedMessage
                      id="phone_number"
                      defaultMessage="Phone Number"
                    />
                  </small>
                </Label>
                <Input
                  type="text"
                  value={contact.countryCode}
                  onChange={(e) =>
                    setContact({ ...contact, countryCode: e.target.value })
                  }
                />
              </Col>
              <Col sm="8">
                <Label>
                  <small>
                    <FormattedMessage
                      id="phone_number"
                      defaultMessage="Phone Number"
                    />
                  </small>
                </Label>
                <Input
                  type="text"
                  value={contact.mobileNumber}
                  onChange={(e) =>
                    setContact({ ...contact, mobileNumber: e.target.value })
                  }
                />
              </Col>
            </Row>
          </CardText>

          <CardText>
            <Row>
              <Col sm="4">
                <Label>
                  <small>
                    <FormattedMessage id="email" defaultMessage="Email" />
                  </small>
                </Label>
                <Input
                  type="text"
                  value={contact.email}
                  onChange={(e) =>
                    setContact({ ...contact, email: e.target.value })
                  }
                />
              </Col>
            </Row>
          </CardText>
          <Button color="primary" onClick={() => onSave()}>
            <FormattedMessage id="common.save" defaultMessage="Save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditContact;
