import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Form,
  Input,
} from "antstrap";
import { FormattedMessage } from "react-intl";
const EditUrl = (props) => {
  const [url, setUrl] = useState(props.url);

  const onSave = () => {
    props.toggle();
  };
  return (
    <Form>
      <Row>
        <Col sm="12">
          <CardText>
            <Input
              type="text"
              defaultValue={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </CardText>
          <Button id="save" color="primary" onClick={() => onSave(url)}>
            <FormattedMessage id={"common.save"} defaultMessage="Save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditUrl;
