import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Header from "./header";
import NameCard from "./name";
import AboutCard from "./about";
import AddressCard from "./address";
import LogoCard from "./logo";
import ContactCard from "./contact";
import WebsiteCard from "./website";
import UrlCard from "./url";
import { useFetchOrganizationDetails } from "../../../redux/actions/organization";
import { Col, Row } from "reactstrap";

const OrganizationProfile = (props) => {
  const { fetchOrganizationDetails, loading } = useFetchOrganizationDetails();

  const [profileHeader, setProfileHeader] = useState({
    title: "",
    url: "",
    description: "",
  });
  const [profileContent, setProfileContent] = useState({
    name: "",
    logo: "",
    about: "",
    address: {
      addressLine: "",
      city: "",
      state: "",
      postalCode: "",
    },
    contact: {
      countryCode: "",
      mobileNumber: "",
      email: "",
    },
    website: "",
    url: "",
  });

  const organization = useSelector((state) => state.organization);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(organization).length == 0) {
      fetchOrganizationDetails();
    }
    if (organization) {
      let tempProfileContent = { ...profileContent };

      Object.keys(tempProfileContent).map((item) => {
        tempProfileContent[item] = organization[item];
      });

      setProfileContent(tempProfileContent);
    }
  }, [organization]);

  return (
    <>
    	<Row className="align-items-baseline mb-3" >
					<Col sm="9">
          <h2>Organization Profile</h2>
      <p>Organization profile is the identity of your organization.</p>
					</Col>
					{/* <Col sm="3" className="text-right ">
						<Button color="primary"  onClick={toggle}>
							Create Category
						</Button>
					</Col> */}
				</Row>
      
      {/* <Header {...profileHeader} /> */}

      <NameCard name={profileContent.name} />
      <LogoCard logo={profileContent.logo} />
      <AboutCard about={profileContent.about} />
      <AddressCard address={profileContent.address} />

      <ContactCard contact={profileContent.contact} />
      <WebsiteCard website={profileContent.website} />

      <UrlCard url={profileContent.url} />
    </>
  );
};

export default OrganizationProfile;
