import React, { useState } from "react";
// import LanguageIcon from '@material-ui/icons/Language';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import Avatar from '@material-ui/core/Avatar';
// import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import {
	Collapse,
	NavbarToggler,
	Nav,
	Navbar,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "antstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLogoutUserFromCurrentSession } from "../../redux/actions/user";

const NavBarExample = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const user = useSelector((state) => state.user);
	const history = useHistory();
	const toggle = () => setIsOpen(!isOpen);
	const toggleSidebar = () => {
		document.documentElement.classList.toggle("nav-open");
	};

	const { logoutUser, loading } = useLogoutUserFromCurrentSession();

	return (
		<>
			<div>
				<Navbar color="white" light expand="lg" className="navbar-plain">
					<div className="navbar-wrapper">
						<div
							className={classnames("navbar-toggle", {
								toggled: props.sidebarOpen,
							})}
						>
							<button
								className="navbar-toggler"
								type="button"
								onClick={toggleSidebar}
							>
								<span className="navbar-toggler-bar bar1" />
								<span className="navbar-toggler-bar bar2" />
								<span className="navbar-toggler-bar bar3" />
							</button>
						</div>
					</div>

					{!props.isMobile ?
						<>
							<NavbarToggler onClick={toggle} />

							<Collapse isOpen={isOpen} navbar>
								<Nav className="ml-auto" navbar>
									<UncontrolledDropdown nav inNavbar>
										<DropdownToggle nav caret>
											<div className="d-inline-flex align-items-center ">
												<span style={{ height: 30 }} />
												Language
											</div>
										</DropdownToggle>
										<DropdownMenu className=" shadow bg-light" right>
											{/* <DropdownItem>
                    <div className="d-flex">
                      <div
                        className="d-flex align-items-center"
                        style={{ height: 40, width: 40 }}
                      >
                        <img
                          className="image-rounded mr-1"
                          style={{ width: 35 }}
                          src={require("assets/images/no-photo.svg")}
                        />
                      </div>

                      <div>
                        <p className="mb-0 font-weight-bold">
                          {" "}
                          {user.firstName == "" && user.lastName == ""
                            ? "No Name"
                            : `${user.firstName} ${user.lastName}`}
                        </p>
                        <p className="mb-0 color-light small">{user.email}</p>
                      </div>
                    </div>
                  </DropdownItem>
                  <DropdownItem divider /> */}
											<DropdownItem tag="a" href="user">
												English
											</DropdownItem>
											<DropdownItem>Marathi</DropdownItem>
											{/* <DropdownItem divider />
                  <DropdownItem>LogOut</DropdownItem> */}
										</DropdownMenu>
									</UncontrolledDropdown>
									<UncontrolledDropdown nav inNavbar>
										<DropdownToggle nav caret>
											<div className="d-inline-flex align-items-center ">
												<img
													className="image-rounded mr-1"
													style={{ width: 30 }}
													src={require("assets/images/no-photo.svg")}
												/>

												{user.firstName == "" && user.lastName == ""
													? "No Name"
													: `${user.firstName} ${user.lastName}`}
											</div>
										</DropdownToggle>
										<DropdownMenu className=" shadow bg-light" right>
											<DropdownItem>
												<div className="d-flex align-items-center ">
													<div
														className="d-flex align-items-center mr-1"
														style={{ height: 40, width: 40 }}
													>
														<img
															className="image-rounded mr-1"
															style={{ width: 35 }}
															src={require("assets/images/no-photo.svg")}
														/>
													</div>

													<div className="d-flex  flex-column">
														<p className="mb-0 font-weight-bold">
															{" "}
															{user.firstName == "" && user.lastName == ""
																? "No Name"
																: `${user.firstName} ${user.lastName}`}
														</p>
														<p className="mb-0 color-light small">{user.email}</p>
													</div>
												</div>
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem
												onClick={() =>
													history.push({
														pathname: "/basic/profile",
														state: { tab: 1 },
													})
												}
											>
												View Profile
											</DropdownItem>
											<DropdownItem
												onClick={() =>
													history.push({
														pathname: "/basic/profile",
														state: { tab: 2 },
													})
												}
											>
												Account Settings
											</DropdownItem>
											<DropdownItem divider />
											<DropdownItem onClick={() => logoutUser()}>Logout</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</Nav>
							</Collapse>
						</> : <></>}
				</Navbar>
			</div>
		</>
	);
};

export default NavBarExample;
