import { Link } from "react-router-dom";

export const sectionButtonsDetails = [
  {
    title: "Organization Info",
    description: "See and manage your organization information.",
    buttonText: "Manage Organization Profile",
    link: "/admin/organization-profile",
    icon: require("../../../assets/images/ic_org_dashboard_info.svg")
  },
  {
    title: "Security Settings",
    description:
      "You have full control to manage security settings of your organization.",
    buttonText: "Manage Settings",
    link: "/admin/settings",
    icon: require("../../../assets/images/ic_org_dashboard_security.svg")
  },

  {
    title: "User Roles",
    description: "Setup roles for your internal users and manage their settings.",
    buttonText: "Manage User Roles",
    link: "/admin/user-roles",
    icon: require("../../../assets/images/ic_org_dashboard_user_roles.svg")
  },
  {
    title: "Sub Organizations",
    description: "Sub organizations define your organization hierarchy.",
    buttonText: "Manage Sub Organizations",
    link: "/admin/sub-organizations",
    icon: require("../../../assets/images/ic_org_dashboard_sub_organization.svg")
  },
  // {
  //   title: "Billing History",
  //   description:
  //     "Check out all your payment history. You can also download or print your invoices.",
  //   buttonText: "Payment History",
  //   link: "/admin/organization-profile",
  // },
];
