import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Input,
  Label,
  Button,
  Form,
  Row,
  Col,
  Container,
} from "antstrap";
import { useHistory } from "react-router-dom";
import { LoginCarousel } from "./carousel";
import AuthFooter from "./auth-footer";

export const AuthResetPassword = () => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const onSignIn = () => {};
  let feedback = "";
  if (error) {
    feedback = (
      <div className="invalid-feedback d-block mb-2">
        Please enter a valid email and password combination
      </div>
    );
  } else feedback = "";

  return (
    <Row className="min-h-100 no-gutters ">
      <Col sm={12} md={12} lg={8} className="m-auto align-self-center py-4">
      <Row  className="px-3">
					<Col sm={12} md={6} className="d-flex">
						<img
              width="150"
              style={{objectFit:"contain"}}
							src={require("assets/images/logo/Antridge_Logo_PNG.png")}
						/>
						<p className="align-self-baseline">org</p>
					</Col>
				</Row>
        <Form className="p-3 mt-3">
          <h4>Reset Password</h4>
          <p>
            If you forgot your password, well, then we will email you
            instructions to reset your password.
          </p>
          {feedback}
          <FormGroup>
            <Label for="exampleEmail">
              <b>Email</b>
            </Label>
            <Input
              type="email"
              name="email"
              id="exampleEmail"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>

          <Button
            size="md"
            color="primary"
            className="w-100 text-center mt-2"
            onClick={onSignIn}
          >
            Send Reset Link
          </Button>
        </Form>
        <Row className="pl-3 pr-3 pb-3 pt-0 mt-0">
          <Col
            sm={12}
            className="d-flex flex-direction-row pl-0 align-items-baseline"
          >
            <button
              size="md"
              color="primary"
              className=" text-center btn btn-link"
              onClick={() => history.push("/auth/login")}
            >
              Return to Login
            </button>
          </Col>
        </Row>

        <Row className="p-3 mt-1">
          <Col
            sm={12}
            className="d-flex flex-direction-row p-3 mt-2 justify-content-center align-items-baseline"
          >
            <p className="h-60">Need help? </p>{" "}
            <button
              size="md"
              color="primary"
              className=" text-center btn btn-link"
              onClick={onSignIn}
            >
              Contact Us
            </button>
          </Col>
        </Row>

        <AuthFooter />
      </Col>
    </Row>
  );
};
