import _ from "lodash";
import { initState } from "../state";
import {
  NOTIFY,
} from "../actions/types";

const notificationsReducer = (state = initState.notifications, action) => {
  switch (action.type) {
    case NOTIFY: {
      return {
        ...state,
        notifications: {
          place: "tc",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default notificationsReducer;
