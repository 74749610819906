import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "../reducers";

let store = undefined;
export function configureStore() {
  if (store) {
    return store;
  }
  store = createStore(reducers, composeWithDevTools());
  return store;
}

export default store;
