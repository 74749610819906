import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";
import {
  GET_FETCH_TICKETS_BY_STATUS_AND_TYPE,
  FETCH_COMMENTS_BY_TICKET_ID,
  ADD_COMMENT,
  UPDATE_TICKET_STATUS,
  ASSIGN_TICKET,
  UPDATE_SUB_ORGANIZATION_OF_TICKET,
} from "../services/ticket-services";
import {
  fetchCommentsByTicketIdSuccess,
  addCommentSuccess,
  getTicketsByStatusAndType,
  updateTicketStatusSuccess,
  assignTicketSuccess,
  updateSubOrganizationOfTicketSuccess,
  updateDueDateStatusAndPrioritySuccess,
} from "./action";
import { notify } from "../../components/common/notification";

export const useFetchTicketByStatusAndType = () => {
  const dispatch = useDispatch();
  const [getTicketByStatusAndType, { loading }] = useLazyQuery(
    GET_FETCH_TICKETS_BY_STATUS_AND_TYPE,
    {
      onCompleted(data) {
        if (data && data.fetchTicketByStatusAndType) {
          dispatch(getTicketsByStatusAndType(data.fetchTicketByStatusAndType));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { getTicketByStatusAndType, loading };
};

export const useFetchCommentsByTicketId = () => {
  const dispatch = useDispatch();
  const [fetchCommentsByTicketId, { loading }] = useLazyQuery(
    FETCH_COMMENTS_BY_TICKET_ID,
    {
      onCompleted(data) {
        if (data && data.fetchCommentsForTicket) {
          dispatch(fetchCommentsByTicketIdSuccess(data.fetchCommentsForTicket));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchCommentsByTicketId, loading };
};

export const useAddComment = () => {
  const dispatch = useDispatch();
  const [addComment, { loading }] = useMutation(ADD_COMMENT, {
    onCompleted(data) {
      if (data && data.addComment) {
        dispatch(addCommentSuccess(data.addComment));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addComment, loading };
};

// export const useUpdateTicketStatus = () => {
//   const dispatch = useDispatch();
//   const [updateTicketStatus, { loading }] = useMutation(UPDATE_TICKET_STATUS, {
//     onCompleted(data) {
//       if (data && data.updateTicketStatus) {
//         dispatch(updateTicketStatusSuccess(data.updateTicketStatus));
//       }
//     },
//     onError(error) {
//       if (error) {
//         notify(error.message, "danger");
//       }
//     },
//     fetchPolicy: "no-cache",
//   });
//   return { updateTicketStatus, loading };
// };

// export const useUpdateDueDateStatusAndPriority = () => {
//   const dispatch = useDispatch();
//   const [updateDueDateStatusAndPriority, { loading }] = useMutation(
//     UPDATE_DUE_DATE_STATUS_AND_PRIORITY,
//     {
//       onCompleted(data) {
//         if (data && data.updateSubOrganizationOfTicket) {
//           dispatch(
//             updateDueDateStatusAndPrioritySuccess(
//               data.updateDueDateStatusAndPriority
//             )
//           );
//         }
//       },
//       onError(error) {
//         if (error) {
//           notify(error.message, "danger");
//         }
//       },
//       fetchPolicy: "no-cache",
//     }
//   );
//   return { updateDueDateStatusAndPriority, loading };
// };

export const useUpdateSubOrganizationOfTicket = () => {
  const dispatch = useDispatch();
  const [updateSubOrganizationOfTicket, { loading }] = useMutation(
    UPDATE_SUB_ORGANIZATION_OF_TICKET,
    {
      onCompleted(data) {
        if (data && data.updateSubOrganizationOfTicket) {
          dispatch(
            updateSubOrganizationOfTicketSuccess(data.updateTicketStatus)
          );
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { updateSubOrganizationOfTicket, loading };
};

export const useAssignComment = () => {
  const dispatch = useDispatch();
  const [assignTicket, { loading }] = useMutation(ASSIGN_TICKET, {
    onCompleted(data) {
      if (data && data.assignTicket) {
        dispatch(assignTicketSuccess(data.assignTicket));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { assignTicket, loading };
};
