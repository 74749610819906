import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Form,
  Input,
} from "antstrap";
import { FormattedMessage } from "react-intl";
import Files from "react-files";

const EditLogo = (props) => {
  const [logo, setLogo] = useState(props.logo);
  const [files, setFiles] = useState([]);

  const onFilesChange = (file) => {
    setFiles(file);
    let image = document.getElementById("output");
    image.src = URL.createObjectURL(file[0]);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };
  const onSave = () => {
    props.toggle();
  };
  //TODO add into locale file
  return (
    <Form>
      <Row>
        <Col sm="12">
          <Row className="mb-3">
            <Col sm="3">
              <div className="image-uploader">
                <Files
                  className="files-dropzone"
                  onChange={onFilesChange}
                  onError={onFilesError}
                  accepts={["image/jpeg", "image/jpg", "image/png"]}
                  multiple
                  maxFiles={1}
                  maxFileSize={5242880}
                  clickable
                >
                  <img id="output" />

                  <CardText className="m-0">
                    <i className="fa fa-plus" />
                    <small className="text-muted">Upload</small>
                  </CardText>
                </Files>
              </div>
            </Col>
            <Col sm="9">
              <CardText className="mb-0"></CardText>
              <CardText className="mb-0">
                <small className="text-muted">
                  Size of the file should be less than 5MB
                </small>
              </CardText>
              <CardText className="mb-0">
                <small className="text-muted">
                  Minimum resolution should be 512px by 512px
                </small>
              </CardText>
              <CardText className="mb-0">
                <small className="text-muted">
                  Supported file formats:JPG, JPEG, PNG
                </small>
              </CardText>
              <CardText className="mb-0">
                <small className="text-muted">
                  We recommend using a Logo for your organization than a
                  captured photograph
                </small>
              </CardText>
            </Col>
          </Row>

          <Button id="save" color="primary" onClick={() => onSave(logo)}>
            <FormattedMessage id="common.save" defaultMessage="Save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditLogo;
