import React from "react";
import { Card, Col, Row } from "reactstrap";
import { useUpdatePrivacySettings } from "../../../redux/actions/settings";
import SettingsSection from "./settings-section";

const PrivacySettings = (props) => {
  const privacy = props.privacy;
  const { updatePrivacySettings, loading } = useUpdatePrivacySettings();

  const updatePrivacySettingsToDb = (key, value) => {
    privacy[key] = value;
    delete privacy["__typename"];
    updatePrivacySettings({
      variables: { privacy },
    });
  };
  return (
    <>
      <Row>
        <Col sm="12" className="pt-4 pb-2">
          <h5>Privacy Settings</h5>
          <p>Configure your organization privacy.</p>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Card>
            <SettingsSection
              title={"Private Organization"}
              subTitle={`Private Organization will not appear in public search
              results. Subscribers can join a private organization by
              invitation only`}
              value={props.privacy.isPrivate}
              id={"isPrivate"}
              onChange={updatePrivacySettingsToDb}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PrivacySettings;
