import { Button, Col, CustomInput, FormGroup, Input, Row,Label } from "antstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

const StepTwo = (props) => {
  let {
    subOrganizationLabel,
    childSubOrganizationLabel,
    setChildSubOrganizationLabel,
    toggleTab,
    configureSubOrgnization,
    isChildSubOrganizationExist,
    isChildSubOrganization,
    setIsChildSubOrganization,
    data
  } = props;
  return (
    <>
      {" "}
      <Row className="pt-4">
        <Col sm="12">
          <Row>
            <Col sm={10}>
              <p>
                <FormattedMessage
                  id="subOrganization.suborganizationlabel"
                  defaultMessage={`Do your {subOrganizationLabel} have Sub {subOrganizationLabel}?`}
                  values={{
                    subOrganizationLabel:subOrganizationLabel.name,
                  }}
                />
              </p>
            </Col>
            <Col sm={2}>
              <CustomInput
                type="switch"
                className="custom-antstrap-switch"
                id="isChildSubOrganizationEnabled"
                name="customSwitch"
                checked={isChildSubOrganization}
                onChange={(e) =>
                  setIsChildSubOrganization(e.target.checked)
                }
              />
            </Col>
          </Row>
          {isChildSubOrganization ? (
            <FormGroup className="pt-4">
              <Label>What do you want your Sub {subOrganizationLabel.name} to be called?</Label>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={JSON.stringify(childSubOrganizationLabel)}
                onChange={(e) =>{
                  setChildSubOrganizationLabel(JSON.parse(e.target.value))
                } }
              >
                {data.map((item)=>(
                  <option value={JSON.stringify(item)}>{item.name}</option>
                ))}
               
              </Input>
            </FormGroup>
          ) : (
            <div />
          )}
        </Col>
      </Row>
      <Col className="py-2 pr-0 text-right border-top-0">
        <Button
          color="primary"
          onClick={() => {
            toggleTab("1");
          }}
        >
          <FormattedMessage id="prev" defaultMessage="Prev" />
        </Button>{" "}
        {props.isFinalStep ? (
          <Button
            color="primary"
            onClick={() => {
              // doConfigure(true);
              configureSubOrgnization();
            }}
          >
            <FormattedMessage id="finish" defaultMessage="Finish" />
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={() => {
              toggleTab("3");
            }}
          >
            <FormattedMessage id="next" defaultMessage="Next" />
          </Button>
        )}
      </Col>
    </>
  );
};

export default StepTwo;
