import { gql } from "apollo-boost";

export const GET_ALL_HELPDESK_CATEGORIES_QUERY = gql`
	query getHelpdeskCategories {
		getHelpdeskCategories {
			_id
			organizationId
			createdAt
			updatedAt

			categories {
				_id
				name
				description
				type
			}
		}
	}
`;

export const ADD_HELPDESK_CATEGORY_MUTATION = gql`
	mutation addHelpdeskCategory($name:String) {
		addHelpdeskCategory(name:$name) {
			_id
			organizationId
			createdAt
			updatedAt

			categories {
				_id
				name
				description
				type
			}
		}
	}
`;

export const EDIT_HELPDESK_CATEGORY_MUTATION = gql`
	mutation editHelpdeskCategory($name:String,$categoryId:String) {
		editHelpdeskCategory(name:$name,categoryId:$categoryId) {
			_id
			organizationId
			createdAt
			updatedAt

			categories {
				_id
				name
				description
				type
			}
		}
	}
`;
export const REMOVE_HELPDESK_CATEGORY_MUTATION = gql`
	mutation removeHelpdeskCategory($categoryId:String) {
		removeHelpdeskCategory(categoryId:$categoryId) {
			_id
			organizationId
			createdAt
			updatedAt

			categories {
				_id
				name
				description
				type
			}
		}
	}
`;