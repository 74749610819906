import React, { useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';

const URLPreview = ({ url, isHideNoPreview }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/v1/preview/fetch-meta?url=${url}`);
                // const response = await fetch(`${url}`);
                const metaTags = await response.json();
               console.log(metaTags)
                    if (metaTags.title ) {
                        setTitle(metaTags.title);
                    }
                    if (metaTags.description) {
                        setDescription(metaTags.description);
                    }
                    if (metaTags.image) {
                        setImage(metaTags.image);
                    }
            } catch (error) {
                console.error('Failed to fetch URL data:', error);
            }
        };

        fetchData();
    }, [url]);

    return (
        <>
            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:image" content={image} />
            </MetaTags>
            {title != "" ?
                <div className='d-flex url-preview cursor-pointer' onClick={() => { window.open(url, '_blank'); }} >
                    <div className='inline-flex url-preview-image mr-3'>
                        {image && <img src={image} alt="Preview" />}
                    </div>
                    <div className='inline-flex url-preview-content'>
                        <p style={{ fontWeight: "bold" }}>{title}</p>
                        <p style={{ color: "#6c757d" }}>{description}</p>
                    </div>

                </div>
                : !isHideNoPreview ? <div className='d-flex url-preview no-preview cursor-pointer' onClick={() => { window.open(url, '_blank'); }} >
                    <div className='inline-flex url-preview-content'>

                        <p style={{ color: "#6c757d" }}>No preview available</p>
                    </div>

                </div> : null}
        </>
    );
};

export default URLPreview;
