import {
  FETCH_TICKETS_BY_STATUS_AND_TYPE,
  FETCH_COMMENTS_BY_TICKET_ID,
  FETCH_PRIVATE_NOTES_BY_TICKET_ID,
  ADD_PRIVATE_NOTE,
  ADD_TEAM_DISCUSSION_COMMENT,
  FETCH_TEAM_DISCUSSION_BY_TICKET_ID,
  ADD_COMMENT,
  FETCH_TAGS_BY_TICKET_ID,
  UPDATE_TAGS,
  UPDATE_TICKET_DETAILS,
  UPDATE_TICKET_STATUS,
  UPDATE_TICKET_STATUS_WITH_COMMENT,
} from "../types";

export const getTicketsByStatusAndType = (data) => {
  return {
    type: FETCH_TICKETS_BY_STATUS_AND_TYPE,
    payload: data,
  };
};

export const fetchCommentsByTicketIdSuccess = (data) => {
  return {
    type: FETCH_COMMENTS_BY_TICKET_ID,
    payload: data,
  };
};

export const addCommentSuccess = (data) => {
  return {
    type: ADD_COMMENT,
    payload: data,
  };
};
export const fetchPrivateNotesByTicketIdSuccess = (data) => {
  return {
    type: FETCH_PRIVATE_NOTES_BY_TICKET_ID,

    payload: data,
  };
};
export const addPrivateNoteSuccess = (data) => {
  return {
    type: ADD_PRIVATE_NOTE,
    payload: data,
  };
};
export const fetchTeamDiscussionByTicketIdSuccess = (data) => {
  return {
    type: FETCH_TEAM_DISCUSSION_BY_TICKET_ID,
    payload: data,
  };
};
export const addTeamDiscussionCommentSuccess = (data) => {
  return {
    type: ADD_TEAM_DISCUSSION_COMMENT,
    payload: data,
  };
};

export const fetchTagsByTicketIdSuccess = (data) => {
  return {
    type: FETCH_TAGS_BY_TICKET_ID,
    payload: data,
  };
};

export const updateTagsSuccess = (data) => {
  return {
    type: UPDATE_TAGS,
    payload: data,
  };
};

export const updateTicketDetailsSuccess = (data) => {
  return {
    type: UPDATE_TICKET_DETAILS,
    payload: data,
  };
};

export const updateTicketStatusSuccess = (data) => {
  return {
    type: UPDATE_TICKET_STATUS,
    payload: data,
  };
};

export const updateTicketStatusAndCommentSuccess = (data) => {
  return {
    type: UPDATE_TICKET_STATUS_WITH_COMMENT,
    payload: data,
  };
};