import React from "react";
import { Row, Col, CardText } from "antstrap";

const ViewUrl = ({ url }) => {
  return (
    <Row>
      <Col sm="12">
        <CardText>{url}</CardText>
      </Col>
    </Row>
  );
};

export default ViewUrl;
