import _ from "lodash";
import {
  ADD_ORGANIZATION_USER_SUCCESS,
  FETCH_ORGANIZATION_USERS,
  SET_USER_ONLINE,
  UPDATE_ORGANIZATION_USER_SUCCESS,
} from "../actions/types";
const usersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_USERS:
      return action.payload;
    case UPDATE_ORGANIZATION_USER_SUCCESS: {
      return _.unionBy([{ ...action.payload }], [...state], "_id");
    }
    
    case ADD_ORGANIZATION_USER_SUCCESS: {
      let newState = [...state];
      newState.push(action.payload);
      return newState;
    }
    // case DELETE_ROLES_SUCCESS: {
    //   return {
    //     ...state,
    //     roles: _.differenceBy(state.roles, [{ ...action.payload }], "_id"),
    //   };
    // }
    default:
      return state;
  }
};

export default usersReducer;
