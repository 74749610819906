import {
  FETCH_SUB_ORGANIZATIONS,
  ADD_SUB_ORGANIZATION,
  DELETE_SUB_ORGANIZATION,
  EDIT_SUB_ORGANIZATION,
  ADD_CHILD_SUB_ORGANIZATION,
  GET_CHILD_SUB_ORGANIZATION,
  EDIT_CHILD_SUB_ORGANIZATION,
  DELETE_CHILD_SUB_ORGANIZATION,
} from "../actions/types";

const subOrganizationReducer = (state = [], action) => {
  if (action.type === FETCH_SUB_ORGANIZATIONS) {
    let newState = action.payload;
    return newState;
  } else if (action.type === ADD_SUB_ORGANIZATION) {
    let newState = [...state];
    newState.push(action.payload);
    return newState;
  } else if (action.type === EDIT_SUB_ORGANIZATION) {
    let newState = [...state];
    newState.map((subOrganization) => {
      if (subOrganization._id == action.payload._id) {
        subOrganization.name = action.payload.name;
      }
    });
    return newState;
  } else if (action.type === DELETE_SUB_ORGANIZATION) {
    let newState = [...state];
    newState = newState.filter((subOrganization) => {
      return subOrganization._id !== action.payload._id;
    });
    return newState;
  } else if (action.type === ADD_CHILD_SUB_ORGANIZATION) {
    let newState = [...state];
    newState.map((subOrganization) => {
      if (subOrganization._id == action.payload._id) {
        subOrganization.childSubOrganization =
          action.payload.childSubOrganization;
      }
    });
    return newState;
  } else if (action.type === GET_CHILD_SUB_ORGANIZATION) {
    let newState = action.payload;
    return newState;
  } else if (action.type === EDIT_CHILD_SUB_ORGANIZATION) {
    let newState = [...state];
    newState.map((subOrganization, index) => {
      if (subOrganization._id == action.payload._id) {
        newState[index] = { ...action.payload };
      }
    });
    return newState;
  } else if (action.type === DELETE_CHILD_SUB_ORGANIZATION) {
    let newState = [...state];
    newState.map((subOrganization, index) => {
      if (subOrganization._id == action.payload._id) {
        newState[index] = { ...action.payload };
      }
    });
    return newState;
  }
  return state;
};

export default subOrganizationReducer;
