import React from "react";
import { Row, Col, CardText } from "antstrap";

const ViewAddress = (props) => {
  const { address } = props;

  return (
    <Row>
      <Col sm="12">
        <CardText>{address?`${address.addressLine},${address.city},${address.state},${address.postalCode}`:""}</CardText>
      </Col>
    </Row>
  );
};

export default ViewAddress;
