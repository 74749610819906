import React from "react";
import { Card, Col, Row } from "reactstrap";
import { useUpdateSubscriberSettings } from "../../../redux/actions/settings";
import SettingsSection from "./settings-section";

const SubscriberSettings = (props) => {
  const subscriber = props.subscriber;
  const { updateSubscriberSettings, loading } = useUpdateSubscriberSettings();

  const updateSubscriberSettingsToDb = (key, value) => {
    subscriber[key] = value;
    delete subscriber["__typename"];

    updateSubscriberSettings({
      variables: { subscriber },
    });
  };
  return (
    <>
      <Row>
        <Col sm="12" className="pt-4 pb-2">
          <h5>Subscribers Settings</h5>
          <p>Configure settings for subscribers.</p>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Card>
            <SettingsSection
              title={"Join on approval only"}
              subTitle={`Subscribers can join the organization on approval only.
              Turn this OFF to allow subscribers to join instantly
              without any approval.`}
              value={props.subscriber.isJoinOnApproval}
              id={"isJoinOnApproval"}
              onChange={updateSubscriberSettingsToDb}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SubscriberSettings;
