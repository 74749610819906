import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Collapse, Nav, NavbarText } from "antstrap";
import PerfectScrollbar from "perfect-scrollbar";
import { useSelector } from "react-redux";

let ps;

const Sidebar = ({ bgColor, activeColor, routes, location, isMobile }) => {
  const sidebarRef = useRef(null);
  const organization = useSelector((state) => state.organization);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const [collapseState, setCollapseState] = useState(getCollapseStates(routes));
  const sidebar = useRef(null);

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect || (isMobile && prop.isHiddenInMobile)) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseState[prop.state];
        return (
          <>
            {prop.headerText && <NavbarText>{prop.headerText}</NavbarText>}
            <li
              className={getCollapseInitialState(prop.views) ? "active" : ""}
              key={key}
            >
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={collapseState[prop.state]}
                onClick={(e) => {
                  e.preventDefault();
                  setCollapseState(st);
                }}
              >
                {prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon}>{prop.iconName}</i>
                    <p>
                      {prop.name}
                      <b className="caret" />
                    </p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">
                      {prop.name}
                      <b className="caret" />
                    </span>
                  </>
                )}
              </a>
              <Collapse isOpen={collapseState[prop.state]}>
                <ul className="nav">{createLinks(prop.views)}</ul>
              </Collapse>
            </li>
          </>
        );
      }
      return (
        <>
          {prop.headerText && <NavbarText>{prop.headerText}</NavbarText>}
          <li className={activeRoute(prop.layout + prop.path)} key={key}>
            <NavLink to={prop.layout + prop.path} activeClassName="" onClick={toggleSidebar}>
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon}>{prop.iconName}</i>
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </NavLink>
          </li>
        </>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    ///return ""
  };

  return (
    <div
      className="sidebar"
      data-color={bgColor}
      data-active-color={activeColor}
    >
      <div className="logo">
        <a href="" className="simple-text logo-mini">
          <div className="logo-img">
            <img
              src={require("../../assets/images/organization_default_thumbnail.svg")}
              alt="react-logo"
              style={{ objectFit: "scale-down" }}
            />
          </div>
        </a>
        <a href="" className="simple-text logo-normal org-name">

          <span className="title">{organization.name}</span>
          <span className="subtitle">kitcoek</span>
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebarRef}>
        <Nav>{createLinks(routes)}</Nav>
      </div>
    </div>
  );
};

export default Sidebar;
