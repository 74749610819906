import React from "react";
import { Col, Row, CardTitle, Button } from "antstrap";
import { FormattedMessage } from "react-intl";

const SectionHeader = (props) => {
  return (
    <Row>
      <Col xs={9}>
        <CardTitle>
          {/* <FormattedMessage id="title" defaultMessage="title" /> */}
          <b>{props.title}</b>
        </CardTitle>
      </Col>
      <Col xs={3} className="text-right">
        {props.isEdit ? (
          <Button
            outline
            size="sm"
            className="card-edit-btn"
            onClick={() => props.toggle(props.type)}
          >
            <FormattedMessage id="common.close" defaultMessage="Close" />
          </Button>
        ) : (
          <Button
            outline
            size="sm"
            className="card-edit-btn"
            onClick={() => props.toggle(props.type)}
          >
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default SectionHeader;
