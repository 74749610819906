import React, { useEffect, useState } from "react";
import moment from "moment";
import { useReadMessageHandler, useSendMessageHandler } from "../../redux/actions/socket";
import URLPreview from "../common/url-preview";
const ChatBlock = (props) => {
	let blockClasses;
	let inlineClasses;
	let listClasses;

	const [url, setUrl] = useState(null)
	const sendMessageReceipt = useReadMessageHandler();
	const urlRegex = /(https?:\/\/[^\s]+)/g;
	useEffect(() => {
		if (!props.message.isRead && props.align == "left") {
			sendMessageReceipt(props.message._id, props.message.userId)
		}
	}, [])
	if (props.align == "right") {
		blockClasses = "message-block right";
		inlineClasses = "message-status d-inline-flex font-sm";
		listClasses =
			"list-group-item border-0 w-100 pb-1 pt-0 flex-row text-right";
	} else {
		blockClasses = "message-block left d-inline-flex";
		inlineClasses = "message-status d-inline-flex";
		listClasses = "list-group-item border-0 w-100 pb-1 pt-0 flex-row text-left";
	}
	useEffect(() => {
		"rerender"
	})

	function isSingleWord(str) {
		return !/\s/.test(str); // Returns true if the string does not contain whitespace
	}

	const renderMessage = (message) => {
		let messagesWords = message.split(" ");
		let url = null

		// if (isSingleWord(message)) {
		// 	messagesWords = [message]

		// }
		messagesWords.map((word, index) => {


			if (urlRegex.test(word)) {
				if (url == null) {
					url = word
				}
			}
		})
		return (
			<p className="message">
				{url ? <URLPreview url={url} isHideNoPreview={true} /> : null}
				{messagesWords.map((word, index) => {


					if (urlRegex.test(word)) {
						return (<a href={word} target="_blank">
							{index != 0 ? <>&nbsp;{word}</> : <>{word}</>}
						</a>)
					} else {
						return (index != 0 ? <>&nbsp;{word}</> : <>{word}</>)
					}
				})}
			</p>
		)

	}
	return (
		<li key={props.key} className={listClasses}>
			{/* To Do -- add condition as per message timeing for below separators START*/}
			{/* <div className="seperator red">
        <span>New</span>
      </div>*/}

			{props.header && props.header.header ? (
				<div className="seperator dark-gray">
					<span>{props.header.header}</span>
				</div>
			) : null}
			{/* To Do -- add condition as per message timeing for below separators END*/}

			{props.header && props.header.isSameLastTime ? (
				<>
					{props.align == "left" ? (
						<div className="float-left ">
							<img
								className="image-rounded mr-2"
							//src={require("assets/images/no-photo.svg")}
							/>
						</div>
					) : null}
					<div className={props.align == "left" ? "ml-4 chat-row" : "chat-row"}>
						<div className={blockClasses}>
							{renderMessage(props.message.message)}
						</div>
						{props.align != "left" ? <div className={inlineClasses}>
							{props.message._id && !props.message.isDelivered && !props.message.isRead ? <i className="material-icons" >done</i> : null}
							{props.message.isDelivered || props.message.isRead ? <i className={props.message.isRead ? "material-icons active" : "material-icons"}>done_all</i> : null}
						</div> : null}
					</div>
				</>
			) : (
				<>
					{props.align == "left" ? (
						<div className="float-left mt-4-5">
							<img
								className="image-rounded mr-2 mt-2"
								src={require("assets/images/no-photo.svg")}
							/>
						</div>
					) : null}
					<div>
						<small className="message-time mt-2">
							{moment(new Date(Number(props.message.createdAt))).format("LT")}
						</small>
						<div className="chat-row">
							<div className={blockClasses}>
								{renderMessage(props.message.message)}
							</div>
							{props.align != "left" ? <div className={inlineClasses}>
								{props.message._id && !props.message.isDelivered && !props.message.isRead ? <i className="material-icons" >done</i> : null}
								{props.message.isDelivered || props.message.isRead ? <i className={props.message.isRead ? "material-icons active" : "material-icons"}>done_all</i> : null}
							</div> : null}
						</div>
					</div>
				</>
			)}
		</li>
	);
};



export default React.memo(ChatBlock);
