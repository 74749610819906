import { gql } from "apollo-boost";

export const GET_FETCH_TICKETS_BY_STATUS_AND_TYPE = gql`
	query fetchTicketByStatusAndType($statuses: [Int], $type: Int) {
		fetchTicketByStatusAndType(statuses: $statuses, type: $type) {
			_id
			organizationId
			subOrganizationId
			userId
			title
			description
			attachments
			requesterId
			createdBy
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			type
			userType
			lastUpdatedBy
			ticketNumber
			createdAt
			updatedAt
			assignedToId
			assignedUser {
				_id
				firstName
				lastName
				email
				fullNumber
			}
			organizationUser {
				_id
				firstName
				lastName
				email
				fullNumber
			}
			subscriberUser {
				_id
				firstName
				lastName
				email
				fullNumber
			}
		}
	}
`;

export const UPDATE_TICKET_DETAILS_MUTATION = gql`
	mutation updateTicketDetails(
		$_id: String
		$userId: String
		$priority: Int
		$dueDate: String
		$assignedTo: String
		$category: String
	) {
		updateTicketDetails(
			ticketId: $_id
			userId: $userId
			priority: $priority
			dueDate: $dueDate
			assignedTo: $assignedTo
			category: $category
		) {
			_id
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			lastUpdatedBy
			ticketNumber
			createdAt
			updatedAt
			assignedToId
			assignedUser {
				_id
				firstName
				lastName
				email
				fullNumber
			}
		}
	}
`;

export const FETCH_COMMENTS_BY_TICKET_ID = gql`
	query fetchCommentsForTicket($_id: String) {
		fetchCommentsForTicket(ticketId: $_id) {
			_id
			comments {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				isUserComment
				attachments
			}
		}
	}
`;

export const ADD_COMMENT = gql`
	mutation addComment(
		$_id: String
		$userId: String
		$comment: String
		$isUserComment: Boolean
	) {
		addComment(
			isUserComment: $isUserComment
			ticketId: $_id
			userId: $userId
			comment: $comment
		) {
			_id
			comments {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				isUserComment
				attachments
			}
		}
	}
`;

export const FETCH_TEAM_DISCUSSIONS_COMMENTS_BY_TICKET_ID = gql`
	query fetchTeamNotesForTicket($_id: String) {
		fetchTeamNotesForTicket(ticketId: $_id) {
			_id
			teamNotes {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				attachments
			}
		}
	}
`;

export const ADD_TEAM_DISCUSSION_COMMENT = gql`
	mutation addTeamNote($_id: String, $userId: String, $comment: String) {
		addTeamNote(ticketId: $_id, userId: $userId, comment: $comment) {
			_id
			teamNotes {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				attachments
			}
		}
	}
`;

export const FETCH_PRIVATE_NOTES_BY_TICKET_ID = gql`
	query fetchPrivateNotesForTicket($_id: String, $userId: String) {
		fetchPrivateNotesForTicket(ticketId: $_id, userId: $userId) {
			_id
			privateNotes {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				attachments
			}
		}
	}
`;

export const ADD_PRIVATE_NOTES = gql`
	mutation addPrivateNote($_id: String, $userId: String, $comment: String) {
		addPrivateNote(ticketId: $_id, userId: $userId, comment: $comment) {
			_id
			privateNotes {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				attachments
			}
		}
	}
`;

export const FETCH_TAGS_BY_TICKET_ID = gql`
	query fetchTagsForTicket($_id: String) {
		fetchTagsForTicket(ticketId: $_id) {
			_id
			tags
		}
	}
`;

export const UPDATE_TAGS_BY_TICKET_ID = gql`
	mutation updateTagsForTicket($_id: String, $tags: [String]) {
		updateTagsForTicket(ticketId: $_id, tags: $tags) {
			_id
			tags
		}
	}
`;

export const UPDATE_TICKET_STATUS_MUTATION = gql`
	mutation updateTicketStatus($_id: String, $userId: String, $status: Int) {
		updateTicketStatus(ticketId: $_id, userId: $userId, status: $status) {
			_id
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			lastUpdatedBy
		}
	}
`;

export const UPDATE_TICKET_STATUS_WITH_COMMENT_MUTATION = gql`
	mutation updateTicketStatusWithComment(
		$_id: String
		$userId: String
		$status: Int
		$comment: String
	) {
		updateTicketStatusWithComment(
			ticketId: $_id
			userId: $userId
			status: $status
			comment: $comment
		) {
			_id
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			comments {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				isUserComment
				attachments
			}
			lastUpdatedBy
		}
	}
`;

export const ASSIGN_TICKET = gql`
	mutation assignTicket(
		$organizationId: String
		$_id: String
		$userId: String
		$assignedBy: String
	) {
		assignTicket(
			organizationId: $organizationId
			_id: $_id
			userId: $userId
			assignedBy: $assignedBy
		) {
			_id
			organizationId
			subOrganizationId
			userId
			title
			description
			attachments
			requesterId
			createdBy
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			type
			userType
			lastUpdatedBy
		}
	}
`;

export const CREATE_TICKET = gql`
	mutation createTicket(
		$organizationId: String
		$subOrganizationId: String
		$_id: String
		$userId: String
		$title: String
		$description: String
		$type: Int
		$userType: Int
		$attachments: [String]
	) {
		createTicket(
			organizationId: $organizationId
			_id: $_id
			userId: $userId
			title: $title
			description: $description
			type: $type
			userType: $userType
			attachments: $attachments
		) {
			_id
			organizationId
			subOrganizationId
			userId
			title
			description
			attachments
			requesterId
			createdBy
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			type
			userType
			lastUpdatedBy
		}
	}
`;

export const UPDATE_SUB_ORGANIZATION_OF_TICKET = gql`
	mutation updateDueDateStatusAndPriority(
		$organizationId: String
		$_id: String
		$userId: String
		$subOrganizationId: String
	) {
		updateDueDateStatusAndPriority(
			organizationId: $organizationId
			_id: $_id
			userId: $userId
			subOrganization: $subOrganizationId
		) {
			_id
			organizationId
			subOrganizationId
			userId
			title
			description
			attachments
			requesterId
			createdBy
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			type
			userType
			lastUpdatedBy
		}
	}
`;
