import { initState } from "../state";
import { SET_USER } from "../actions/types";

const userReducer = (state = {}, action) => {
  if (action.type === SET_USER) {
    console.log("inside user", action);
    return action.user;
  }
  return state;
};

export default userReducer;
