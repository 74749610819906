import React from "react";
import { Spinner } from "reactstrap";

const LoadingIndicator = () => {
	return (
		<span className="text-center p-3">
			<Spinner type="grow" color="secondary" size="sm" className="mr-1"/>
			<Spinner type="grow" color="secondary" size="sm" className="mr-1"/>
			<Spinner type="grow" color="secondary" size="sm"/>
		</span>
	);
};

export default LoadingIndicator;
