import React, { useState,useEffect } from "react";
import {
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "antstrap";
import classnames from "classnames";
import { AccountSettings } from "./account-settings";
import { Organizational } from "./organizational";
import { Personal } from "./personal";
import { useHistory } from "react-router-dom";

import PerfectScrollbar from 'react-perfect-scrollbar'
export const UserProfile = (props) => {
  const history = useHistory();
  
  
  const tabFromQuery = props.location&&props.location.state&&props.location.state.tab?props.location.state.tab:1;
  console.log(props.location)
  const [activeTab, setActiveTab] = useState(""+tabFromQuery);
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(()=>{
    setActiveTab(""+tabFromQuery)
  },[tabFromQuery])

  return (
    <div className="bg-white mainWrapper profile-wrapper px-3">
      <Row className="border-bottom mb-3">
        <Button
          className="btn-light border-0 px-3 bg-white"
          onClick={() => history.goBack()}
        >
          <i className="material-icons">arrow_back</i>
        </Button>
        <h5 className="py-3 pl-4 mb-0">My Profile</h5>
      </Row>
      <Row className="mainContent py-3 px-3">
      <PerfectScrollbar>
        <Col xs={12} lg="9">
          <p>Manage your own information and settings.</p>
          <Row>
            <Col xs="12">
              <Nav tabs className="tabs-antstrap">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    <b>Personal</b>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    <b>Account Settings</b>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    <b>Organizational</b>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Personal />
                </TabPane>
                <TabPane tabId="2">
                  <AccountSettings />
                </TabPane>
                <TabPane tabId="3">
                  <Organizational />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Col>
        </PerfectScrollbar>
      </Row>
    </div>
  );
};
