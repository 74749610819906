import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import ReactTelInput from "react-telephone-input";
import DatePicker from "react-datepicker";
import "react-telephone-input/css/default.css";
import Files from "react-files";

import { useFetchOrganizationUsers } from "redux/actions/user";
import {
	FormGroup,
	Input,
	Button,
	Form,
	Label,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "antstrap";
import { useAddOrganizationUser } from "redux/actions/user";
import { generateTicketNUmberToDisplay } from "./constants";
import { useUpdateTicketDetails } from "../../redux/actions/helpdesk-tickets";
import { useFetchHelpdeskCategories } from "../../redux/actions/helpdesk-categories";

const EditTicketPopup = ({ modal, toggle, ticket }) => {
	const [assignedTo, setAssignedTo] = useState("");
	const [priority, setPriority] = useState(1);
	const [category, setCategory] = useState("");
	const [dueDate, setDueDate] = useState(new Date());
	const [status, setStatus] = useState(1);
	const [files, setFiles] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const { fetchOrganizationUsers } = useFetchOrganizationUsers();
	const users = useSelector((state) => state.users);
	const helpdeskCategories = useSelector((state) => state.helpdeskCategories);
	const { updateTicketDetails, loading } = useUpdateTicketDetails(toggle);
	const { getHelpdeskCategories } = useFetchHelpdeskCategories();
	useEffect(() => {
		if (ticket) {
			setPriority(ticket.priority);
			setStatus(ticket.status);
			setDueDate(new Date(Number(ticket.dueDate)));
			setCategory(ticket.category);
			setAssignedTo(ticket.assignedToId);

			if (!users || users.length == 0) {
				fetchOrganizationUsers();
			}
		}
	}, [ticket]);

	useEffect(() => {
		if (!helpdeskCategories || !helpdeskCategories._id) {
			getHelpdeskCategories();
		}
	}, [helpdeskCategories]);

	const handleAdd = () => {
		let variables = { _id: ticket._id };

		if (assignedTo == ticket.assignedToId) {
			variables.assignedTo = "";
		} else {
			variables.assignedTo = assignedTo;
		}
		if (dueDate.getTime() == Number(ticket.dueDate)) {
			variables.dueDate = "";
		} else {
			variables.dueDate = "" + dueDate.getTime();
		}
		if (priority == ticket.priority) {
			variables.priority = 0;
		} else {
			variables.priority = priority;
		}
		if (category == ticket.category) {
			variables.category = false;
		} else {
			variables.category = category;
		}

		updateTicketDetails({ variables });

		//toggle();
	};

	const handleInputChange = (phone) => {
		//setPhoneNumber(phone);
	};

	return (
		<Modal centered isOpen={modal} toggle={toggle} className="custom-modal">
			<ModalHeader toggle={toggle}>
				{/* <FormattedMessage id="add.user" /> */}
				Edit Ticket -{" "}
				{ticket ? `${generateTicketNUmberToDisplay(ticket.ticketNumber)}` : ""}
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormGroup>
						<Label for="suborganization">
							{/* <FormattedMessage id="sub_organization" /> */}
							Assigned To
						</Label>
						<Input
							type="select"
							name="role"
							id="role"
							value={assignedTo}
							onChange={(e) => {
								setAssignedTo(e.target.value);
							}}
						>
							<option value={""}>{"Select User"}</option>
							{users &&
								users.map((user) => {
									return (
										<option value={user.userId}>
											{user.user.firstName + " " + user.user.lastName}
										</option>
									);
								})}
						</Input>
					</FormGroup>
					<FormGroup>
						<Label for="role">
							{/* <FormattedMessage id="role" /> */}
							Priority
						</Label>
						<Input
							type="select"
							name="role"
							id="role"
							value={priority}
							onChange={(e) => {
								setPriority(Number(e.target.value));
							}}
						>
							<option value={1}>Medium</option>
							<option value={3}>Low</option>
							<option value={2}>High</option>
						</Input>
					</FormGroup>
					<FormGroup>
						<Label for="category">
							{/* <FormattedMessage id="role" /> */}
							Category
						</Label>
						<Input
							type="select"
							name="category"
							id="category"
							value={category}
							onChange={(e) => {
								setCategory(e.target.value);
							}}
						>
							<option value={""}>{"Select Category"}</option>
							{helpdeskCategories &&
								helpdeskCategories.categories &&
								helpdeskCategories.categories.map((category) => {
									return <option value={category._id}>{category.name}</option>;
								})}
						</Input>
					</FormGroup>
					{/* <FormGroup>
            <Label for="role">
              <FormattedMessage id="role" /> 
              Status
            </Label>
            <Input
              type="select"
              name="role"
              id="role"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value)
              }}
            >
              <option value={1}>New</option>
              <option value={2}>Open</option>
              <option value={3}>OnHold</option>
              <option value={4}>Solved</option>
              <option value={5}>Closed</option>
            </Input>
          </FormGroup> */}
					<FormGroup>
						<Label for="role">
							{/* <FormattedMessage id="role" /> */}
							Due Date
						</Label>

						<DatePicker
							selected={dueDate}
							dateFormat={"dd MMMM yyyy"}
							wrapperClassName="form-control"
							className="form-control"
							onChange={(date) => setDueDate(date)}
							minDate={startDate}
						/>
					</FormGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => handleAdd()}>
					Update Ticket
				</Button>{" "}
				<Button color="link" onClick={toggle}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default EditTicketPopup;
