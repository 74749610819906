import React, { useState,useEffect } from "react";
import "react-telephone-input/css/default.css";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col
} from "antstrap";

const UpdateUserProfile = ({ modal, toggle, userDetails}) => {
  const [firstName, setFirstName] = useState(userDetails.firstName);
  const [lastName, setLastName] = useState(userDetails.lastName);
  const [email, setEmail] = useState(userDetails.email);
  const [phoneNumber, setPhoneNumber] = useState(userDetails.mobileNumber);
 
  useEffect(()=>{
    if(userDetails){
      setFirstName(userDetails.firstName);
      setLastName(userDetails.lastName);
      setPhoneNumber(userDetails.mobileNumber);
      setEmail(userDetails.email);
    }
  },[userDetails])
  return (
    <Modal centered isOpen={modal} toggle={toggle} className="custom-modal">
      <ModalHeader className="border-bottom-0" toggle={toggle}>
        Update Profile
      </ModalHeader>
      <ModalBody className="py-1">
          <Row className="py-2 d-flex align-items-center">
              <Col xs={6} sm={4}>
              <img
            className="image-fluid rounded-circle img-width-100"
            src={require("assets/images/no-photo.svg")}
          />
              </Col>
              <Col xs={6} sm={8} >
                  <Row>
                      <p className="mb-0">Profile Photo</p>
                  </Row>
                  <Row>
                    <small className="text-primary cursor-pointer">Upload</small>
                  </Row>
              </Col>
          </Row>
        <Form>
        <Row form>
        <Col md={6}>
        <FormGroup>
            <Label for="firstname">
               First Name
               </Label>
            <Input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Enter First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
        <FormGroup>
            <Label for="lastname">
                Last Name
                </Label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Enter Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
        <FormGroup>
            <Label for="phoneNumber">
                Phone Number
            </Label>
            <Input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormGroup>
        </Col>
       
      </Row>
      <Row form>
        <Col md={6}>
        <FormGroup>
            <Label for="email">
               Email
                </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </FormGroup>
        </Col>
       
      </Row>
         
          
         
        </Form>
      </ModalBody>
      <ModalFooter>
    
        <Button color="primary" onClick={toggle}>
          Update
        </Button>     
        <Button color="link" className="color-light font-weight-bold" onClick={toggle}>
          Cancel
        </Button>   
      </ModalFooter>
    </Modal>
  );
};

export default UpdateUserProfile;
