import React, { useState } from "react";
import { Row, Col, Card } from "antstrap";
import PostMenuButton from "../common/post-menu-button";
import { Button, CardFooter, Progress } from "reactstrap";
import PostTags from "./post-tags";

import moment from "moment";
import { useDisLikePost, useLikePost, useRemoveDisLikePost, useRemoveLikePost, useSubmitPoll } from "../../redux/actions/post";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const Poll = (props) => {
	const post = props.post;
	const likePostMethod = useLikePost();
	const dislikePostMethod = useDisLikePost();
	const submitPollMethod = useSubmitPoll();
	const removeLikePostMethod = useRemoveLikePost();
	const removeDislikePostMethod = useRemoveDisLikePost();
	let timeout = null;
	const user = useSelector((state) => state.user);
	let imgClass;
	let totalImages = post.media.length;
	if (totalImages === 1) {
		imgClass = "col-12";
	} else if (totalImages === 2) {
		imgClass = "col-6";
	} else imgClass = "col-4";

	const [isResult, setResult] = useState(false);
	const [isWinner, setWinner] = useState([]);
	const toggleResult = () => {
		setResult(!isResult);
	};
	const postImage = post.media.map((img, index) => {
		if (index < 3) {
			return (
				<div className={`${imgClass} post-image`}>
					<img src={img.url} />
					{index == 2 && totalImages > 3 ? (
						<div className="overlay-extra">
							<span>+{totalImages - 3}</span>
						</div>
					) : null}
				</div>
			);
		}
	});

	const likePost = () => {
		// myVar = setTimeout(function(){ alert("Hello"); }, 3000);
		if (!post.isLiked) {
			likePostMethod.likePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		}else{
			removeLikePostMethod.removeLikePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		}
	};

	const dislikePost = () => {
		// myVar = setTimeout(function(){ alert("Hello"); }, 3000);
		if (!post.isDisliked) {
			dislikePostMethod.dislikePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		}else{
			removeDislikePostMethod.removeDislikePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		}
	};

	const submitPoll =(optionId)=>{
		submitPollMethod.submitPoll({variables:{
			postId:post._id,
			optionId,
			userId:user._id
		}})
	}

	useEffect(()=>{
		let isWinner = [];
		let lastWinnerValue = 0;
		if(post&&post.options.length){
			post.options.map(option=>{
				if(option.isVoted){
					setResult(true)
				}
				if(option.votes>lastWinnerValue){
					lastWinnerValue = option.votes;
					isWinner=[option._id]
				}else if(option.votes==lastWinnerValue){
					lastWinnerValue = option.votes;
					isWinner.push(option._id)
				}
			})

			setWinner(isWinner)
			
		}
	},[post])
	return (
		<Row>
			<Col sm="12" >
				<Card className="post">
					<div className="post-header">
						<div className="post-user">
							<img
								src={require("assets/images/no-photo.svg")}
								className="post-user-image"
							/>
							<div className="post-user-name">
							<h6>{post.user?post.user.firstName+ " "+post.user.lastName:"NA"}</h6>
								<p>{moment(new Date(Number(post.createdAt))).format("lll")}</p>
								{post.visibility==2?
								<PostTags subOrganizations={["a","b","c","d"]}/>:null}
							</div>
						</div>
						<div className="menu-button">
							<PostMenuButton menuButtons={[ {
                            name: "Report",
                            onClick: () => {
                            //     setCurrentCategory(category);
                            //   toggle();
                            },
                          },]} />
						</div>
					</div>
					<div className="post-caption">
						<p>
						{post.description}
						</p>
					</div>
					{/* <Row className="post-img no-gutters">{postImage}</Row> */}
					{!post.isExpired&&!isResult ? (
						<Row className="poll-options">
							{post.options.map((option, index) => {
								return (
									<Col className={"mb-2"} sm={12}>
										<Button
											color={"primary"}
											outline
											block
											onClick={() => submitPoll(option._id)}
										>
											{option.option}
										</Button>
									</Col>
								);
							})}
						</Row>
					) : (
						<Row className="poll-result">
							{post.options.map((option, index) => {
								return (
									<Col className={"mb-2"} sm={12}>
										<div className="option-outer-box">
											<div className={isWinner.indexOf(option._id)>-1?"option-text active":"option-text"}>
												<Progress value={post.totalVotes?option.votes * 100 /post.totalVotes:0}>
													<span>{option.option}</span>
													{option.isVoted? <i class="material-icons ">check_circle</i>:null}
												</Progress>
												
											</div>
											<div className={isWinner.indexOf(option._id)>-1?"option-response active":"option-response"}>{post.totalVotes?Math.round(option.votes * 100 /post.totalVotes):"0"}%</div>
										</div>
									</Col>
								);
							})}
						</Row>
					)}

					<Row className={"mb-2 duration"}>
						{post.isExpired ? (
							<Col>
								<span>{`${post.totalVotes} votes · Final result`}</span>
							</Col>
						) : (
							<Col>
								<span>{moment(new Date(Number(post.expiryDate))).toNow(true)} left</span>
							</Col>
						)}
					</Row>
					<div className="full-border"></div>
					<CardFooter className="post-actions-wrapper bg-white d-flex justify-content-around">
					<div
							className="d-flex post-actions  text-center cursor-pointer"
							onClick={likePost}
						>
							{post.isLiked ? (
								<i class="material-icons active">thumb_up</i>
							) : (
								<i class="material-icons ">thumb_up</i>
							)}
							<span>{post.likes} </span>
						</div>
						<div
							className="d-flex post-actions  text-center cursor-pointer"
							onClick={dislikePost}
						>
							{post.isDisliked ? (
								<i class="material-icons active">thumb_down</i>
							) : (
								<i class="material-icons ">thumb_down</i>
							)}
							<span>{post.dislikes} </span>
						</div>
						<div className="d-flex post-actions  text-center">
							<i class="material-icons ">comment</i>
							<span>{post.views} </span>
						</div>
					</CardFooter>
				</Card>
			</Col>
		</Row>
	);
};

export default Poll;
