import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";

import { notify } from "../../components/common/notification";
import { ADD_USER_COMMENT, FETCH_USER_COMMENTS_BY_TICKET_ID, GET_FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER } from "../services/myspace-services";
import { addUserCommentSuccess, fetchUserCommentsByTicketIdSuccess, getTicketsByStatusAndTypeForUser } from "./redux-actions/my-space";


export const useFetchTicketByStatusAndTypeForUser = () => {
  const dispatch = useDispatch();
  const [getTicketByStatusAndTypeForUser, { loading }] = useLazyQuery(
    GET_FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER,
    {
      onCompleted(data) {
        if (data && data.fetchTicketByStatusAndTypeForUser) {
          dispatch(getTicketsByStatusAndTypeForUser(data.fetchTicketByStatusAndTypeForUser));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { getTicketByStatusAndTypeForUser, loading };
};

export const useFetchUserCommentsByTicketId = () => {
  const dispatch = useDispatch();
  const [fetchUserCommentsByTicketId, { loading }] = useLazyQuery(
    FETCH_USER_COMMENTS_BY_TICKET_ID,
    {
      onCompleted(data) {
        if (data && data.fetchCommentsForUserTicket) {
          dispatch(fetchUserCommentsByTicketIdSuccess(data.fetchCommentsForUserTicket));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchUserCommentsByTicketId, loading };
};

export const useAddUserComment = () => {
  const dispatch = useDispatch();
  const [addUserComment, { loading }] = useMutation(ADD_USER_COMMENT, {
    onCompleted(data) {
      if (data && data.addUserComment) {
        dispatch(addUserCommentSuccess(data.addUserComment));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addUserComment, loading };
};

