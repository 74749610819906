import React from 'react'
import { Nav, NavItem } from 'reactstrap'

import { NavLink } from "react-router-dom";

const MobileBottomTabs = (props) => {
    return (
        <nav className="fixed-bottom bottom-tabs navbar-light" role="navigation">
            <Nav className="w-100">
                <div className=" d-flex flex-row justify-content-around w-100">
                    {
                        props.tabs.map((tab, index) => (
                            <NavItem key={`tab-${index}`}>
                                <NavLink to={tab.layout + tab.path} className="nav-link" activeClassName="active">
                                    <div className="row d-flex flex-column justify-content-center align-items-center">
                                        <i class="material-icons">{tab.iconName}</i>
                                        <div>{tab.name}</div>
                                    </div>
                                </NavLink>
                            </NavItem>
                        ))
                    }
                </div>
            </Nav>
        </nav>
    )
}

export default MobileBottomTabs