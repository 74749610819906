import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	FormGroup,
	Dropdown,
	Button,
	Input,
} from "antstrap";
import PostSideMenu from "./post-side-menu";
import PostList from "./posts-list";
import { Container } from "reactstrap";
import CreatePost from "./create-post";
import { useFetchLatestPosts } from "../../redux/actions/post";
import { useSelector } from "react-redux";
import SubOrganizationsDropdown from "../common/sub-organization";
import { isMobile } from "react-device-detect";
import MobileActionButton from "../common/mobile-action-button";
import { useHistory } from "react-router-dom";

export const Posts = () => {
	const [searchString, setSearchString] = useState("");
	const [selectedSubOrganization, setSubOrganization] = useState(false);
	const [selectedType, setSelectedType] = useState(false);
	const [dropdownOpen, setOpen] = useState(false);
	const toggleDropdown = () => setOpen(!dropdownOpen);
	const posts = useSelector((state) => state.posts);
	const { fetchLatestPosts, loading } = useFetchLatestPosts();
	const user = useSelector((state) => state.user);
	const history = useHistory();
	useEffect(() => {
		if (user && user._id) {
			fetchLatestPosts({
				variables: {
					userId: user._id,
				},
			});
		}
	}, [user]);

	return (
		<Row className="mainWrapper post-wrapper">
			<Col className="mainContent ">
				<Row>
					<Col md="12" lg={6} className={"border-right"}>
						<Row className="border-bottom post-toolbar ">
							<Col md="12" className=" pt-3 pb-3 ">
								<div className="text-left d-flex d-md-inline-flex">
									<Dropdown
										className="custom-dropdown mr-3"
										isOpen={dropdownOpen}
										toggle={toggleDropdown}
									>
										<DropdownToggle color="light" className="border" caret>
											{selectedType ? selectedType == 1 ? "Posts" : "Polls" : "All"}
										</DropdownToggle>
										<DropdownMenu>
											{/* <DropdownItem header>sort by...</DropdownItem>
											<DropdownItem disabled>Newest first</DropdownItem> */}
											<DropdownItem onClick={() => setSelectedType(false)}>All</DropdownItem>
											<DropdownItem onClick={() => setSelectedType(1)}>Posts</DropdownItem>
											<DropdownItem onClick={() => setSelectedType(2)}>Polls</DropdownItem>
											<DropdownItem disabled>Events</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<SubOrganizationsDropdown
										value={selectedSubOrganization}
										onSelect={(value) => setSubOrganization(value)}
									/>
									{selectedSubOrganization || selectedType ? (
										<Button
											color="link"
											className={"px-0"}
											onClick={() => {
												setSelectedType(false);
												setSubOrganization(false);
											}}
										>
											Clear Filter
										</Button>
									) : null}
								</div>
								{/* <FormGroup className=" form-inline float-right">
										<div className="input-group">
											<div className="input-group-prepend ">
												<i className="material-icons input-group-text bg-white h6">
													search
												</i>
											</div>
											<Input
												type="text"
												className="form-control border-left-0"
												placeholder="Search User"
												value={searchString}
												onChange={(e) => {
													setSearchString(e.target.value);
												}}
											/>
										</div>
									</FormGroup> */}
							</Col>
						</Row>
						{!isMobile ? <CreatePost /> : <MobileActionButton onClick={()=>{
							history.push("/basic/new-post")
						}}/>}
						<PostList posts={posts} />
					</Col>
					<Col md="4"></Col>
				</Row>
			</Col>
		</Row>
	);
};
