import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label
} from "antstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAddSubOrganization } from "../../../redux/actions/sub-organization";

const AddSubOrganizationModal = (props) => {
  const { isOpen, toggle, subOrganizationLabel } = props;
  const [name, setName] = useState("");
  const toggleModal = ()=>{
    setName("");
    toggle()
  }
  const { addSubOrganization, loading } = useAddSubOrganization(toggleModal);

  const addSubOrg = () => {
    addSubOrganization({
      variables: {
        name,
        description: "",
        childSubOrganization: [],
      },
    });
  };
  return (
    <Modal centered isOpen={isOpen} toggle={toggleModal} className={"custom-modal"}>
      <ModalHeader toggle={toggleModal}>Create {subOrganizationLabel.name}</ModalHeader>
      <ModalBody>
       
        <FormGroup>
          <Label>
          <FormattedMessage
          id="subOrganization.subOrganizationName"
          defaultMessage="Name of your {subOrganizationLabel}"
          values={{
            subOrganizationLabel:subOrganizationLabel.name,
          }}
        />

          </Label>
          <Input
            type="text"
            name="departmentName"
            placeholder={`Enter ${subOrganizationLabel.name} Name`}
            id="exampleSelect"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={addSubOrg}>
          <FormattedMessage id="create" defaultMessage="Create" />
        </Button>{" "}
        <Button color="link" onClick={toggleModal}>
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddSubOrganizationModal;
