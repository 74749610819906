import React, { useEffect } from "react";
import { AuthLogin } from "../../components/auth/auth-login";
import { LoginCarousel } from "../../components/auth/carousel";
import { Row, Col, Container } from "reactstrap";
import { AuthSubmitRequest } from "../../components/auth/auth-submit-request";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { AuthResetPassword } from "../../components/auth/auth-reset-password";
import { checkIfSessionActive } from "../../services/auth-service";

const AuthContainer = (props) => {
  const history = useHistory();

  useEffect(()=>{
    const user = checkIfSessionActive();

    if (user && user != null && user.account._id && user.user._id) {
      history.replace("/");
    }
  },[])
  
  return (
    <Container fluid={true}>
      <Row>
        <Col sm={12} md={6}>
          <Switch>
            <Route path="/auth/login" component={AuthLogin}></Route>
            <Route
              path="/auth/reset-password"
              component={AuthResetPassword}
            ></Route>
            <Route
              path="/auth/submit-request"
              component={AuthSubmitRequest}
            ></Route>
            <Redirect to="/auth/login" />
          </Switch>
        </Col>
        <Col sm={12} md={6}>
          <LoginCarousel />
        </Col>
      </Row>
    </Container>
  );
};

export default AuthContainer;
