import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import AddChildSubOrganizationModal from "./add-child-suborganization";
import ChildSubOrganizationListItem from "./child-suborganization-list-item";
import DeleteChildSubOrganization from "./delete-child-suborganization";
import EditChildSubOrganization from "./edit-child-suborganization";

const ChildSubOrganizationList = (props) => {
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	const [isEdit, setIsEdit] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [
		currentChildSubOrganization,
		setCurrentChildSubOrganizaiton,
	] = useState(null);
	const toggleEdit = () => setIsEdit(!isEdit);
	const toggleDelete = () => setIsDelete(!isDelete);

	useEffect(() => {
		if (!isEdit && !isDelete) {
			setCurrentChildSubOrganizaiton(null);
		}
	}, [isEdit, isDelete]);
	return (
		<Row className="table-antstrap no-border m-auto">
			<Col xs={12}>
				<Row className="thead">
					<Col className="th no-caps">
						{props.childSubOrganizationLabel.plural}
						<Button color="primary" size="xs ml-3" onClick={toggle}>
							<i className="material-icons">add</i>
						</Button>
					</Col>
				</Row>

				{props.childSubOrganizationList.length == 0 ? (
					<p>
						<FormattedMessage
							id="subOrganization.noChildSubOrganization"
							defaultMessage="No {childSubOrganizationLabel} are added yet"
							values={{
								childSubOrganizationLabel: props.childSubOrganizationLabel.name,
							}}
						/>
					</p>
				) : (
					props.childSubOrganizationList.map((subOrganization, index) => (
						<>
							<ChildSubOrganizationListItem
								isEdit={isEdit}
								setIsEdit={setIsEdit}
								isDelete={isDelete}
								setIsDelete={setIsDelete}
								subOrganizationId={props.subOrganizationId}
								childSubOrganization={subOrganization}
								setCurrentChildSubOrganizaiton={setCurrentChildSubOrganizaiton}
							/>
						</>
					))
				)}
				<AddChildSubOrganizationModal
					isOpen={modal}
					toggle={toggle}
					childSubOrganizationLabel={props.childSubOrganizationLabel}
					subOrganizationId={props.subOrganizationId}
				/>
				{currentChildSubOrganization != null && (
					<>
						<EditChildSubOrganization
							modal={isEdit}
							toggle={toggleEdit}
							subOrganizationId={props.subOrganizationId}
							subOrganization={currentChildSubOrganization}
						/>

						<DeleteChildSubOrganization
							modal={isDelete}
							toggle={toggleDelete}
							subOrganizationId={props.subOrganizationId}
							subOrganization={currentChildSubOrganization}
							name={currentChildSubOrganization.name}
							subOrganizationLabel={props.childSubOrganizationLabel.name}
						/>
					</>
				)}
			</Col>
		</Row>
	);
};

export default ChildSubOrganizationList;
