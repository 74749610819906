import React from "react";
import { Row, Col, CardText } from "antstrap";

const ViewContact = ({ contact }) => {
  return (
    <Row>
      <Col sm="12">
        <CardText>
          <i className="fa fa-phone" style={{ marginRight: "10px" }} />
          {contact && contact.fullNumber}
        </CardText>
        <CardText>
          <i className="fa fa-envelope" style={{ marginRight: "10px" }} />
          {contact && contact.email}
        </CardText>
      </Col>
    </Row>
  );
};

export default ViewContact;
