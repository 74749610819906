import { Button, Col, FormGroup, Input, Row,Label } from "antstrap";
import React from "react";
import { FormattedMessage } from "react-intl";

const StepThree = (props) => {
  let { subOrganizationLabel, toggleTab, configureSubOrgnization } = props;
  return (
    <>
      <Row className="pt-4">
        <Col sm="12">
          <FormGroup>
          <Label><FormattedMessage
              id="subOrganization.suborganizationconfirmationlabel"
              defaultMessage={`Almost there! How about creating your first {subOrganizationLabel}?`}
              values={{
                subOrganizationLabel:subOrganizationLabel.name,
              }}
            /></Label>
            <Input
              type="text"
              name="departmentName"
              placeholder={`Enter ${subOrganizationLabel.name} Name`}
              id="exampleSelect"
            />
          </FormGroup>
        </Col>
      </Row>
      <Col className="py-2 pr-0 text-right border-top-0">
        <Button
          color="primary"
          onClick={() => {
            toggleTab("2");
          }}
        >
          <FormattedMessage id="prev" defaultMessage="Prev" />
        </Button>{" "}
        <Button
          color="primary"
          onClick={() => {
            // doConfigure(true);
            configureSubOrgnization();
          }}
        >
          <FormattedMessage id="finish" defaultMessage="Finish" />
        </Button>
      </Col>
    </>
  );
};

export default StepThree;
