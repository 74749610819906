import React, { useState } from "react";
import { Collapse, Button, CardBody, Card, CardHeader } from "antstrap";
import moment from "moment";
import { USERTYPE } from "./constants";
export const TicketUpdates = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const comment = props.comment;
	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<Card
				className={
					props.index==0?"border-top-0":
					props.lastIndex ? "rounded-2" : "border-bottom-0 rounded-0 py-1"
				}
			>
				<CardHeader className="d-flex py-2" onClick={toggle}>
					<img
						className="image-rounded-md mr-3 mt-1  align-self-sm-start"
						width={50}
						src={require("assets/images/no-photo.svg")}
					/>
					<div className="comment-body">
						<p className="font-weight-bold mb-0">
							{comment && comment.user
								? comment.user.firstName + " " + comment.user.lastName
								: "Anonymous"}
						</p>
						{!isOpen ? (
							<p className="mb-0 comment-collapsed text-secondary">
								{comment.comment}
							</p>
						) : (
							<p className="small mb-0 text-secondary">
								{" "}
								{moment(new Date(Number(comment.commentedOn))).format("lll")}
							</p>
						)}
					</div>

					<div className="">
						{!isOpen ? (
							<p className="small mb-0 text-secondary">
								{" "}
								{moment(new Date(Number(comment.commentedOn))).format("lll")}
							</p>
						) : (
							""
						)}
						{comment.attachments && comment.attachments.length ? (
							<i className="material-icons">attachment</i>
						) : null}
					</div>
				</CardHeader>

				<Collapse isOpen={isOpen}>
					<CardBody className={"comment-detailed"} style={{ marginLeft: 52, paddingTop: 5, paddingBottom: 0 }}>
						<p>{comment.comment}</p>
					</CardBody>
				</Collapse>
			</Card>
			{/* <TicketSystemUpdates {...props} /> */}
		</>
	);
};

export const TicketDetailsComment = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const ticket = props.ticket;
	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<Card
				className={
					props.lastIndex ? "rounded-2" : " rounded-0 py-1"
				}
			>
				<CardHeader className="d-flex py-2" onClick={toggle}>
					<img
						className="image-rounded-md mr-3 mt-1  align-self-sm-start"
						width={50}
						src={require("assets/images/no-photo.svg")}
					/>
					<div className="comment-body">
						<p className="font-weight-bold mb-0">
						{
						ticket.userType == USERTYPE.SUBCRIBERUSER
							? ticket.subscriberUser.firstName +
							  " " +
							  ticket.subscriberUser.lastName
							: ticket.organizationUser.firstName +
							  " " +
							  ticket.organizationUser.lastName
					}
						</p>
						{!isOpen ? (
							<p className="mb-0 comment-collapsed text-secondary">
								{ticket.description}
							</p>
						) : (
							<p className="small mb-0 text-secondary">
								{" "}
								{moment(new Date(Number(ticket.createdAt))).format("lll")}
							</p>
						)}
					</div>

					<div className="">
						{!isOpen ? (
							<p className="small mb-0 text-secondary">
								{" "}
								{moment(new Date(Number(ticket.createdAt))).format("lll")}
							</p>
						) : (
							""
						)}
						{/* {comment.attachments && comment.attachments.length ? (
							<i className="material-icons">attachment</i>
						) : null} */}
					</div>
				</CardHeader>

				<Collapse isOpen={isOpen}>
					<CardBody className={"comment-detailed"} style={{ marginLeft: 52, paddingTop: 5, paddingBottom: 0 }}>
						<p>	{ticket.description}</p>
					</CardBody>
				</Collapse>
			</Card>
			{/* <TicketSystemUpdates {...props} /> */}
		</>
	);
};

export const TicketSystemUpdates = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const comment = props.comment;
	const toggle = () => setIsOpen(!isOpen);

	return (
		<Card
			className={
				props.lastIndex
					? "rounded-2"
					: "border-bottom-0 bg-light rounded-0 py-1"
			}
		>
			<CardHeader className="d-flex py-1 bg-transparent" onClick={toggle}>
				<img
					className="image-rounded-md mr-3 mt-1  align-self-sm-start"
					width={50}
				/>
				<div className="comment-body">
					<p className=" mb-0">
						<small>
							<b>
								<i>John Doe</i>
							</b>{" "}
							<span className={"text-secondary font-italic"}>
								assigned this ticket to{" "}
							</span>
							<b>
								<i>Marie Scott(You)</i>
							</b>
						</small>
					</p>
				</div>
				<div className="">
					<p className="small mb-0 text-secondary">
						{" "}
						{moment(new Date(Number(comment.commentedOn))).format("lll")}
					</p>
				</div>
			</CardHeader>
		</Card>
	);
};
