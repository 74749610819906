import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";
import {
  GET_ORGANIZATION_DETAILS,
  GET_ORGANIZATION_DETAILS_BY_ID,
  UPDATE_NAME,
  UPDATE_ABOUT,
  UPDATE_ADDRESS,
  UPDATE_WEBSITE,
  UPDATE_CONTACT,
  GET_ROLES_BY_ORGANIZATION_ID,
  UPDATE_ROLES,
  GET_ACTIVITIES,
  SAVE_ROLES,
  DELETE_ROLES
} from "../services/graphql-query-services";
import {
  setOrganizationDetails,
  setOrganizationDetailsById,
  updateNameSuccess,
  updateAboutSuccess,
  updateAddressSuccess,
  updateWebsiteSuccess,
  updateContactSuccess,
  getRolesByOrganizationId,
  updateRolesSuccess,
  getActivitiesSuccess,
  saveRolesSuccess,
  deleteRolesSuccess
} from "./action";
import { notify } from "../../components/common/notification";

export const useFetchOrganizationDetails = () => {
  const dispatch = useDispatch();
  const [fetchOrganizationDetails, { loading }] = useLazyQuery(
    GET_ORGANIZATION_DETAILS,
    {
      variables: { organizationId: "123" },
      onCompleted(data) {
        if (data && data.getAuth) {
          dispatch(setOrganizationDetails(data.getAuth));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
    }
  );
  return { fetchOrganizationDetails, loading };
};

export const useFetchOrganizationDetailsById = () => {
  const dispatch = useDispatch();
  const [fetchOrganizationDetailsById, { loading }] = useLazyQuery(
    GET_ORGANIZATION_DETAILS_BY_ID,
    {
      variables: { organizationId: useSelector((state) => state.user.organizationId) },
      onCompleted(data) {
        if (data && data.getOrganizationById) {
          dispatch(setOrganizationDetailsById(data.getOrganizationById));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchOrganizationDetailsById, loading };
};

export const useUpdateName = (organizationId, name) => {
  const dispatch = useDispatch();
  const [updateName, { loading }] = useMutation(UPDATE_NAME, {
    variables: { organizationId: organizationId, name: name },
    onCompleted(data) {
      if (data && data.updateName) {
        dispatch(updateNameSuccess(data.updateName));
        notify("Name has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateName, loading };
};

export const useUpdateAbout = (organizationId, about) => {
  const dispatch = useDispatch();
  const [updateAbout, { loading }] = useMutation(UPDATE_ABOUT, {
    variables: { organizationId: organizationId, about: about },
    onCompleted(data) {
      if (data && data.updateName) {
        dispatch(updateAboutSuccess(data.updateAbout));
        notify("About section has been updated succesfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateAbout, loading };
};

export const useUpdateAddress = (organizationId, address) => {
  const dispatch = useDispatch();
  const [updateAddress, { loading }] = useMutation(UPDATE_ADDRESS, {
    variables: { organizationId: organizationId, address: address },
    onCompleted(data) {
      if (data && data.updateAddress) {
        dispatch(updateAddressSuccess(data.updateAddress));
        notify("Address has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateAddress, loading };
};

export const useUpdateWebsite = (organizationId, website) => {
  const dispatch = useDispatch();
  const [updateWebsite, { loading }] = useMutation(UPDATE_WEBSITE, {
    variables: { organizationId: organizationId, website: website },
    onCompleted(data) {
      if (data && data.updateWebsite) {
        dispatch(updateWebsiteSuccess(data.updateWebsite));
        notify("Website URL has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateWebsite, loading };
};

export const useUpdateContact = (organizationId, contact) => {
  const dispatch = useDispatch();
  const [updateContact, { loading }] = useMutation(UPDATE_CONTACT, {
    variables: { organizationId: organizationId, contact: contact },
    onCompleted(data) {
      if (data && data.updateContact) {
        dispatch(updateContactSuccess(data.updateContact));
        notify("Contact has been updated succesfully", "success");
      }
    },
    onError(error) {
      if (error) {
       notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateContact, loading };
};

export const useFetchRolesByOrganizationId = () => {
  const dispatch = useDispatch();
  const [fetchRolesByOrganizationId, { loading }] = useLazyQuery(
    GET_ROLES_BY_ORGANIZATION_ID,
    {
      variables: { organizationId: useSelector((state) => state.user.organizationId) },
      onCompleted(data) {
        if (data && data.getRolesByOrganizationId) {
          dispatch(getRolesByOrganizationId(data.getRolesByOrganizationId));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchRolesByOrganizationId, loading };
};

export const useSaveRoles = (organizationId, name, activities) => {
  const dispatch = useDispatch();
  const [saveRoles, { loading }] = useMutation(SAVE_ROLES, {
    variables: {
      organizationId: organizationId,
      name: name,
      activities: activities,
    },
    onCompleted(data) {
      if (data && data.saveRoles) {
        dispatch(saveRolesSuccess(data.saveRoles));
        notify("Role has been saved successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { saveRoles, loading };
};

export const useUpdateRoles = (organizationId, roleId, name, activities) => {
  const dispatch = useDispatch();
  const [updateRoles, { loading }] = useMutation(UPDATE_ROLES, {
    variables: {
      organizationId: organizationId,
      roleId,
      name: name,
      activities: activities,
    },
    onCompleted(data) {
      if (data && data.updateRoles) {
        dispatch(updateRolesSuccess(data.updateRoles));
        notify("Role has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
       notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateRoles, loading };
};


export const useDeleteRoles = (organizationId, activities) => {
  const dispatch = useDispatch();
  const [deleteRoles, { loading }] = useMutation(DELETE_ROLES, {
    variables: { organizationId: organizationId, activities: activities },
    onCompleted(data) {
      if (data && data.deleteRoles) {
        dispatch(deleteRolesSuccess(data.deleteRoles));
        notify("Role is deleted successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { deleteRoles, loading };
};

export const useFetchActivities = (organizationId) => {
  const dispatch = useDispatch();
  const [fetchActivities, { loading }] = useLazyQuery(
    GET_ACTIVITIES,
    {
      onCompleted(data) {
        if (data && data.getActivities) {
          dispatch(getActivitiesSuccess(data.getActivities));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchActivities, loading };
};
