import {
  Col,


  Nav,
  NavItem,
  NavLink, Row,

  TabContent,
  TabPane
} from "antstrap";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFetchChats, useSetActiveChat } from "../../../redux/actions/chat";
import { useFetchOtherOrganizationUsers } from "../../../redux/actions/user";
import ChatList from "./chat-list";
import ChatUserList from "./chat-user-list";
const ChatUserListContainer = (props) => {
  let {activeTab,setActiveTab } = props;
  
  const [searchText, setSearchText] = useState("");
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const setActiveChat = useSetActiveChat();
  const { fetchChats,loading } = useFetchChats();
  const fetchPeopleMethod = useFetchOtherOrganizationUsers();

  const users = useSelector((state) => state.chatUsers);
  const chats = useSelector((state) => state.chats);
  useEffect(()=>{
    if(chats&&chats.length==0){
      console.log(props.currentUser._id)
      fetchChats({variables:{
        userId:props.currentUser._id
      }})
    }
    if(users&&users.length==0){
      fetchPeopleMethod.fetchOrganizationUsers({variables:{
        userId:props.currentUser._id
      }})
    }
  },[])

  return (
    <Col className="chat-left-menu">
      <Row className="py-3">
        <div className="input-group mb-0 mt-1 chat-search">
          <div className="input-group-prepend">
            <span className="input-group-text" id="search">
              <i className="material-icons font-large">search</i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-label="Username"
            aria-describedby="search"
            value={searchText}
            onChange={(e)=>{
              setSearchText(e.target.value)
            }}
          />
        </div>
      </Row>
      <Row>
        <Nav tabs className="tabs-antstrap justify-content-around w-100">
          <NavItem className="col">
            <NavLink
              className={classnames({ active: activeTab === 1 })}
              onClick={() => {
                toggleTab(1);
                if(chats&&chats.length){
                  props.setUser(null)
                 }
              }}
            >
              Recent
            </NavLink>
          </NavItem>
          <NavItem className="col">
            <NavLink
              className={classnames({ active: activeTab === 2 })}
              onClick={() => {
                toggleTab(2);
                setActiveChat(null);
                props.setUser(null)
              }}
            >
              People
            </NavLink>
          </NavItem>
          {/* <NavItem className="col">
            <NavLink
              className={classnames({ active: activeTab === 3 })}
              onClick={() => {
                toggleTab(3);
              }}
            >
              People
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent className="w-100" activeTab={activeTab}>
          <TabPane tabId={1}>
            <ChatList status={activeTab} chats={chats} chatLoading={loading} searchText={searchText} currentUser={props.currentUser}  setUser={props.setUser}  setActiveChat={   props.setActiveChat}/>
          </TabPane>
          <TabPane tabId={2}>
            <ChatUserList users={users} userLoading={fetchPeopleMethod.loading} status={activeTab} currentUser={props.currentUser} searchText={searchText} setUser={props.setUser} activeUser={props.user}/>
          </TabPane>
          {/* <TabPane tabId={3}>
            <ChatUserList status={activeTab} />
          </TabPane> */}
        </TabContent>
      </Row>
    </Col>
  );
};

export default ChatUserListContainer;
