import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "antstrap";
import classnames from "classnames";
import React, { useState,useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useUpdateSubOrganizationSettings } from "../../../../redux/actions/settings";
import StepOne from "./step-one";
import StepThree from "./step-three";
import StepTwo from "./step-two";
const data = [
	{
		_id: 1,
		name: "Department",
		plural: "Departments",
	},
	{ _id: 2, name: "Sub Department", plural: "Sub Departments" },
	{
		_id: 3,
		name: "Class",
		plural: "Classes",
	},
	{ _id: 4, name: "Sub Class", plural: "Sub Classes" },
	{
		_id: 5,
		name: "Division",
		plural: "Divisions",
	},
	{ _id: 6, name: "Sub Division", plural: "Sub Divisions" },
];
const ConfigureSubOrganizationModal = (props) => {
  const { isOpen, toggle } = props;
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isChildSubOrganization, setIsChildSubOrganization] = useState(false);
  const [subOrganizationLabel, setSuborganizationLabel] = useState({
    _id:0,
    name:"Sub Organization",
    plural:"Sub Organizations"
  }
  );

  const [childSubOrganizationLabel, setChildSubOrganizationLabel] = useState(
    {
    _id:0,
    name:"Sub Organization",
    plural:"Sub Organizations"
  }
  );

  useEffect(()=>{console.log(subOrganizationLabel.name)},[subOrganizationLabel])

  const { updateSubOrganizationSettings } = useUpdateSubOrganizationSettings(
    toggle
  );
  const configureSubOrgnization = () => {
    updateSubOrganizationSettings({
      variables: {
        subOrganization: {
          label: subOrganizationLabel,
          childLabel: childSubOrganizationLabel,
          isSubOrganization: true,
          isChildSubOrganization
        },
      },
    });
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} className="role-modal custom-modal">
      <ModalHeader toggle={toggle}>
        Configure Sub Organization
      </ModalHeader>
      <ModalBody className="pt-2">
        <Nav tabs className="tabs-justified--antstrap">
          <NavItem>
            <NavLink className={classnames({ active: activeTab === "1" })}>
              <FormattedMessage id="step" defaultMessage="STEP" /> 1
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === "2" })}>
              <FormattedMessage id="step" defaultMessage="STEP" /> 2
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === "3" })}>
              <FormattedMessage id="step" defaultMessage="STEP" /> 3
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <StepOne
              subOrganizationLabel={subOrganizationLabel}
              setSuborganizationLabel={setSuborganizationLabel}
              toggleTab={toggleTab}
              data={data}
            />
          </TabPane>
          <TabPane tabId="2">
            <StepTwo
              subOrganizationLabel={subOrganizationLabel}
              childSubOrganizationLabel={childSubOrganizationLabel}
              setChildSubOrganizationLabel={setChildSubOrganizationLabel}
              setIsChildSubOrganization={setIsChildSubOrganization}
              isChildSubOrganization={isChildSubOrganization}
              toggleTab={toggleTab}
              data={data}
            />
          </TabPane>

          <TabPane tabId="3">
            <StepThree
              subOrganizationLabel={subOrganizationLabel}
              toggleTab={toggleTab}
              configureSubOrgnization={configureSubOrgnization}
            />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
};

export default ConfigureSubOrganizationModal;
