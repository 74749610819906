import React, { useState } from "react";
import EditAbout from "./edit-about";
import ViewAbout from "./view-about";
import SectionCard from "../section-card";

const AboutCard = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setIsEdit(!isEdit);
  return (
    <SectionCard toggle={toggle} title={"About"} isEdit={isEdit}>
      {isEdit ? (
        <EditAbout {...props} toggle={toggle} />
      ) : (
        <ViewAbout {...props} />
      )}
    </SectionCard>
  );
};

export default AboutCard;
