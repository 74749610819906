import {
	Col,
	Row
} from "antstrap";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFetchLatestPosts } from "../../redux/actions/post";
import CreatePost from "./create-post";
import { Button } from "reactstrap";

import { useHistory } from "react-router-dom";
export const MobileCreatePost = () => {
	const [searchString, setSearchString] = useState("");
	const [selectedSubOrganization, setSubOrganization] = useState(false);
	const [selectedType, setSelectedType] = useState(false);
	const [dropdownOpen, setOpen] = useState(false);
	const toggleDropdown = () => setOpen(!dropdownOpen);
	const posts = useSelector((state) => state.posts);
	const { fetchLatestPosts, loading } = useFetchLatestPosts();
	const user = useSelector((state) => state.user);
	const history = useHistory()

	return (
		<Row className="mainWrapper post-create-wrapper">
			<Row className="border-bottom custom-nav mb-3">
				<Button
					className="btn-light border-0 px-3 bg-white"
					onClick={() => history.goBack()}
				>
					<i className="material-icons">arrow_back</i>
				</Button>
				<h5 className="py-3 pl-4 mb-0">New Post</h5>
			</Row>
			<Col className="mainContent mobile-content">
				<Row className ="create-post-section">
					<Col xs="12" md={8} lg={6}>
						<CreatePost isMobile={true} handleBackNavigation={() => history.goBack()} />
					</Col>
					<Col xs="12" md={4}></Col>
				</Row>
			</Col>
		</Row>
	);
};
