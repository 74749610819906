import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label
} from "antstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAddChildSubOrganization } from "../../../redux/actions/sub-organization";

const AddChildSubOrganizationModal = (props) => {
  const { isOpen, toggle, childSubOrganizationLabel } = props;
  const [name, setName] = useState("");
  

  const toggleModal = ()=>{
    setName("");
    toggle()
  }
  const { addChildSubOrganization, loading } = useAddChildSubOrganization(
    toggleModal
  );
  const addChildSubOrg = () => {
    addChildSubOrganization({
      variables: {
        subOrganizationId: props.subOrganizationId,
        childSubOrganization: {
          name,
          description: "",
        },
      },
    });
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggleModal} className={"custom-modal"}>
      <ModalHeader toggle={toggleModal}>
        Create {childSubOrganizationLabel.name}
      </ModalHeader>
      <ModalBody>
       
        <FormGroup>
          <Label>
          <FormattedMessage
          id="subOrganization.childSubOrganizationLabel"
          defaultMessage={`Name of your {childSubOrganizationLabel}`}
          values={{
            childSubOrganizationLabel:childSubOrganizationLabel.name,
          }}
        />
          </Label>
          <Input
            type="text"
            name="departmentName"
            placeholder={`Enter ${childSubOrganizationLabel.name} Name`}
            id="exampleSelect"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={addChildSubOrg}>
          <FormattedMessage id="create" defaultMessage="Create" />
        </Button>{" "}
        <Button color="link" onClick={toggleModal}>
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddChildSubOrganizationModal;
