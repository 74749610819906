import React, { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Notification from "react-web-notification";
import { useHistory } from "react-router-dom";


const WebNotification = (props) => {
  const [ignore, setIgnore] = useState(false);
  const [sw, setSw] = useState(null);
  const [title, setTitle] = useState("");
  const [options, setOptions] = useState(null);
  const history = useHistory();

  useEffect(()=>{
	if (navigator && 'serviceWorker' in navigator && sw == null) {
		navigator.serviceWorker.ready.then(registration => {
			setSw(registration)
		  });
		}

  },[])
  const notification = useSelector(state=>state.notification)
	const handlePermissionGranted = () => {
		console.log("Permission Granted");
		setIgnore(false);
	};
	const handlePermissionDenied = () => {
		console.log("Permission Denied");
		setIgnore(true);
	};
	const handleNotSupported = () => {
		console.log("Web Notification not Supported");
		setIgnore(true);
	};

	const handleNotificationOnClick = (e, tag) => {
		console.log(e, "Notification clicked tag:" + tag);
		history.push("/chat/chat/")
	};

	const handleNotificationOnError = (e, tag) => {
		console.log(e, "Notification error tag:" + tag);
	};

	const handleNotificationOnClose = (e, tag) => {
		console.log(e, "Notification closed tag:" + tag);
	};

	const handleNotificationOnShow = (e, tag) => {
	
		console.log(e, "Notification shown tag:" + tag);
  };
  
  useEffect(()=>{
	console.log("SDsdfdfsdfsdfsd Dhiraj Kate niotification o")
    if(notification && sw){
		console.log("SDsdfdfsdfsdfsd Dhiraj Kate niotification",notification)
		console.log(ignore)
		try{
			if(ignore) {
				// Notification.requestPermission().then(permission => {
				// 	if (permission === 'granted') {
				// 		setIgnore(false)
				// 	}else{
				// 		setIgnore(true)
				// 		return;
				// 	}
				//   });
				return;
			  
			}
		  
			const now = Date.now();
		  
			const title = "New Message by "+notification.name;
			const body = notification.message.message;
			const tag = now;
			const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png';
			// const icon = 'http://localhost:3000/Notifications_button_24.png';
		  
			// Available options
			// See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
			const options = {
			  tag: tag,
			  body: body,
			  icon: icon,
			  lang: 'en',
			  dir: 'ltr',
			 // sound: './sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
			}
			setTitle(title);
			setOptions(options)
	
		}catch(error){
			alert(JSON.stringify(error))
		}
       
		// if (WebNotification.isSupported) {
		// 	navigator.serviceWorker.ready.then(registration => {
		// 		registration.showNotification('Hello!', options);
		// 	  });
		//   }
		
    }
  },[notification])

	return (
		<Notification
			ignore={ignore && title !== ""}
			askAgain={true}
			notSupported={handleNotSupported}
			onPermissionGranted={handlePermissionGranted}
			onPermissionDenied={handlePermissionDenied}
			onShow={handleNotificationOnShow}
			onClick={handleNotificationOnClick}
			onClose={handleNotificationOnClose}
			onError={handleNotificationOnError}
			timeout={5000}
			// title={props.title}
      // options={props.options}
      		title={title}
			options={options}
			swRegistration={sw}
		/>
	);
};

export default WebNotification;
