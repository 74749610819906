import _ from "lodash";
import {
  ADD_ORGANIZATION_USER_SUCCESS,
  FETCH_CHAT_USERS,
  SET_USER_ONLINE,
  UPDATE_ORGANIZATION_USER_SUCCESS,
} from "../actions/types";
const chatUsersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_CHAT_USERS:
      return action.payload;
  
    case SET_USER_ONLINE: {
      let newState = state.map((user)=>{
        if(user.userId == action.payload.userId){
          return {...user,...action.payload}
        }
        return user;
      })
  
      return newState;
    }
  
    default:
      return state;
  }
};

export default chatUsersReducer;
