import React from "react";
import { Col, Row } from "reactstrap";
import SubOrganizationListItem from "./suborganization-list-item";

const SubOrganizationList = (props) => {
  return (
    <Row className="border table-antstrap ml-0 mr-0">
      <Col>
        <Row className="thead bg-light">
          <Col className="th">
            {props.subOrganizationLabel.name.toUpperCase()} NAME
          </Col>
        </Row>

        {props.subOrganizations.length == 0 ? (
          <p className="text-center">No Suborganizations are added yet</p>
        ) : (
          props.subOrganizations.map((subOrganization) => (
            <SubOrganizationListItem
              subOrganization={subOrganization}
              subOrganizationLabel={props.subOrganizationLabel}
              childSubOrganizationLabel={props.childSubOrganizationLabel}
              isChildSubOrganization={props.isChildSubOrganization}
            />
          ))
        )}
      </Col>
    </Row>
  );
};

export default SubOrganizationList;
