import { gql } from "apollo-boost";

export const GET_ROLES = gql`
  query getRoles {
    getRoles {
      _id
      organizationId
      name
      activities
      isSuperAdmin
      createdAt
      numberOfUsers
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($roleId: String, $name: String, $activities: [String]) {
    updateRole(roleId: $roleId, name: $name, activities: $activities) {
      _id
      organizationId
      name
      activities
      isSuperAdmin
      createdAt
    }
  }
`;

export const REMOVE_ROLE = gql`
  mutation removeRole($roleId: String) {
    removeRole(roleId: $roleId) {
      _id
      organizationId
      name
      activities
      isSuperAdmin
      createdAt
    }
  }
`;

export const ADD_ROLE = gql`
  mutation addRole($name: String, $activities: [String]) {
    addRole(name: $name, activities: $activities) {
      _id
      organizationId
      name
      activities
      isSuperAdmin
      createdAt
    }
  }
`;

export const GET_ACTIVITIES = gql`
  query getActivities {
    getActivities {
      _id
      name
      featureName
    }
  }
`;
