import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "reactstrap";
import MenuButton from "../../common/menu-button";

const ChildSubOrganizationListItem = (props) => {
  let { childSubOrganization } = props;

  const menus = [
    {
      name: <FormattedMessage id="edit" defaultMessage="Edit" />,
      onClick: () => {
        props.setIsEdit(!props.isEdit);
        props.setCurrentChildSubOrganizaiton(childSubOrganization);
      },
    },
    {
      name: <FormattedMessage id="delete" defaultMessage="Delete" />,
      onClick: () => {
        props.setIsDelete(!props.isDelete);
        props.setCurrentChildSubOrganizaiton(childSubOrganization);
      },
    },
  ];
  return (
    <>
      <Row className="tr  pointer">
        <Col xs={9} sm={10} md={11} className="td">
          {childSubOrganization.name}
        </Col>
        <Col xs={3} sm={2} md={1} className="td text-center">
          {" "}
          <MenuButton menuButtons={menus} />
        </Col>
      </Row>
    </>
  );
};

export default ChildSubOrganizationListItem;
