export const initState = {
  posts: [
    { id: "1", name: "Post 1" },
    { id: "2", name: "Post 2" },
    { id: "3", name: "Post 3" },
  ],
  organizationDetails: [],
  profile: {
    activities: [],
  },
  roles: null,
  users: {
      users: []
  },
  notifications: null,
  settings : {
    activeTab : 1
  },
  ticket: {
      tickets: [],
      comments: []
  }
};
