import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Collapse, Row } from "reactstrap";
import MenuButton from "../../common/menu-button";
import ChildSubOrganizationList from "./child-suborganization-list";
import DeleteSubOrganization from "./delete-suborganization";
import EditSubOrganization from "./edit-suborganization";
import ViewSubOrganizationDetails from "./view-suborganization-details";

const SubOrganizationListItem = (props) => {
	let {
		subOrganization,
		subOrganizationLabel,
		childSubOrganizationLabel,
		isChildSubOrganization,
	} = props;
	const [isOpen, setIsOpen] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const toggleEdit = () => setIsEdit(!isEdit);
	const toggleDelete = () => setIsDelete(!isDelete);

	const menus = [
		{
			name: <FormattedMessage id="edit" defaultMessage="Edit" />,
			onClick: () => {
				setIsEdit(!isEdit);
			},
		},
		{
			name: <FormattedMessage id="delete" defaultMessage="Delete" />,
			onClick: () => {
				setIsDelete(!isDelete);
			},
		},
	];
	return (
		<>
			<Row className="tr ">
				<Col
					xs={9}
					sm={10}
					md={11}
					className="td pointer d-flex align-items-center"
					onClick={() => {
						if (isChildSubOrganization) {
							toggle();
						}
					}}
				>
					<ViewSubOrganizationDetails
						subOrganizationName={subOrganization.name}
					/>
				</Col>
				<Col xs={3} sm={2} md={1} className="td text-center">
					{" "}
					<MenuButton menuButtons={menus} />
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<Collapse isOpen={isOpen && isChildSubOrganization}>
						<ChildSubOrganizationList
							subOrganizationId={subOrganization._id}
							childSubOrganizationLabel={childSubOrganizationLabel}
							childSubOrganizationList={subOrganization.childSubOrganization}
						/>
					</Collapse>
				</Col>
			</Row>
			{isEdit ? (
				<EditSubOrganization
					modal={isEdit}
					toggle={toggleEdit}
					subOrganizationName={subOrganization.name}
					subOrganizationId={subOrganization._id}
				/>
			) : (
				<></>
			)}
			{isDelete ? (
				<DeleteSubOrganization
					modal={isDelete}
					toggle={toggleDelete}
					subOrganizationId={subOrganization._id}
					name={subOrganization.name}
					subOrganizationLabel={props.subOrganizationLabel.name}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default SubOrganizationListItem;
