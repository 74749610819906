import React from "react";
import { Row, Col } from "reactstrap";

const AuthFooter = () => {
  return (
    <>
      <Row className="p-0 p-md-3 mt-1">
        <Col
          sm={12}
          className="d-flex flex-direction-row  align-items-baseline justify-content-center"
        >
          <button
            size="md"
            color="primary"
            className=" text-center btn btn-link"
            //onClick={}
          >
            Terms & Conditions
          </button>
          <button
            size="md"
            color="primary"
            className=" text-center btn btn-link"
            // onClick={}
          >
            Privacy Policy
          </button>
          <button
            size="md"
            color="primary"
            className=" text-center btn btn-link"
            // onClick={}
          >
            Help
          </button>
        </Col>
      </Row>
      <Row className="pr-3 pl-3 pb-3 mt-0">
        <Col
          sm={12}
          className="d-flex flex-direction-row  align-items-baseline justify-content-center"
        >
          <p className="h-60">@2020 Powered by Antridge Technology Pvt Ltd</p>{" "}
        </Col>
      </Row>
    </>
  );
};

export default AuthFooter;
