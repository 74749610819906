import React, { useState } from "react";
import {
	Button,
    ButtonDropdown,
	Card,
	CardFooter,
	Col,
    DropdownMenu,
    DropdownToggle,
	FormGroup,
	Input,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from "reactstrap";
import classnames from "classnames";
import CreateNewPost from "./create-new-post";
import CreateNewPoll from "./create-new-poll";
const CreatePost = (props) => {
	const [activeTab, setActiveTab] = useState("1");
	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<Row>
			<Col sm="12" >
				<Card className="post new-post">
					<Row>
						<Col sm="12">
							<Nav tabs className="tabs-antstrap">
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === "1" })}
										onClick={() => {
											toggleTab("1");
										}}
									>
										Post
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === "2" })}
										onClick={() => {
											toggleTab("2");
										}}
									>
										Polls
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === "3" })}
										onClick={() => {
											toggleTab("3");
										}}
									>
										Event
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									{/* <UserTable user={users} /> */}
									<CreateNewPost {...props}/>
								</TabPane>
								<TabPane tabId="2">
									<CreateNewPoll {...props}/>
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>
	);
};

export default CreatePost;
