import React from "react";
import { Row, Col, CardText } from "antstrap";

const ViewName = ({ name }) => {
  return (
    <Row>
      <Col sm="12">
        <CardText>{name}</CardText>
      </Col>
    </Row>
  );
};

export default ViewName;
