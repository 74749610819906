import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactTelInput from "react-telephone-input";
import "react-telephone-input/css/default.css";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "antstrap";
import { useUpdateOrganizationUser } from "redux/actions/user";

const EditUser = ({ modal, toggle, user }) => {
  const [currentUser, setCurrentUser] = useState({ ...user.user });
  const [currentUserAuth, setAuth] = useState({ ...user });
  const roles = useSelector((state) => state.roles);
  const suborganizations = useSelector((state) => state.subOrganizations);
  const { updateOrganizationUser } = useUpdateOrganizationUser();

  const handleEdit = () => {
    updateOrganizationUser({
      variables: {
        roles: currentUserAuth.roles,
        userId: currentUserAuth.userId,
        subOrganizations: currentUserAuth.subOrganizations,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        countryCode: currentUser.countryCode,
        mobileNumber: currentUser.mobileNumber,
      },
    });

    toggle();
  };

  const handleInputChange = (phone) => {
    const [countryCode, mobileNumber] = phone.split(" ");
    setCurrentUser({
      ...currentUser,
      countryCode: countryCode,
      mobileNumber: mobileNumber,
    });
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} className="custom-modal">
      <ModalHeader toggle={toggle}>Update User</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">First Name</Label>
            <Input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Enter First name"
              value={currentUser.firstName}
              onChange={(e) =>
                setCurrentUser({ ...currentUser, firstName: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">Last Name</Label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Enter Last name"
              value={currentUser.lastName}
              onChange={(e) =>
                setCurrentUser({ ...currentUser, lastName: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="email address"
              value={currentUser.email}
              onChange={(e) =>
                setCurrentUser({ ...currentUser, email: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="usernumber">Phone Number</Label>
            <ReactTelInput
              defaultCountry="in"
              flagsImagePath={require("assets/images/flags.png")}
              onChange={handleInputChange}
              value={`${currentUser.countryCode} ${currentUser.mobileNumber}`}
            />
          </FormGroup>
          <FormGroup>
            <Label for="role">Role</Label>
            <Input
              type="select"
              name="role"
              id="role"
              value={
                currentUserAuth.rolesDetails[0] &&
                currentUserAuth.rolesDetails[0]._id
              }
              onChange={(e) =>
                setCurrentUser({ ...currentUser, role: e.target.value })
              }
            >
              {roles &&
                roles.map((role) => {
                  return <option value={role._id}>{role.name}</option>;
                })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="suborganization">Sub Organization</Label>
            <Input
              type="select"
              name="subOrganization"
              id="subOrganization"
              value={currentUser.subOrganization}
              onChange={(e) =>
                setCurrentUser({
                  ...currentUser,
                  subOrganization: e.target.value,
                })
              }
            >
              {suborganizations &&
                suborganizations.map((subOrganization) => {
                  return (
                    <option value={subOrganization._id}>
                      {subOrganization.name}
                    </option>
                  );
                })}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => handleEdit()}>
          Edit User
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditUser;
