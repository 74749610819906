import React, { useRef, useEffect } from "react";
import Sidebar from "./sidebar/main-sidebar";
import Navbar from "./navbar/admin-navbar";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import routes from "./routes/admin-routes";
import PerfectScrollbar from "perfect-scrollbar";
import { setUser, setAuth } from "../redux/actions/user";
import { useDispatch } from "react-redux";
import { checkIfSessionActive } from "../services/auth-service";

let ps;

const AdminDashboard = (props) => {
  const mainPanelRef = useRef(null);
  const contentRef = useRef(null)
  const history = useHistory();

  const dispatch = useDispatch();
  const user = checkIfSessionActive();

  if (
    user &&
    user != null &&
    user.account._id &&
    user.user._id &&
    user.token &&
    user.token !== ""
  ) {
    setUser(user.user, dispatch);
    setAuth({ account: user.account, token: user.token }, dispatch);
  } else {
    history.push("/auth");
  }

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      if (mainPanelRef.current != null) {
        ps = new PerfectScrollbar(mainPanelRef.current);
      }
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      // if (prop.layout === '/admin') {
      //   return (
      //     <Route
      //       path={prop.layout + prop.path}
      //       component={prop.component}
      //       key={key}
      //     />
      //   );
      // } else {
      //   return null;
      // }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          mainPanelRef={mainPanelRef}
        />
      );
    });
  };

  return (
    <div className="admin-wrapper" ref={mainPanelRef}>
      <Navbar></Navbar>
      <div className="container p-0">
        <Sidebar {...props} routes={routes}></Sidebar>
        <div className="main-panel">
          <div className="content" ref={contentRef}>
            <Switch>
              {getRoutes(routes)}
              <Redirect to="/admin/dashboard"></Redirect>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
