import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useFetchOrganizationDetails } from "../../../redux/actions/organization";
import SubOrganizationDashboard from "./dashboard";
import EnableSubOrganization from "./enable-suborganization";

export const SubOrganization = () => {
  const settings = useSelector((state) => state.settings);
  const { fetchOrganizationDetails, loading } = useFetchOrganizationDetails();
  useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
  useEffect(() => {
    if (Object.keys(settings).length == 0) {
      fetchOrganizationDetails();
    }
  }, [settings]);

  return loading ? (
    <h3>
      <FormattedMessage id="{loading}" defaultMessage="Loading" />
    </h3>
  ) : settings.subOrganization ? (
    settings.subOrganization.isSubOrganization ? (
      <SubOrganizationDashboard />
    ) : (
      <EnableSubOrganization />
    )
  ) : (
    <h3>
      <FormattedMessage
        id="{subOrganization.fetchError}"
        defaultMessage="Error while fetching settings please try again"
      />
    </h3>
  );
};
