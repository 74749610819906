import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactTelephoneInput } from "react-telephone-input";
import {
	Col,
	Row,
	Button,
	Container,
	FormGroup,
	Form,
	Label,
	Input,
	Badge,
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
	InputGroup,
	InputGroupAddon,
} from "reactstrap";
import { useFetchRoles } from "../../redux/actions/roles";
import { useFetchSubOrganizations } from "../../redux/actions/sub-organization";
import { useUpdateOrganizationUser } from "../../redux/actions/user";
import RolesDropdown from "../common/roles-dropdown";
import SubOrganizationsDropdown from "../common/sub-organization";
const EditUser = (props) => {
	const [rolesList, setRoles] = useState([]);
	const [subOrganizationList, setSubOrganization] = useState([]);
	const [rolesById, setRolesById] = useState({});
	const [subOrgsById, setSubOrgsById] = useState({});
	const [files, setFiles] = useState([]);
	const [dropdownDept, setDropdownDept] = useState(false);
	const [dropdownRole, setDropdownRole] = useState(false);
	const toggleDept = () => setDropdownDept((prevState) => !prevState);
	const toggleRole = () => setDropdownRole((prevState) => !prevState);
	const roles = useSelector((state) => state.roles);
	const subOrganizations = useSelector((state) => state.subOrganizations);
	const history = useHistory();
	const { fetchRoles } = useFetchRoles();
	const { fetchSubOrganizations, loading } = useFetchSubOrganizations();
	const [currentUser, setCurrentUser] = useState({});
	const [currentUserAuth, setAuth] = useState({});
	useEffect(() => {
		if (props.location.state && props.location.state.user) {
			setCurrentUser(props.location.state.user.user);
			setAuth(props.location.state.user);
			setRoles(props.location.state.user.roles);
			setSubOrganization(props.location.state.user.subOrganizations);
			fetchRoles();
			fetchSubOrganizations();
		} else {
			history.push("/user");
		}
	}, []);

	useEffect(() => {
		let roleObject = {};
		if (roles && roles.length > 0) {
			roles.map((role) => {
				roleObject[role._id] = role;
			});
			console.log(roleObject);
			setRolesById(roleObject);
		}
	}, [roles]);

	useEffect(() => {
		let subOrgId = {};
		if (subOrganizations && subOrganizations.length > 0) {
			subOrganizations.map((subOrganization) => {
				subOrgId[subOrganization._id] = subOrganization;
			});

			setSubOrgsById(subOrgId);
		}
	}, [subOrganizations]);

	const { updateOrganizationUser } = useUpdateOrganizationUser(history);

	const onFilesChange = (file) => {
		setFiles(file);
		let image = document.getElementById("output");
		image.src = URL.createObjectURL(file[0]);
	};

	const onFilesError = (error, file) => {
		console.log("error code " + error.code + ": " + error.message);
	};

	const handleUpdate = () => {
		updateOrganizationUser({
			variables: {
				roles: rolesList,
				userId: currentUserAuth.userId,
				subOrganizations: subOrganizationList,
				firstName: currentUser.firstName,
				lastName: currentUser.lastName,
				countryCode: currentUser.countryCode,
				mobileNumber: currentUser.mobileNumber,
			},
		});
	};

	const handleInputChange = (phone) => {
		const [countryCode, mobileNumber] = phone.split(" ");
		setCurrentUser({
			...currentUser,
			countryCode: countryCode,
			mobileNumber: mobileNumber,
		});
	};
	return (
		<Row className="mainWrapper new-user-wrapper">
			<Col md={12} className="border-right">
				<Row className="border-bottom mb-3 helpdesk-header bg-white">
					<Button
						className="btn-light border-0 px-4 bg-white"
						onClick={() => props.history.goBack()}
					>
						<i className="material-icons">arrow_back</i>
					</Button>
					<h5 className="py-3 pl-4 mb-0">Edit User</h5>
					<div className="user-info-toggler"></div>
				</Row>
				<Container fluid>
					<Form>
						<Row>
							<Col sm="12">
								<p className={"text-uppercase section-header"}>
									{/* <FormattedMessage id="raised_by" /> */}
									Personal
								</p>
							</Col>
						</Row>
						<Row className={"mb-3"}>
							<Col sm="12">
								<div className="d-flex py-2">
									<img
										className="image-rounded-lg  align-self-center"
										src={require("assets/images/no-photo.svg")}
									/>
									<div className="d-flex flex-column justify-content-center">
										{/* <FormattedMessage id="assigned_to" /> */}
										<p className="mb-0 font-weight-bold">Profile Photo</p>
										<Button color="link" className={"px-0 py-0"} >
										Upload
										</Button>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								{" "}
								<FormGroup>
									<Label for="firstname">
										<FormattedMessage id="first_name" />
									</Label>
									<Input
										type="text"
										name="firstname"
										id="firstname"
										placeholder="Enter First name"
										value={currentUser.firstName}
										onChange={(e) =>
											setCurrentUser({
												...currentUser,
												firstName: e.target.value,
											})
										}
									/>
								</FormGroup>
							</Col>
							<Col md={4}>
								{" "}
								<FormGroup>
									<Label for="lastname">
										<FormattedMessage id="last_name" />
									</Label>
									<Input
										type="text"
										name="lastname"
										id="lastname"
										placeholder="Enter Last name"
										value={currentUser.lastName}
										onChange={(e) =>
											setCurrentUser({
												...currentUser,
												lastName: e.target.value,
											})
										}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<FormGroup>
									<Label for="email">
										<FormattedMessage id="email" />
									</Label>
									<Input
										type="email"
										name="email"
										id="email"
										placeholder="email address"
										value={currentUser.email}
										onChange={(e) =>
											setCurrentUser({ ...currentUser, email: e.target.value })
										}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={4}>
								<FormGroup>
									<Label for="usernumber">
										<FormattedMessage id="phone_number" />
									</Label>
									<ReactTelephoneInput
										defaultCountry="in"
										flagsImagePath={require("assets/images/flags.png")}
										onChange={handleInputChange}
										value={`${currentUser.countryCode} ${currentUser.mobileNumber}`}
									/>
								</FormGroup>
							</Col>
						</Row>
						<div className="border-bottom my-3"></div>
						<Row>
							<Col sm="12" className="d-flex flex-row align-items-center mb-3">
								<span className={"text-uppercase section-header"}>
									{/* <FormattedMessage id="raised_by" /> */}
									Role
								</span>
								<RolesDropdown
									isAdd
									// value={selectedSubOrganization}
									onSelect={(value) => {
										if (rolesList.indexOf(value._id) == -1) {
											setRoles([...rolesList, value._id]);
										}
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={8}>
								{rolesList &&
									rolesList.map((role, index) => {
										return (
											<div
												className="light mr-2 d-inline-flex tags badge badge-ant"
												pill
											>
												<span>
													{rolesById[role] ? rolesById[role].name : "Unknown"}{" "}
												</span>{" "}
												<i
													className="material-icons ml-2 cursor-pointer"
													onClick={() => {
														let newRolesList = [...rolesList];
														newRolesList.splice(index, 1);
														setRoles(newRolesList);
													}}
												>
													close
												</i>
											</div>
										);
									})}

								
							</Col>
						</Row>
						<div className="border-bottom my-3"></div>
						<Row>
						<Col sm="12" className="d-flex flex-row align-items-center mb-3">
								<span className={"text-uppercase section-header"}>
									{/* <FormattedMessage id="raised_by" /> */}
									Department
								</span>
								<SubOrganizationsDropdown
									isAdd
									// value={selectedSubOrganization}
									onSelect={(value) => {
										if (
											subOrganizationList.indexOf(
												value._id
											) == -1
										) {
											setSubOrganization([
												...subOrganizationList,
												value._id,
											]);
										}
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={8}>
								{subOrganizationList &&
									subOrganizationList.map((subOrganization, index) => {
										return (
											<div
												className="light mr-2 d-inline-flex tags badge badge-ant"
												pill
											>
												<span>
													{subOrgsById[subOrganization]
														? subOrgsById[subOrganization].name
														: "Unknown"}{" "}
												</span>{" "}
												<i
													className="material-icons ml-2 cursor-pointer"
													onClick={() => {
														let newSubOrgsList = [...subOrganizationList];
														newSubOrgsList.splice(index, 1);
														setSubOrganization(newSubOrgsList);
													}}
												>
													close
												</i>
											</div>
										);
									})}
								
							</Col>
						</Row>
						<div className="border-bottom my-3"></div>
						<Row className="my-3">
							<Col sm="12">
								<Button color="primary" onClick={() => handleUpdate()}>
									Save
								</Button>{" "}
							</Col>
						</Row>
					</Form>
				</Container>
			</Col>
		</Row>
	);
};

export default EditUser;
