import { FETCH_CHATS, FETCH_CHAT_BY_USER, FETCH_CHAT_MESSAGES, FETCH_OLD_CHAT_MESSAGES, RECEIVE_MESSAGE, RECEIVE_MESSAGE_SENT_STATUS, SETUP_CHAT, SET_ACTIVE_CHAT } from "../types";

export const getChatsAction = (data) => {
	return {
		type: FETCH_CHATS,
		payload: data,
	};
};

export const getChatMessagesAction = (data) => {
	return {
		type: FETCH_CHAT_MESSAGES,
		payload: data,
	};
};


export const getOldChatMessagesAction = (data) => {
	return {
		type: FETCH_OLD_CHAT_MESSAGES,
		payload: data,
	};
};

export const setupChatAction = (data) => {
	return {
		type: SETUP_CHAT,
		payload: data,
	};
};

export const receiveMessageAction = (data) => {
	return {
		type: RECEIVE_MESSAGE,
		payload: data,
	};
};

export const receiveSentMessageStatusAction = (data) => {
	return {
		type: RECEIVE_MESSAGE_SENT_STATUS,
		payload: data,
	};
};


export const fetchChatByUserIdsAction = (data) => {
	return {
		type: FETCH_CHAT_BY_USER,
		payload: data,
	};
};

export const setActiveChatAction = (data) => {
	return {
		type: SET_ACTIVE_CHAT,
		chat: data,
	};
};
