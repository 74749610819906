import {
  CHECKED_SUBSCRIBERS,
  FETCH_SUBSCRIBERS,
  UPDATE_SUBSCRIBER_STATUS,
  UPDATE_SUBSCRIBER_ACTIVE_STATUS,
} from "../actions/types";

const subscriberReducer = (state = [], action) => {
  if (action.type === FETCH_SUBSCRIBERS) {
    let newState = action.subscriberData;
    //newState.push(action.subscriberData.subscriberList);
    return newState;
  } else if (action.type === CHECKED_SUBSCRIBERS) {
    let newState = [...state];
    //newState.push(action.name);
    return newState;
  } else if (action.type === UPDATE_SUBSCRIBER_STATUS) {
    let newState = [...action.subscriberData.subscriberList];
    return newState;
  }
  return state;
};
export default subscriberReducer;
