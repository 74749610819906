import { useLazyQuery, useMutation } from "react-apollo";
import { useDispatch } from "react-redux";
import { notify } from "../../components/common/notification";
import {
  GET_ORGANIZATION_DETAILS,
  UPDATE_ABOUT,
  UPDATE_ADDRESS,
  UPDATE_CONTACT,
  UPDATE_NAME,
  UPDATE_WEBSITE,
} from "../services/organization";
import { setOrganizationDetails } from "./action";
import { setAdminSettings } from "./redux-actions/settings";

export const useFetchOrganizationDetails = () => {
  const dispatch = useDispatch();
  const [fetchOrganizationDetails, { loading }] = useLazyQuery(
    GET_ORGANIZATION_DETAILS,
    {
      onCompleted(data) {
        if (data && data.getOrganizationDetails) {
          dispatch(setOrganizationDetails(data.getOrganizationDetails));
          dispatch(setAdminSettings(data.getOrganizationDetails.settings));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
    }
  );

  return { fetchOrganizationDetails, loading };
};

export const useUpdateName = (name) => {
  const dispatch = useDispatch();
  const [updateName, { loading }] = useMutation(UPDATE_NAME, {
    variables: { name: name },
    onCompleted(data) {
      if (data && data.updateOrgName) {
        dispatch(setOrganizationDetails(data.updateOrgName));
        notify("Name has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateName, loading };
};

export const useUpdateAbout = (about) => {
  const dispatch = useDispatch();
  const [updateAbout, { loading }] = useMutation(UPDATE_ABOUT, {
    variables: { about: about },
    onCompleted(data) {
      if (data && data.updateOrgAbout) {
        dispatch(setOrganizationDetails(data.updateOrgAbout));
        notify("About section has been updated succesfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateAbout, loading };
};

export const useUpdateAddress = (address) => {
  const dispatch = useDispatch();
  const [updateAddress, { loading }] = useMutation(UPDATE_ADDRESS, {
    variables: { address: address },
    onCompleted(data) {
      if (data && data.updateOrgAddress) {
        dispatch(setOrganizationDetails(data.updateOrgAddress));
        notify("Address has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateAddress, loading };
};

export const useUpdateWebsite = (website) => {
  const dispatch = useDispatch();
  const [updateWebsite, { loading }] = useMutation(UPDATE_WEBSITE, {
    variables: { website: website },
    onCompleted(data) {
      if (data && data.updateOrgWebsite) {
        dispatch(setOrganizationDetails(data.updateOrgWebsite));
        notify("Website URL has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateWebsite, loading };
};

export const useUpdateContact = (contact) => {
  const dispatch = useDispatch();
  const [updateContact, { loading }] = useMutation(UPDATE_CONTACT, {
    variables: { contact: contact },
    onCompleted(data) {
      if (data && data.updateOrgContact) {
        dispatch(setOrganizationDetails(data.updateOrgContact));
        notify("Contact has been updated succesfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateContact, loading };
};
