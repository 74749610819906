import React, { useState } from "react";
import { Row } from "antstrap";
import Post from "./post";
import SinglePoll from "./single-poll";
import Poll from "./poll";

const PostList = (props) => {
	// const [postData, setpostData] = useState({});

	// const posts = [
	//     {
	//       username: "Kit's College Of Engineering",
	//       postDetails: "May 31, 2020 at 3:15 PM",
	//       image: [
	//         { url: "https://via.placeholder.com/150x101" },
	//         { url: "https://via.placeholder.com/150x102" },
	//         { url: "https://via.placeholder.com/150x103" },
	//         { url: "https://via.placeholder.com/150x104" },
	//         { url: "https://via.placeholder.com/150x105" },
	//       ],
	//       postCaption:
	//         "Specify the width first, then height. Height is optional: if no height is specified, your placeholder image will be a square. So this example"
	//     },
	//     {
	//       username: "DY Patil College",
	//       postDetails: "May 31, 2020 at 3:15 PM",
	//       image: [{ url: "https://via.placeholder.com/150x80" }],
	//       postCaption:
	//         "Just specify the image size after our URL (https://via.placeholder.com/) and you’ll get a placeholder image. You can specify image size & format (.GIF, .JPG, .PNG, .WEBP), background color & text color, as well as the text.",
	//     },
	//     {
	//       username: "College Of Engineering, Mumbai",
	//       postDetails: "May 31, 2020 at 3:15 PM",
	//       image: [],
	//       postCaption:
	//         "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
	//     },
	//   ];

	//   const polls = [
	//     {
	//       username: "Kit's College Of Engineering",
	//       postDetails: "May 31, 2020 at 3:15 PM",
	//       image:[],
	//       options: [
	//         { option: "https://via.placeholder.com/150x101https://via.placeholder.com/150x101https:// via.placeholder.com/150x101https://via.placeholder.com/150x101" },
	//         { option: "https://via.placeholder.com/150x102" },
	//         { option: "https://via.placeholder.com/150x103" },
	//         { option: "https://via.placeholder.com/150x104" },
	//         { option: "https://via.placeholder.com/150x105" },
	//       ],
	//       postCaption:
	//         "Specify the width first, then height. Height is optional: if no height is specified, your placeholder image will be a square. So this example"
	//     },

	//   ];

	

	return (
		<>
			{props.posts.map((post, index) => {
				if (post.type == 1) {
				return	<Post post ={post}></Post>;
				} else if (post.type == 2) {
				return	<Poll post ={post}></Poll>;
				}
			})}
		</>
	);
};

export default PostList;
