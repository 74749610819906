import React, { useState } from "react";
// import LanguageIcon from '@material-ui/icons/Language';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import Avatar from '@material-ui/core/Avatar';
// import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import {
	Navbar
} from "antstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLogoutUserFromCurrentSession } from "../../redux/actions/user";

const MobileNavbar = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const user = useSelector((state) => state.user);
	const history = useHistory();
	const toggle = () => setIsOpen(!isOpen);
	const toggleSidebar = () => {
		document.documentElement.classList.toggle("nav-open");
	};

	const { logoutUser, loading } = useLogoutUserFromCurrentSession();

	return (
		<>
			<div>
				<Navbar color="white" light expand="lg" className="navbar-plain">
					<div className="navbar-wrapper">
						<div
							className={classnames("navbar-toggle", {
								toggled: props.sidebarOpen,
							})}
						>
							<button
								className="navbar-toggler"
								type="button"
								onClick={toggleSidebar}
							>
								<span className="navbar-toggler-bar bar1" />
								<span className="navbar-toggler-bar bar2" />
								<span className="navbar-toggler-bar bar3" />
							</button>
						</div>
					</div>

				</Navbar>
			</div>
		</>
	);
};

export default MobileNavbar;
