import { Input } from "antstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEditSubOrganization } from "../../../redux/actions/sub-organization";

const EditSubOrganization = (props) => {
  const [name, setName] = useState(props.subOrganizationName);
  const { updateSubOrganization } = useEditSubOrganization(props.toggle);
  const updateSubOrg = () => {
    updateSubOrganization({
      variables: {
        subOrganizationId: props.subOrganizationId,
        name,
        description: "",
      },
    });
  };

  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle} className="ml-modal custom-modal">
      <ModalHeader toggle={props.toggle}>
        <FormattedMessage
          id="subOrganization.edit.title"
          defaultMessage="Edit sub organization"
        />
      </ModalHeader>
      <ModalBody>
        <Input
          type="text"
          value={name}
          maxLength={250}
          onChange={(e) => setName(e.target.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={updateSubOrg}>
          <FormattedMessage id="common.save" defaultMessage="Save" />
        </Button>{" "}
        <Button color="link" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditSubOrganization;
