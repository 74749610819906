import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";

import { notify } from "../../components/common/notification";
import { FETCH_CHAT_MESSAGES_QUERY, GET_ALL_CHATS_QUERY, GET_CHAT_BY_USERIDS_QUERY, SETUP_CHAT_MUTATION } from "../services/chat-services";
import { fetchChatByUserIdsAction, getChatMessagesAction, getChatsAction, getOldChatMessagesAction, setActiveChatAction, setupChatAction } from "./redux-actions/chat";
import { getSocket, SocketEvents } from "../services/socket-service";
import { RECEIVE_MESSAGE } from "./types";

export const useFetchChats = () => {
  const dispatch = useDispatch();
  const [fetchChats, { loading }] = useLazyQuery(
    GET_ALL_CHATS_QUERY,
    {
      onCompleted(data) {
        if (data && data.fetchChats) {
          dispatch(getChatsAction(data.fetchChats));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchChats, loading };
};

export const useFetchChatByUserIds = () => {
  const dispatch = useDispatch();
  const setActiveChat = useSetActiveChat();
  const [fetchChat, { loading }] = useLazyQuery(
    GET_CHAT_BY_USERIDS_QUERY,
    {
      onCompleted(data) {
        if (data && data.fetchChatByUserIds) {
          dispatch(fetchChatByUserIdsAction(data.fetchChatByUserIds));
          setActiveChat(data.fetchChatByUserIds)
        } else {
          setActiveChat(null)
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchChat, loading };
};


export const useFetchChatMessages = (chatId) => {
  const dispatch = useDispatch();
  const [fetchChatMessages, { loading }] = useLazyQuery(FETCH_CHAT_MESSAGES_QUERY, {
    onCompleted(data) {
      if (data && data.fetchChatMessages) {
        if (data.fetchChatMessages.length) {

          // nextPage()
          dispatch(getChatMessagesAction({ chatId, messages: data.fetchChatMessages }));
        }

      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { fetchChatMessages, loading };
};

export const useFetchOldChatMessages = (chatId) => {
  const dispatch = useDispatch();
  const [fetchOldChatMessages, { loading }] = useLazyQuery(FETCH_CHAT_MESSAGES_QUERY, {
    onCompleted(data) {
      if (data && data.fetchChatMessages) {
        if (data.fetchChatMessages.length) {

          dispatch(getOldChatMessagesAction({ chatId, messages: data.fetchChatMessages }));
        }

      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { fetchOldChatMessages, loading };
};


export const useSetupChat = (setActiveTab, getMessage, userId, setMessage) => {
  const dispatch = useDispatch();
  const setActiveChat = useSetActiveChat();
  const [setupChat, { loading }] = useMutation(SETUP_CHAT_MUTATION, {
    onCompleted(data) {
      if (data && data.setupChat) {
        dispatch(setupChatAction(data.setupChat));
        setActiveChat(data.setupChat)
        //setActiveTab(1)
        let socket = getSocket();
        let message = getMessage();
        socket.emit(SocketEvents.SEND_FIRST_MESSAGE, { message, chatId: data.setupChat._id, userId });
        setMessage("");
      } else {
        setActiveChat(null)
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { setupChat, loading };
  // return (message,userId)=>{


  // }

};

export const useSetActiveChat = () => {
  const dispatch = useDispatch();
  return (chat) => {
    dispatch(setActiveChatAction(chat))
  }

}
