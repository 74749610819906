import React, { useState } from "react";
import "react-telephone-input/css/default.css";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "antstrap";

const UpdatePassword = ({ modal, toggle }) => {
  return (
    <Modal centered isOpen={modal} toggle={toggle} className="modal-md custom-modal">
      <ModalHeader  toggle={toggle}>
        Change Password
      </ModalHeader>
      <ModalBody className="py-4">
        <Form>
          <Row form>
            <Col md="10">
              <FormGroup>
                <Label for="firstname">Current Password</Label>
                <Input type="password" name="oldpassword" id="oldpassword" />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md="10">
              <FormGroup>
                <Label for="firstname">New Password</Label>
                <Input type="password" name="newPassword" id="newPassword" />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md="10">
              <FormGroup>
                <Label for="firstname">Re-enter New Password</Label>
                <Input type="password" name="newpassword" id="newpassword" />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
      
        <Button color="primary" onClick={toggle}>
          Update Password
        </Button>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={toggle}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdatePassword;
