import { gql } from "apollo-boost";

export const GET_ORGANIZATION_DETAILS = gql`
  query getAuth($organizationId: String) {
    getAuth(organizationId: $organizationId) {
      name
      about
      logo
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        phoneNumber
        email
      }
      website
      organizationUrl
    }
  }
`;

const ADD_ORGANIZATION_DETAILS = `
  mutation addAuth(
    $organizationId: String
    $name: String
    $about: String
){
    addAuth(organizationId: $organizationId) {
        name: $name
        about: $about
    }
  }`;

export const GET_ORGANIZATION_DETAILS_BY_ID = gql`
  query getOrganizationById($organizationId: String) {
    getOrganizationById(organizationId: $organizationId) {
      name
      logo
      about
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        email
        phoneNumber
      }
      organizationUrl
      website
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation updateName($organizationId: String, $name: String) {
    updateName(organizationId: $organizationId, name: $name) {
      name
    }
  }
`;

export const UPDATE_ABOUT = gql`
  mutation updateAbout($organizationId: String, $about: String) {
    updateAbout(organizationId: $organizationId, about: $about) {
      about
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($organizationId: String, $address: AddressInput) {
    updateAddress(organizationId: $organizationId, address: $address) {
      address {
        addressLine
        city
        state
        postalCode
      }
    }
  }
`;

export const UPDATE_WEBSITE = gql`
  mutation updateWebsite($organizationId: String, $website: String) {
    updateWebsite(organizationId: $organizationId, website: $website) {
      website
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact($organizationId: String, $address: AddressInput) {
    updateAddress(organizationId: $organizationId, address: $address) {
      address {
        addressLine
        city
        state
        postalCode
      }
    }
  }
`;
export const GET_ROLES_BY_ORGANIZATION_ID = gql`
  query getRolesByOrganizationId($organizationId: String) {
    getRolesByOrganizationId(organizationId: $organizationId) {
      _id
      organizationId
      name
      activities
      isSuperAdmin
    }
  }
`;

export const UPDATE_ROLES = gql`
  mutation updateRoles(
    $organizationId: String
    $roleId: String
    $name: String
    $activities: [String]
  ) {
    updateRoles(
      organizationId: $organizationId
      roleId: $roleId
      name: $name
      activities: $activities
    ) {
      _id
      organizationId
      name
      activities
    }
  }
`;

export const DELETE_ROLES = gql`
  mutation deleteRoles($organizationId: String, $roleId: String) {
    deleteRoles(organizationId: $organizationId, roleId: $roleId) {
      _id
      organizationId
      name
      activities
    }
  }
`;

export const SAVE_ROLES = gql`
  mutation saveRoles(
    $organizationId: String
    $name: String
    $activities: [String]
  ) {
    saveRoles(
      organizationId: $organizationId
      name: $name
      activities: $activities
    ) {
      name
      activities
    }
  }
`;

export const GET_ACTIVITIES = gql`
  query getActivities {
    getActivities {
      _id
      name
      featureName
    }
  }
`;

export const SAVE_DEPARTMENT_DETAILS = gql`
  mutation saveDepartment(
    $organizationId: String
    $name: String
    $description: String
  ) {
    saveDepartment(
      organizationId: $organizationId
      name: $name
      description: $description
    ) {
      organizationId
      name
      description
    }
  }
`;
