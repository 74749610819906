import _ from "lodash";
import { initState } from "../state";
import {
	FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER,
	FETCH_USER_COMMENTS_BY_TICKET_ID,
	ADD_USER_COMMENT,
} from "../actions/types";

const ticketReducer = (state = [], action) => {
	let newState = [...state];
	switch (action.type) {
		case FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER:
			return [...action.payload];
		case FETCH_USER_COMMENTS_BY_TICKET_ID:
			newState.map((ticket) => {
				if (ticket._id == action.payload._id) {
					ticket.comments = [...action.payload.comments];
				}
			});
			return newState;
		case ADD_USER_COMMENT:
			console.log("dfd", action.payload);
			newState.map((ticket) => {
				if (ticket._id == action.payload._id) {
					ticket.comments.push(...action.payload.comments);
				}
			});
			return newState;

		default:
			return state;
	}
};

export default ticketReducer;
