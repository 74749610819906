import {
  GET_ROLES_BY_ORGANIZATION_ID,
  UPDATE_ROLES_SUCCESS,
  SAVE_ROLES_SUCCESS,
  DELETE_ROLES_SUCCESS,
  GET_ACTIVITIES_SUCCESS,
} from "../types";

export const getRolesByOrganizationId = (roles) => {
  return {
    type: GET_ROLES_BY_ORGANIZATION_ID,
    payload: roles,
  };
};

export const updateRolesSuccess = (roles) => {
  return {
    type: UPDATE_ROLES_SUCCESS,
    payload: roles,
  };
};

export const saveRolesSuccess = (roles) => {
  return {
    type: SAVE_ROLES_SUCCESS,
    payload: roles,
  };
};

export const deleteRolesSuccess = (roles) => {
  return {
    type: DELETE_ROLES_SUCCESS,
    payload: roles,
  };
};

export const getActivitiesSuccess = (activities) => {
  return {
    type: GET_ACTIVITIES_SUCCESS,
    payload: activities,
  };
};
