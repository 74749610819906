import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
	Button,
	ButtonDropdown,
	CardFooter,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	Label,
	Row,
} from "reactstrap";
import { useAddPoll } from "../../redux/actions/post";

const CreateNewPoll = () => {
	const [isDurationOpen, setDurationOpen] = useState(false);
	const [duration, setDuration] = useState(86400000);
	const toggle = () => {
		setDurationOpen(!isDurationOpen);
	};

	const postNoteRef = useRef(null);
	const [postDetails, setPostDetails] = useState("");
	const user = useSelector((state) => state.user);
	const { addPoll, loading } = useAddPoll();
	useEffect(() => {
		console.log(postNoteRef.current.clientHeight);
		postNoteRef.current.style.height = "60px";
		postNoteRef.current.style.height = `${postNoteRef.current.scrollHeight}px`;
	}, [postDetails]);

	const [options, setOptions] = useState([]);
	const [option, setOption] = useState("");
	const addOption = () => {
		let newOption = {
			option,
			media: "",
			votes: 0,
		};

		let newOptions = [...options];
		newOptions.push(newOption);
		setOptions(newOptions);
		setOption("");
	};

	const updateOption = (index, value) => {
		let newOptions = [...options];
		let option = newOptions[index];
		option.option = value;
		setOptions(newOptions);
	};

	const removeOption = (index) => {
		let newOptions = [...options];
		newOptions.splice(index, 1);
		setOptions(newOptions);
	};

	const createPoll = () => {
		if (postDetails != "" && options.length > 0) {
			let poll = {
				userId: user._id,
				title: "",
				description: postDetails,
				media: [],
				visibility: 1,
				suborgs: [],
				options,
				expiryDate: new Date(new Date().getTime() + Number(duration)),
			};
			addPoll({ variables: { poll } });

			setPostDetails("");
			setOptions([]);
			setOption("");
		}
	};
	return (
		<>
			<Row>
				<Col>
					<div className="d-flex py-2 post-input">
						<img
							className="image-rounded-md "
							src={require("assets/images/no-photo.svg")}
						/>
						<div className="d-flex input-area ">
							{/* <FormattedMessage id="assigned_to" /> */}
							<FormGroup>
								<Input
									type="textarea"
									innerRef={postNoteRef}
									name="text"
									id="exampleText"
									placeholder="Write question here... "
									value={postDetails}
									onChange={(e) => setPostDetails(e.target.value)}
								/>
							</FormGroup>
							{options.map((option, index) => {
								return (
									<FormGroup>
										<Input
											type="text"
											name="text"
											id="exampleText"
											placeholder="Add Option Text... "
											value={option.option}
											onChange={(e) => updateOption(index, e.target.value)}
										/>
										<i
											class="material-icons ml-3"
											onClick={() => removeOption(index)}
										>
											close
										</i>
									</FormGroup>
								);
							})}

							<FormGroup>
								<Input
									type="text"
									name="text"
									id="exampleText"
									placeholder="Add Option Text... "
									value={option}
									onChange={(e) => setOption(e.target.value)}
								/>
								<i class="material-icons ml-3" onClick={() => addOption()}>
									add
								</i>
							</FormGroup>
							<FormGroup className="d-flex flex-column">
								<Label for="exampleSelectMulti">Set Duration</Label>
								<Input
									type="select"
									name="selectMulti"
									id="exampleSelectMulti"
									style={{width:200}}
									value={duration}
									onChange={(e) => {
										setDuration(e.target.value)
									  }}
								>
									<option value={86400000}>1 Day</option>
									<option value={604800000}>1 Week</option>
								</Input>
							</FormGroup>
							{/* <FormGroup>
								<ButtonDropdown
									isOpen={isDurationOpen}
									toggle={toggle}
									className="btn-filter mr-2"
									value={duration}
								>
									<DropdownToggle caret>Set Duration</DropdownToggle>
									<DropdownMenu>
										<DropdownItem active={duration==86400000} value={86400000}  onClick={()=>setDuration(86400000)}>1 Day</DropdownItem>
										<DropdownItem active={duration==604800000} value={604800000}  onClick={()=>setDuration(604800000)}>1 Week</DropdownItem>
									</DropdownMenu>
								</ButtonDropdown>
							</FormGroup> */}
						</div>
					</div>
				</Col>
			</Row>
			<CardFooter className="bg-white d-flex justify-content-between border-0">
				<div className="d-flex post-actions  text-center ">
					<i class="material-icons mr-3">mood</i>
				</div>

				<div className="d-flex text-right">
					<i class="material-icons mr-3 ">visibility</i>
					<Button
						color="primary"
						onClick={() => {
							createPoll();
						}}
					>
						Create
					</Button>{" "}
				</div>
			</CardFooter>
		</>
	);
};

export default CreateNewPoll;
