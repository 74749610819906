import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";

import { notify } from "../../components/common/notification";
import {
  GET_FETCH_TICKETS_BY_STATUS_AND_TYPE,
  FETCH_COMMENTS_BY_TICKET_ID,
  ADD_COMMENT,
  FETCH_TEAM_DISCUSSIONS_COMMENTS_BY_TICKET_ID,
  ADD_TEAM_DISCUSSION_COMMENT,
  FETCH_PRIVATE_NOTES_BY_TICKET_ID,
  ADD_PRIVATE_NOTES,
  FETCH_TAGS_BY_TICKET_ID,
  UPDATE_TAGS_BY_TICKET_ID,
  UPDATE_TICKET_DETAILS_MUTATION,
  UPDATE_TICKET_STATUS_MUTATION,
  UPDATE_TICKET_STATUS_WITH_COMMENT_MUTATION,
} from "../services/ticket-services";
import {
  getTicketsByStatusAndType,
  fetchCommentsByTicketIdSuccess,
  addCommentSuccess,
  fetchTeamDiscussionByTicketIdSuccess,
  addTeamDiscussionCommentSuccess,
  fetchPrivateNotesByTicketIdSuccess,
  addPrivateNoteSuccess,
  fetchTagsByTicketIdSuccess,
  updateTagsSuccess,
  updateTicketStatusSuccess,
  updateTicketStatusAndCommentSuccess,
  updateTicketDetailsSuccess,
} from "./redux-actions/helpdesk";

export const useFetchTicketByStatusAndType = () => {
  const dispatch = useDispatch();
  const [getTicketByStatusAndType, { loading }] = useLazyQuery(
    GET_FETCH_TICKETS_BY_STATUS_AND_TYPE,
    {
      onCompleted(data) {
        if (data && data.fetchTicketByStatusAndType) {
          dispatch(getTicketsByStatusAndType(data.fetchTicketByStatusAndType));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { getTicketByStatusAndType, loading };
};

export const useFetchCommentsByTicketId = () => {
  const dispatch = useDispatch();
  const [fetchCommentsByTicketId, { loading }] = useLazyQuery(
    FETCH_COMMENTS_BY_TICKET_ID,
    {
      onCompleted(data) {
        if (data && data.fetchCommentsForTicket) {
          dispatch(fetchCommentsByTicketIdSuccess(data.fetchCommentsForTicket));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchCommentsByTicketId, loading };
};

export const useAddComment = () => {
  const dispatch = useDispatch();
  const [addComment, { loading }] = useMutation(ADD_COMMENT, {
    onCompleted(data) {
      if (data && data.addComment) {
        dispatch(addCommentSuccess(data.addComment));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addComment, loading };
};

export const useFetchTeamDiscussionByTicketId = () => {
  const dispatch = useDispatch();
  const [fetchTeamDiscussionByTicketId, { loading }] = useLazyQuery(
    FETCH_TEAM_DISCUSSIONS_COMMENTS_BY_TICKET_ID,
    {
      onCompleted(data) {
        if (data && data.fetchTeamNotesForTicket) {
          dispatch(
            fetchTeamDiscussionByTicketIdSuccess(data.fetchTeamNotesForTicket)
          );
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchTeamDiscussionByTicketId, loading };
};

export const useAddTeamDiscussionComment = () => {
  const dispatch = useDispatch();
  const [addTeamDiscussionComment, { loading }] = useMutation(
    ADD_TEAM_DISCUSSION_COMMENT,
    {
      onCompleted(data) {
        if (data && data.addTeamNote) {
          dispatch(addTeamDiscussionCommentSuccess(data.addTeamNote));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { addTeamDiscussionComment, loading };
};

export const useFetchPrivateNotesByTicketId = () => {
  const dispatch = useDispatch();
  const [fetchPrivateNotesByTicketId, { loading }] = useLazyQuery(
    FETCH_PRIVATE_NOTES_BY_TICKET_ID,
    {
      onCompleted(data) {
        if (data && data.fetchPrivateNotesForTicket) {
          dispatch(
            fetchPrivateNotesByTicketIdSuccess(data.fetchPrivateNotesForTicket)
          );
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchPrivateNotesByTicketId, loading };
};

export const useAddPrivateNote = () => {
  const dispatch = useDispatch();
  const [addPrivateNote, { loading }] = useMutation(ADD_PRIVATE_NOTES, {
    onCompleted(data) {
      if (data && data.addPrivateNote) {
        dispatch(addPrivateNoteSuccess(data.addPrivateNote));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addPrivateNote, loading };
};

export const useFetchTagsByTicketId = () => {
  const dispatch = useDispatch();
  const [fetchTagsByTicketId, { loading }] = useLazyQuery(
    FETCH_TAGS_BY_TICKET_ID,
    {
      onCompleted(data) {
        if (data && data.fetchTagsForTicket) {
          dispatch(
            fetchTagsByTicketIdSuccess(data.fetchTagsForTicket)
          );
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchTagsByTicketId, loading };
};

export const useUpdateTags = () => {
  const dispatch = useDispatch();
  const [updateTags, { loading }] = useMutation(UPDATE_TAGS_BY_TICKET_ID, {
    onCompleted(data) {
      if (data && data.updateTagsForTicket) {
        dispatch(updateTagsSuccess(data.updateTagsForTicket));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateTags, loading };
};


export const useUpdateTicketDetails = (toggle) => {
  const dispatch = useDispatch();
  const [updateTicketDetails, { loading }] = useMutation(UPDATE_TICKET_DETAILS_MUTATION, {
    onCompleted(data) {
      if (data && data.updateTicketDetails) {
        dispatch(updateTicketDetailsSuccess(data.updateTicketDetails));
      }
      toggle()
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateTicketDetails, loading };
};

export const useUpdateTicketStatus = () => {
  const dispatch = useDispatch();
  const [updateTicketStatus, { loading }] = useMutation(UPDATE_TICKET_STATUS_MUTATION, {
    onCompleted(data) {
      if (data && data.updateTicketStatus) {
        dispatch(updateTicketStatusSuccess(data.updateTicketStatus));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateTicketStatus, loading };
};

export const useUpdateTicketStatusWithComment = () => {
  const dispatch = useDispatch();
  const [updateTicketStatusWithComment, { loading }] = useMutation(UPDATE_TICKET_STATUS_WITH_COMMENT_MUTATION, {
    onCompleted(data) {
      if (data && data.updateTicketStatusWithComment) {
        dispatch(updateTicketStatusAndCommentSuccess(data.updateTicketStatusWithComment));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateTicketStatusWithComment, loading };
};

