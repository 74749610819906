import React, { useEffect } from "react";
import { Card, CardBody, Button, Row, CardFooter } from "antstrap";
import { sectionButtonsDetails } from "./section-buttons-data";
import SectionButtonCard from "./section-button-card";
import { Col } from "reactstrap";
import { useFetchAdminSettings } from "../../../redux/actions/settings";
import { useSelector } from "react-redux";
import { useFetchOrganizationDetails } from "../../../redux/actions/organization";
import { FormattedMessage } from "react-intl";
import SectionButtonFullCard from "./section-button-card-full";

export const Dashboard = () => {
	const organization = useSelector((state) => state.organization);
	const { fetchOrganizationDetails, loading } = useFetchOrganizationDetails();
	useEffect(() => {
		window.scrollTo(0, 0);
		fetchOrganizationDetails();
	}, []);
	return (
		<div>
			<h2>{organization.name}</h2>
			<p>Manage your organization account and your subscriptions.</p>

			<Row className="pr-0 pl-0 ">
				{sectionButtonsDetails.map((sectionButton) => (
					<Col lg={6} md={6} sm={12}>
						<SectionButtonCard {...sectionButton} />
					</Col>
				))}
			</Row>

			<Row>
				<Col sm={12}>
					<SectionButtonFullCard
						icon={require("assets/images/ic_org_dashboard_support.svg")}
						title={
							<FormattedMessage
								id="admin.dashboard.cardTitle"
								defaultMessage="We are here to help you!"
							/>
            }
            description={
              <FormattedMessage
              id="admin.dashboard.cardMessage"
              defaultMessage="Ask a question, create a support ticket or report an
            issue. Our team will get back to you."
            />
            }
					/>
				</Col>
				{/* <div className="admin-card col-sm-12 no-gutters">
					<Card className="col my-3">
						<CardBody>
							<div className="media align-items-center">
								<img
									src={require("assets/images/ic_org_dashboard_support.svg")}
									className="media-img col-sm-2 px-0 text-center"
									alt="antridge"
								/>
								<div className="media-body d-block d-sm-flex row align-items-center">
									<div className="col-sm-9 ">
										<h6 className="mt-0"></h6>
										<p>
											<FormattedMessage
												id="admin.dashboard.cardMessage"
												defaultMessage="Ask a question, create a support ticket or report an
                      issue. Our team will get back to you"
											/>
										</p>
									</div>
									<div className="col-sm-3   text-left px-1">
										<Button color="primary" outline>
											Get support
										</Button>
									</div>
								</div>
							</div>
						</CardBody>
					</Card>
				</div> */}
			</Row>
		</div>
	);
};
