import { combineReducers } from "redux";
import activities from "./activities";
import notifications from "./notifications";
import organization from "./organization";
import posts from "./posts";
import internalPosts from './internal-posts'
import roles from "./roles";
import settings from "./settings";
import subOrganizations from "./suborganizations";
import subscriber from "./subscriber";
import ticket from "./ticket";
import user from "./user";
import users from "./users";
import chats from "./chats";
import chatMessages from "./chat-messages";
import activeChat from "./active_chat";
import helpdeskCategories from "./helpdesk-categories";
import notification from './notification';
import chatUsers from './chat-users';
import myRequests from './my-requests';
const reducers = combineReducers({
  activities,
   posts,
   internalPosts,
  organization,
  roles,
  settings,
  subOrganizations,
  users,
  subscriber,
  user,
  notification,
  notifications,
  ticket,
  chats,
  chatMessages,
  activeChat,
  helpdeskCategories,
  chatUsers,
  myRequests
});

export default reducers;
