import { gql } from "apollo-boost";

export const GET_FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER = gql`
	query fetchTicketByStatusAndTypeForUser($statuses: [Int],$userId:String, $type: Int) {
		fetchTicketByStatusAndTypeForUser(statuses: $statuses,userId:$userId, type: $type) {
			_id
			organizationId
			subOrganizationId
			userId
			title
			description
			attachments
			requesterId
			createdBy
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			type
			userType
			lastUpdatedBy
			ticketNumber
			createdAt
			updatedAt
			assignedToId
			assignedUser {
				_id
				firstName
				lastName
				email
				fullNumber
			}
			organizationUser {
				_id
				firstName
				lastName
				email
				fullNumber
			}
			subscriberUser {
				_id
				firstName
				lastName
				email
				fullNumber
			}
		}
	}
`;


export const FETCH_USER_COMMENTS_BY_TICKET_ID = gql`
	query fetchCommentsForUserTicket($_id: String) {
		fetchCommentsForUserTicket(ticketId: $_id) {
			_id
			comments {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				isUserComment
				attachments
			}
		}
	}
`;

export const ADD_USER_COMMENT = gql`
	mutation addUserComment(
		$_id: String
		$userId: String
		$comment: String
		$isUserComment: Boolean
	) {
		addUserComment(
			isUserComment: $isUserComment
			ticketId: $_id
			userId: $userId
			comment: $comment
		) {
			_id
			comments {
				_id
				comment
				user {
					_id
					firstName
					lastName
					email
				}
				commentBy
				commentedOn
				isUserComment
				attachments
			}
		}
	}
`;

export const CREATE_TICKET = gql`
	mutation createTicket(
		$organizationId: String
		$subOrganizationId: String
		$_id: String
		$userId: String
		$title: String
		$description: String
		$type: Int
		$userType: Int
		$attachments: [String]
	) {
		createTicket(
			organizationId: $organizationId
			_id: $_id
			userId: $userId
			title: $title
			description: $description
			type: $type
			userType: $userType
			attachments: $attachments
		) {
			_id
			organizationId
			subOrganizationId
			userId
			title
			description
			attachments
			requesterId
			createdBy
			dueDate
			status
			priority
			category
			categoryDetails {
				_id
				name
			}
			type
			userType
			lastUpdatedBy
		}
	}
`;
