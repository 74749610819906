import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "antstrap";


const ConfirmationModal = (props) => {
  return (
    <Modal centered isOpen={props.isOpen} toggle={props.toggle} className="ml-modal custom-modal">
      <ModalHeader toggle={props.toggle}>
        Delete
      </ModalHeader>

      <ModalBody className="py-1">
        Are you sure you want to delete the user?
      </ModalBody>

      <ModalFooter >
        <Button color="primary" onClick={props.onConfirm}>
          Delete
        </Button>{" "}
        <Button color="link" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal