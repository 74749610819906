import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFetchSubOrganizations } from "../../../redux/actions/sub-organization";
import AddSubOrganizationModal from "./add-suborganization";
import ConfigureExistingSubOrganizationModal from "./configure-suborganization/configure";
import DashboardHeader from "./dashboard-header";
import SubOrganizationList from "./suborganization-list";

const SubOrganizationDashboard = () => {
  const subOrganizations = useSelector((state) => state.subOrganizations);
  const settings = useSelector((state) => state.settings);
  const subOrganizationLabel = settings.subOrganization.label;
  const childSubOrganizationLabel = settings.subOrganization.childLabel;
  const isChildSubOrganization = settings.subOrganization.isChildSubOrganization;
  const { fetchSubOrganizations, loading } = useFetchSubOrganizations();
  const [isOpenModal, setOpenModal] = useState(false);
  const toggle = () => setOpenModal(!isOpenModal);

  const [isOpenConfigureModal, setOpenConfigureModal] = useState(false);
  const toggleConfigureModal = () =>
    setOpenConfigureModal(!isOpenConfigureModal);

  useEffect(() => {
    fetchSubOrganizations();
  }, []);

  return (
    <>
      <DashboardHeader
        subOrganizationLabel={subOrganizationLabel}
        toggle={toggle}
        toggleConfigureModal={toggleConfigureModal}
      />
      <SubOrganizationList
        subOrganizations={subOrganizations}
        subOrganizationLabel={subOrganizationLabel}
        childSubOrganizationLabel={childSubOrganizationLabel}
        isChildSubOrganization={isChildSubOrganization}
      />

      <AddSubOrganizationModal
        subOrganizationLabel={subOrganizationLabel}
        isOpen={isOpenModal}
        toggle={toggle}
      />
      <ConfigureExistingSubOrganizationModal
        isOpen={isOpenConfigureModal}
        toggle={toggleConfigureModal}
        settings={settings}
      />
    </>
  );
};

export default SubOrganizationDashboard;
