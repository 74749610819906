import React from "react";
import { Card, Col, Row } from "reactstrap";
import { useUpdatePostSettings } from "../../../redux/actions/settings";
import SettingsSection from "./settings-section";

const PostSettings = (props) => {
  const post = props.post;
  const { updatePostSettings, loading } = useUpdatePostSettings();

  const updatePostSettingsToDb = (key, value) => {
    post[key] = value;
    delete post["__typename"];

    updatePostSettings({
      variables: { post },
    });
  };
  return (
    <>
      <Row>
        <Col xs="12" className="pt-4 pb-2">
          <h5>Posts Settings</h5>
          <p>
            Configure the default controls for posts. These controls can be
            overridden while creating a post.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Card>
            <SettingsSection
              title={"Allow Like and Dislike"}
              subTitle={"Subscribers can like or dislike a post."}
              value={props.post.isAllowLikeAndDislike}
              id={"isAllowLikeAndDislike"}
              onChange={updatePostSettingsToDb}
            />
            <SettingsSection
              title={"Allow Comments"}
              subTitle={"Subscribers can comment on post."}
              className="border-top"
              value={props.post.isAllowComments}
              id={"isAllowComments"}
              onChange={updatePostSettingsToDb}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PostSettings;
