import React from "react";
import EmptyStateSection from "../../common/empty-state-section";

const Subscription = () => {
	return (
		<div className={"admin-main-panel"}>
			<EmptyStateSection/>
		</div>
	);
};

export default Subscription;
