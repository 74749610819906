export const transformActivities = (activities) => {
  let tempActivities = {};

  Array.isArray(activities) &&
    activities.map((activity) => {
      if (tempActivities[activity.featureName]) {
        tempActivities[activity.featureName].push(activity);
      } else {
        tempActivities[activity.featureName] = [activity];
      }
    });
  return tempActivities;
};
