import { useMutation } from "react-apollo";
import { useDispatch } from "react-redux";
import { notify } from "../../components/common/notification";
import {
  UPDATE_POST_SETTINGS,
  UPDATE_PRIVACY_SETTINGS,
  UPDATE_SUBORGANIZATION_SETTINGS,
  UPDATE_SUBSCRIBER_SETTINGS,
} from "../services/settings-service";
import { setAdminSettings } from "./redux-actions/settings";

export const useUpdatePostSettings = () => {
  const dispatch = useDispatch();
  const [updatePostSettings, { loading }] = useMutation(UPDATE_POST_SETTINGS, {
    onCompleted(data) {
      if (data && data.updatePostSettings) {
        dispatch(setAdminSettings(data.updatePostSettings));
        notify("Post Settings updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
  });

  return { updatePostSettings, loading };
};

export const useUpdateSubscriberSettings = () => {
  const dispatch = useDispatch();
  const [updateSubscriberSettings, { loading }] = useMutation(
    UPDATE_SUBSCRIBER_SETTINGS,
    {
      onCompleted(data) {
        if (data && data.updateSubscriberSettings) {
          dispatch(setAdminSettings(data.updateSubscriberSettings));
          notify("Subscriber Settings updated successfully", "success");
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
    }
  );

  return { updateSubscriberSettings, loading };
};

export const useUpdatePrivacySettings = () => {
  const dispatch = useDispatch();
  const [updatePrivacySettings, { loading }] = useMutation(
    UPDATE_PRIVACY_SETTINGS,
    {
      onCompleted(data) {
        if (data && data.updatePrivacySettings) {
          dispatch(setAdminSettings(data.updatePrivacySettings));
          notify("Privacy Settings updated successfully", "success");
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
    }
  );

  return { updatePrivacySettings, loading };
};

export const useUpdateSubOrganizationSettings = (toggle) => {
  const dispatch = useDispatch();
  const [updateSubOrganizationSettings, { loading }] = useMutation(
    UPDATE_SUBORGANIZATION_SETTINGS,
    {
      onCompleted(data) {
        if (data && data.updateSubOrganizationSettings) {
          dispatch(setAdminSettings(data.updateSubOrganizationSettings));
          notify("Suborganization enabled successfully", "success");
          toggle();
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
    }
  );

  return { updateSubOrganizationSettings, loading };
};
