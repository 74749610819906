import io from "socket.io-client";
import { checkIfSessionActive } from "../../services/auth-service";

let socket;

// const WSS_URL = `wss://dev-api.antridge.com?accessToken=Bearer`;
const WSS_URL = `${process.env.REACT_APP_WS_URL}?accessToken=Bearer`;

//const WSS_URL = `${process.env.REACT_WS_URL}?accessToken=Bearer`;
export const SocketEvents = {
	CONNECTION: "connection",
	SOCKET_ERROR: "socketError",
	SOCKET_CONNECTED: "socketConnected",
	DISCONNECT: "disconnect",

	SEND_MESSAGE: "sendMessage",
	SEND_MESSAGE_ERROR: "sendMessageError",
	SEND_DELIVERY_REPORT: "sendDeliveryReport",
	SEND_DELIVERY_RECEIPT:"sendDeliveryReceipt",
	SEND_READ_RECEIPT: "sendReadReceipt",

	SEND_SENT_RECEIPT: "sendSentReceipt",
	
	SEND_READ_ALL_RECEIPT: "sendReadAllReceipt",

	RECEIVE_MESSAGE: "receiveMessage",
	SEND_FIRST_MESSAGE:"sendFirstMessage",
	USER_ONLINE: "userOnline",
	READ_CHAT: "readChat",
	READ_MESSAGE: "readMessage",
	RECEIVE_DELIVERY_RECEIPT: "receiveDeliveryReceipt",
};

export const getSocket = () => {
    console.log("socket called",socket)
	if (!socket) {
		const user = checkIfSessionActive();

		console.log("before socket");
		socket = io(user && user.token ? `${WSS_URL} ${user.token}` : WSS_URL, {
            transports: ["websocket"],
            upgrade: false
		});

		socket.on(SocketEvents.SOCKET_ERROR, function () {
			console.log("socketError to server");
        });
        
        socket.on(SocketEvents.DISCONNECT, function (data) {
			console.log("socketError to server",data);
		});

		socket.on(SocketEvents.SOCKET_CONNECTED, function () {
			console.log("sfdsfsdfsdfdsfdsfsdf to server");
		});
	}
	return socket;
};
