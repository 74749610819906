import React, { useState } from "react";
import EditUrl from "./edit-url";
import ViewUrl from "./view-url";
import SectionCard from "../section-card";

const UrlCard = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setIsEdit(!isEdit);
  return (
    <SectionCard toggle={toggle} title={"Organization Url"} isEdit={isEdit}>
      {isEdit ? <EditUrl {...props} toggle={toggle} /> : <ViewUrl {...props} />}
    </SectionCard>
  );
};

export default UrlCard;
