import React, { useRef, useState } from "react";
import { useSendMessageHandler } from "../../redux/actions/socket";
import { Input, Button } from 'antstrap'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
const ChatMessage = (props) => {
  const [ message, setMessage ] = useState("");
  const [isShow, setShow] = useState(false);
  const sendMessageHandler = useSendMessageHandler();
  const inputRef = useRef(null);
  const sendMessage = () => {
    if (props.chatId == null || !props.chatId) {
      if (message.trim() != "") {
        props.setupChat(message.trim());
      }
    } else {
      if (message.trim() != "") {
        sendMessageHandler({
          chatId: props.chatId,
          userId: props.currentUser._id,
          message: message.trim(),
          isDelivered:false,
          isSent:false,
          isRead:false
        },
          props.currentUser.firstName + " " + props.currentUser.lastName
        );
        setMessage("");
      }
    }
  };

  

  const handleEmojiClick = (emoji) => {
    let textareaElement = inputRef.current;
    const { selectionStart, selectionEnd } = textareaElement ;
    const newMessage =
      message.substring(0, selectionStart) +
      emoji.native +
      message.substring(selectionEnd);

    setMessage(newMessage);

    // Move the caret to the end of the inserted emoji
    textareaElement .focus();
    textareaElement .setSelectionRange(
      selectionStart + emoji.native.length,
      selectionStart + emoji.native.length
    );
  };
  return (
    <div className="py-2 py-lg-3 px-lg-2 px-3 border-top mb-0 bg-white row chat-input-wrapper">
      <div className="input-group message-input align-items-start">
        <div className="input-group-prepend" id="button-addon4">
          <div className="custom-file custom-icon-button chat-input-file">
            <input
              type="file"
              className="custom-file-input h-100"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
            />
            <label
              className="btn-outline-light btn btn-sm   chat-file-label border-0 px-1"
              htmlFor="inputGroupFile01"
            >
              <i className="material-icons message-icons font-large px-1">
                attach_file_outline
              </i>
            </label>
          </div>

          <Dropdown isOpen={isShow} toggle={() => setShow(!isShow)}>
            <DropdownToggle className="btn-transparent" style={{background:"transparent",border:"none",boxShadow:"none"}}caret>  <i className="material-icons message-icons font-large">
              tag_faces
            </i></DropdownToggle>
            <DropdownMenu>
              <DropdownItem toggle={true}>
                {isShow && <Picker data={data} onEmojiSelect={handleEmojiClick} />}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <Input
          type="textarea"
          className="chat-input"
          placeholder="Write something to send..."
          aria-label="Write something here"
          aria-describedby="button-addon4"
          value={message}
          innerRef={inputRef}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && !e.altKey) {
              e.preventDefault()
              sendMessage()
            } else if (e.keyCode === 13 && e.altKey) {
              setMessage(e.target.value + "\r\n");
            }
          }}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
        <div className="input-group-append">
          <button
            className="btn btn-primary chat-submit"
            type="button"
            onClick={() => sendMessage()}
          >
            <i className="material-icons text-white message-icons font-large">
              send
            </i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
