import { initState } from "../state";
import { SET_AUTH, SHOW_NOTIFICATION } from "../actions/types";

const notificationReducer = (state = false, action) => {
  if (action.type === SHOW_NOTIFICATION) {
    return action.notification;
  }
  return state;
};

export default notificationReducer;
