import React from "react";
import { Card, Row, Col, CardHeader, CardBody, CardTitle } from "antstrap";
import SectionHeader from "./section-header";

const SectionCard = (props) => {
  return (
    <Row className={"mt-3"}>
      <Col>
        <Card>
          <CardBody>
            <SectionHeader
              title={props.title}
              toggle={props.toggle}
              type={props.type}
              isEdit={props.isEdit}
            />
            {props.children}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SectionCard;
