import React from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState } from "react";

const MenuButton = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  return (
    <ButtonDropdown direction="left" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        size={"sm"}
        className="bg-white text-dark border-0 p-0 font-weight-bold dropdown-button"
      >
        {" "}
        <i className="material-icons">more_horiz</i>
      </DropdownToggle>
      <DropdownMenu>
        {props.menuButtons.map((menu, index) => (
          <DropdownItem
            onClick={() => {
              menu.onClick();
              toggle();
            }}
          >
            {menu.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default MenuButton;
