import React, { useState } from "react";
import { Row, Col, CardText, Button, Form, Input } from "antstrap";
import { FormattedMessage } from "react-intl";
import { useUpdateName } from "../../../../redux/actions/organization";
import { useSelector } from "react-redux";

const EditName = (props) => {
  const [name, setName] = useState(props.name);
  const updateName = useUpdateName(name);

  const onSave = () => {
    updateName.updateName();
    props.toggle();
  };
  return (
    <Form>
      <Row>
        <Col sm="12">
          <CardText>
            <Input
              type="text"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
          </CardText>
          <Button id="save" color="primary" onClick={() => onSave(name)}>
            <FormattedMessage id="common.save" defaultMessage="Save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditName;
