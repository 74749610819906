export function JoinString(val1, val2) {
  if (val1 && val2) {
    return `${val1.toString()} ${val2.toString()}`;
  } else if (!val1 && val2) {
    return `${val2.toString()}`;
  } else if (val1 && !val2) {
    return `${val1.toString()}`;
  } else {
    return "";
  }
}
