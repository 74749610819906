import React, { useState, useRef,useEffect } from "react";
import { useSelector } from "react-redux";
import {
	Row,
	Col,
	Input,
	FormGroup,
	Button,
	CardFooter,
	CardBody,
	Card,
	CardHeader,
} from "antstrap";
import { TICKET_STATUS } from "./ticket-constants";
import { USERTYPE } from "./constants";
import {
	useUpdateTicketStatusWithComment,
	useUpdateTicketStatus,
	useAddComment,
} from "../../redux/actions/helpdesk-tickets";

export const TicketMessage = (props) => {
	const postNoteRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const user = useSelector((state) => state.user);
	const ticket = props.ticket;
	const [ticketMessage, setTicketMessage] = useState("");

	const toggle = () => setIsOpen(!isOpen);
	const { addComment } = useAddComment();
	const { updateTicketStatus } = useUpdateTicketStatus();
	const { updateTicketStatusWithComment } = useUpdateTicketStatusWithComment();
	const handleReply = () => {
		addComment({
			variables: {
				isUserComment: false,
				comment: ticketMessage,
				_id: `${props.ticketId}`,
				userId: user._id,
			},
		});
		setTicketMessage("");
	};

	useEffect(() => {
		console.log(postNoteRef.current.clientHeight);
		postNoteRef.current.style.height = "60px";
		postNoteRef.current.style.height = `${postNoteRef.current.scrollHeight}px`;
	}, [ticketMessage]);

	const handleSolved = () => {
		if (ticketMessage != "") {
			updateTicketStatusWithComment({
				variables: {
					_id: `${props.ticketId}`,
					userId: user._id,
					status: TICKET_STATUS.SOLVED,
					comment: ticketMessage,
				},
			});
			setTicketMessage("");
		} else {
			updateTicketStatus({
				variables: {
					_id: `${props.ticketId}`,
					userId: user._id,
					status: TICKET_STATUS.SOLVED,
				},
			});
		}
	};

	const handleClosed = () => {
		updateTicketStatus({
			variables: {
				_id: `${props.ticketId}`,
				userId: user._id,
				status: TICKET_STATUS.CLOSED,
			},
		});
	};

	const handleHold = () => {
		updateTicketStatus({
			variables: {
				_id: `${props.ticketId}`,
				userId: user._id,
				status: TICKET_STATUS.ONHOLD,
			},
		});
	};

	const handleOpen = () => {
		updateTicketStatus({
			variables: {
				_id: `${props.ticketId}`,
				userId: user._id,
				status: TICKET_STATUS.OPEN,
			},
		});
	};
	return (
		<Card className="mt-3 mb-5">
			<CardHeader className="d-flex py-0">
				<i className="material-icons mr-2">reply</i>
				<p className="mb-0 mt-2">
					Replying to{" "}
					<b>
						{ticket
							? ticket.userType == USERTYPE.SUBCRIBERUSER
								? ticket.subscriberUser.firstName +
								  " " +
								  ticket.subscriberUser.lastName
								: ticket.organizationUser.firstName +
								  " " +
								  ticket.organizationUser.lastName
							: "Anonymous"}
					</b>
				</p>
			</CardHeader>

			<CardBody>
				<FormGroup row>
					<Col sm={12}>
						{/* <Input
              type="textarea"
              name="ticketmessage"
              id="ticketmessage"
              className="border-0"
              value={ticketMessage}
              onChange={(e) => setTicketMessage(e.target.value)}
              placeholder="Type your comment here"
            /> */}
            	<div className="d-flex input-area ">
						<FormGroup>
							<Input
								innerRef={postNoteRef}
								type="textarea"
								name="text"
								id="exampleText"
								placeholder="Type your comment here"
								value={ticketMessage}
								onChange={(e) => setTicketMessage(e.target.value)}
							/>
						</FormGroup>
            </div>
					</Col>
				</FormGroup>
				{/* <Row>
					<div className="badge badge-ant ml-3 mb-2">Lorem ipsm.pdf(10kb)</div>
				</Row> */}
				<Row>
					<div className="col-md-4 no-gutters">
						<Button color="primary" size="sm" onClick={handleReply}>
							Reply
						</Button>
						<Button color="link" size="sm" className="py-0">
							<i className="material-icons mr-2">attachment</i>
						</Button>
					</div>
					<div className="col-md-8 ">
						<Row className="justify-content-end">
							{ticket && ticket.status < 3 ? (
								<Button
									className="ml-auto mr-2"
									color="success"
							
									onClick={handleSolved}
								>
									Reply & Mark as Solved
								</Button>
							) : null}
							{ticket && ticket.status == 4 ? (
								<>
									<Button
										className=" mr-2"
										color="secondary"
										onClick={handleClosed}
									>
										Mark as Closed
									</Button>
									<Button
										className=" mr-2"
										color="warning"
										onClick={handleOpen}
									>
										Reply & Mark as Reopened
									</Button>
								</>
							) : null}
							{ticket && ticket.status == 3 ? (
								<Button
									className=" mr-2"
									color="warning"
									onClick={handleOpen}
								>
									Reply & Mark as Reopen
								</Button>
							) : null}
							{ticket && ticket.status == 2 ? (
								<Button color="warning" onClick={handleHold}>
									Mask as On Hold
								</Button>
							) : null}
						</Row>
					</div>
				</Row>
			</CardBody>
		</Card>
	);
};
