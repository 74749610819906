const api_base_url = `${process.env.REACT_APP_URL}/org/v1/auth`;

export const checkIfSessionActive = () => {
  const userString = localStorage.getItem("@antridge-organizaion/user");

  if (userString && userString !== null) {
    try{

      let user = JSON.parse(userString);
      return user;
    }catch(error){
      return false;
    }
    
  }
  return false;
};

//TODO @Dhiraj Make sure you will add device details later
export const signin = (auth, successHandler, errorHandler) => {
  fetch(`${api_base_url}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: auth.email,
      password: auth.password,
      device: {
        deviceType: 12,
        deviceToken: 12,
        deviceBrowser: 12,
        deviceModel: 12,
        osName: 12,
        appVersion: 22,
        userIp: 33,
      },
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        if (res.status === 401) {
          throw new Error("Invalid Username/Password. Please try again!");
        }
        if (res.status === 500) {
          throw new Error("Server Error");
        }
      }
    })
    .then((data) => {
      localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data));
      successHandler();
    })
    .catch((error) => {
      errorHandler(error.message);
      localStorage.setItem("@antridge-organizaion/user", null);
      console.log(error);
    });
};

export const logout = () => {
  //const userString = localStorage.getItem("@antridge-organizaion/user");

  localStorage.removeItem("@antridge-organizaion/user");
  return true;
};
