import { gql } from "apollo-boost";

export const UPDATE_POST_SETTINGS = gql`
  mutation updatePostSettings($post: PostInput) {
    updatePostSettings(post: $post) {
      organizationId
      post {
        isAllowLikeAndDislike
        isAllowComments
      }
      subscriber {
        isJoinOnApproval
      }
      subOrganization {
        label{
          _id
          name
          plural
        }
        childLabel{
          _id
          name
          plural
        }
        isSubOrganization
        isChildSubOrganization
      }
      privacy {
        isPrivate
      }
    }
  }
`;

export const UPDATE_SUBSCRIBER_SETTINGS = gql`
  mutation updateSubscriberSettings($subscriber: SubscriberInput) {
    updateSubscriberSettings(subscriber: $subscriber) {
      organizationId
      post {
        isAllowLikeAndDislike
        isAllowComments
      }
      subscriber {
        isJoinOnApproval
      }
      subOrganization {
        label{
          _id
          name
          plural
        }
        childLabel{
          _id
          name
          plural
        }
        isSubOrganization
        isChildSubOrganization
      }
      privacy {
        isPrivate
      }
    }
  }
`;

export const UPDATE_PRIVACY_SETTINGS = gql`
  mutation updatePrivacySettings($privacy: PrivacyInput) {
    updatePrivacySettings(privacy: $privacy) {
      organizationId
      post {
        isAllowLikeAndDislike
        isAllowComments
      }
      subscriber {
        isJoinOnApproval
      }
      subOrganization {
        label{
          _id
          name
          plural
        }
        childLabel{
          _id
          name
          plural
        }
        isSubOrganization
        isChildSubOrganization
      }
      privacy {
        isPrivate
      }
    }
  }
`;

export const UPDATE_SUBORGANIZATION_SETTINGS = gql`
  mutation updateSubOrganizationSettings(
    $subOrganization: SubOrganizationInput
  ) {
    updateSubOrganizationSettings(subOrganization: $subOrganization) {
      organizationId
      post {
        isAllowLikeAndDislike
        isAllowComments
      }
      subscriber {
        isJoinOnApproval
      }
      subOrganization {
        label{
          _id
          name
          plural
        }
        childLabel{
          _id
          name
          plural
        }
        isSubOrganization
        isChildSubOrganization
      }
      privacy {
        isPrivate
      }
    }
  }
`;
