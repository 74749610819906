import React, { useState } from "react";
import { Row, Col, CardText, Button, Form, Input, FormGroup } from "antstrap";
import { FormattedMessage } from "react-intl";
import { useUpdateAbout } from "../../../../redux/actions/organization";

const EditAbout = (props) => {
  const [about, setAbout] = useState(props.about);
  const updateAbout = useUpdateAbout(about);

  const onSave = () => {
    updateAbout.updateAbout();
    props.toggle();
  };
  return (
    <Form>
      <Row>
        <Col sm="12">
          <CardText>
            <FormGroup>
              <Input
                type="textarea"
                value={about}
                maxlength={250}
                onChange={(e) => setAbout(e.target.value)}
              />
            </FormGroup>
          </CardText>
          <Button color="primary" onClick={() => onSave(about)}>
            <FormattedMessage id="common.save" defaultMessage="Save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditAbout;
