import { Col, Row, ListGroup } from "reactstrap";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useFetchChatMessages, useFetchOldChatMessages, useSetupChat } from "../../redux/actions/chat";
import LoadingIndicator from "../common/loading-indicator";
import ChatBlock from "./chat-block";
import moment from 'moment';
import { useReadChatHandler } from "../../redux/actions/socket";
let lastMessageTime = null;
let lastMessageType = null;
const ChatConversation = (props) => {
  const chatMessagesObject = useSelector((state) => state.chatMessages);
  const user = useSelector((state) => state.user);
  const [isLoadOldMessage, setLoadOldMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(20);

  let dateHeaders = [];

  const addHeader = (dateHeader) => {
    let headers = [...dateHeaders];
    headers.push(dateHeader)
    dateHeaders = headers
  }

  const nextPage = () => {
    setPage(page + 1)
  }

  const checkIfDateHeaderExist = (timestamp, type) => {
    let dateHeader = moment(new Date(timestamp)).format("LL");
    let isSameLastTime = false;
    if (lastMessageType != null && lastMessageType == type && lastMessageTime != null && lastMessageTime == moment(new Date(timestamp)).format("LT").toString()) {
      isSameLastTime = true;
    } else {
      lastMessageTime = moment(new Date(timestamp)).format("LT").toString();
      lastMessageType = type;
      isSameLastTime = false;
    }

    // console.log(lastMessageTime)
    if (dateHeaders.indexOf(dateHeader) == -1) {
      addHeader(dateHeader)
      return { header: dateHeader, lastMessageTime, isSameLastTime }
    }
    return { header: false, lastMessageTime, isSameLastTime };
  }
  const { fetchChatMessages, loading } = useFetchChatMessages(props.chat?._id);
  const fetchOldChatMessagesAction = useFetchOldChatMessages(props.chat?._id);
  const readChatHandler = useReadChatHandler();

  useEffect(() => {
    let chat = chatMessagesObject[props.chat ? props.chat._id : null];
    if(chat){
      setPage(chat.page)
      let chatMessages = chat.messages
      if (chatMessages && chatMessages.length) {
        chatMessages.sort((a, b) => Number(a.createdAt) - Number(b.createdAt))
        setMessages(chatMessages);
        readChatHandler(props.chat._id, props.currentUser._id)
  
      } else {
        setMessages([]);
      }
    }else {
      console.log("fetcing new messages")
      setPage(1)
      setLoadOldMessage(false)
      loadMessages(false)
      setMessages([]);
    }

   
  }, [chatMessagesObject, props.chat]);

  const loadMessages = (isLoadOldMessage) => {
    setLoadOldMessage(isLoadOldMessage)
    if (isLoadOldMessage) {
      console.log("fetching old messages",page)
      if (props.chat && props.chat._id) {
        fetchOldChatMessagesAction.fetchOldChatMessages({
          variables: {
            userId: user._id,
            chatId: props.chat._id,
            page,
            perPage
          },
        });
      }
    } else {
      if (props.chat && props.chat._id) {
        fetchChatMessages({
          variables: {
            userId: user._id,
            chatId: props.chat._id,
            page,
            perPage
          },
        });
      }
    }

  }

  // useEffect(() => {
  //   // setPage(1)
  //   setLoadOldMessage(false)
  //   loadMessages(false)

  // }, [props.chat]);



  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  return (

    <Row className="chat-conversation" >
      <PerfectScrollbar>
        <div
          flush
          className={"text-center chat-messages-list w-100 "}

        >
          {!props.chat || props.chat == null ? (
            <div
              className={
                "text-center mt-1  m-auto p-3 tags badge badge-light rounded-lg"
              }
            >
              <span>Please send a message to begin a chat </span>
            </div>
          ) : <div
            className={
              "text-center mt-1  m-auto p-3 tags badge badge-light rounded-lg pointer-cursor"
            }

            onClick={() => loadMessages(true)}
          >
            <span>Load Older Messages </span>
          </div>}
          {messages.map((message, index) => (
            <React.Fragment key={message._id}>
              <ChatBlock
                message={message}

                align={message.userId == user._id ? "right" : "left"}
                header={checkIfDateHeaderExist(Number(message.createdAt), message.userId == user._id ? 1 : 2)}
                lastMessageTime={lastMessageTime}
              />
            </React.Fragment>
          ))}
          {!isLoadOldMessage ? <AlwaysScrollToBottom /> : null}



          {loading || fetchOldChatMessagesAction.loading || props.settingUpChatLoading ? (
            <div
              className={
                "text-center m-auto p-3 mt-1 tags badge-light  rounded-lg"
              }
            >
              <LoadingIndicator />
            </div>
          ) : null}
        </div>
      </PerfectScrollbar>
    </Row>
  );
};

export default ChatConversation;
