export const STATUS = {
  1: "New",
  2: "Open",
  3: "OnHold",
  4: "Solved",
  5: "Closed",
};

export const PRIORITY = {
  1: "Normal",
  2: "High",
  3: "Low",
};

export const TYPE = {
  HELPDESK: 1,
  COMPLAINTHELPDESK: 2,
};

export const USERTYPE = {
  SUBCRIBERUSER: 1,
  ORGANIZATIONUSER: 2,
};

export const generateTicketNUmberToDisplay = (ticketNumber) => {
  if (ticketNumber > 99999) {
    return "HELP" + ticketNumber;
  } else if (ticketNumber > 9999) {
    return "HELP0" + ticketNumber;
  } else if (ticketNumber > 999) {
    return "HELP00" + ticketNumber;
  } else if (ticketNumber > 99) {
    return "HELP000" + ticketNumber;
  } else if (ticketNumber > 9) {
    return "HELP0000" + ticketNumber;
  } else {
    return "HELP00000" + ticketNumber;
  }
};
