import _ from "lodash";
import { initState } from "../state";
import {
  FETCH_TICKETS_BY_STATUS_AND_TYPE,
  FETCH_COMMENTS_BY_TICKET_ID,
  ADD_COMMENT,
  FETCH_PRIVATE_NOTES_BY_TICKET_ID,
  ADD_PRIVATE_NOTE,
  ADD_TEAM_DISCUSSION_COMMENT,
  FETCH_TEAM_DISCUSSION_BY_TICKET_ID,
  FETCH_TAGS_BY_TICKET_ID,
  UPDATE_TAGS,
  UPDATE_TICKET_DETAILS,
  UPDATE_TICKET_STATUS_WITH_COMMENT,
  UPDATE_TICKET_STATUS,
} from "../actions/types";

const ticketReducer = (state = [], action) => {
  let newState = [...state];
  switch (action.type) {
    case FETCH_TICKETS_BY_STATUS_AND_TYPE:
      return [...action.payload];
    case FETCH_COMMENTS_BY_TICKET_ID:
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          ticket.comments = [...action.payload.comments];
        }
      });
      return newState;
    case ADD_COMMENT:
      console.log("dfd", action.payload);
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          ticket.comments.push(...action.payload.comments);
        }
      });
      return newState;
    case FETCH_TEAM_DISCUSSION_BY_TICKET_ID:
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          ticket.teamNotes = [...action.payload.teamNotes];
        }
      });
      return newState;
    case ADD_TEAM_DISCUSSION_COMMENT:
      console.log("dfd", action.payload);
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          if (ticket.teamNotes) {
            ticket.teamNotes.push(...action.payload.teamNotes);
          } else {
            ticket.teamNotes = [...action.payload.teamNotes];
          }
        }
      });
      return newState;
    case FETCH_PRIVATE_NOTES_BY_TICKET_ID:
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          ticket.privateNotes = [...action.payload.privateNotes];
        }
      });
      return newState;
    case ADD_PRIVATE_NOTE:
      console.log("dfd", action.payload);
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          if (ticket.privateNotes) {
            ticket.privateNotes.push(...action.payload.privateNotes);
          } else {
            ticket.privateNotes = [...action.payload.privateNotes];
          }
        }
      });
      return newState;
      case FETCH_TAGS_BY_TICKET_ID:
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          ticket.tags = [...action.payload.tags];
        }
      });
      return newState;
    case UPDATE_TAGS:
      console.log("dfd", action.payload);
      newState.map((ticket) => {
        if (ticket._id == action.payload._id) {
          ticket.tags = [...action.payload.tags];
        }
      });
      return newState;

      case UPDATE_TICKET_DETAILS:
        console.log("dfd", action.payload);

        newState.map((ticket,index) => {
          if (ticket._id == action.payload._id) {
            newState[index]= {...ticket,...action.payload};
          }
        });
        
        return newState;

        case UPDATE_TICKET_STATUS:
          console.log("dfd", action.payload);
          newState.map((ticket,index) => {
            if (ticket._id == action.payload._id) {
              newState[index]= {...ticket,...action.payload};
            }
          });
          return newState;

          case UPDATE_TICKET_STATUS_WITH_COMMENT:
          console.log("dfd", action.payload);
          newState.map((ticket,index) => {
            if (ticket._id == action.payload._id) {
              ticket.status = action.payload.status;
              ticket.comments.push(...action.payload.comments);
              newState[index]= {...ticket};
            }
          });
          return newState;
    default:
      return state;
  }
};

export default ticketReducer;
