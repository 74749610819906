import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label
} from "antstrap";
import classnames from "classnames";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAddRole } from "../../../redux/actions/roles";
import * as util from "./util";

const CreateRoleModal = (props) => {
  const userOrganizationId = useSelector((state) => state.user.organizationId);
  const [activeTab, setActiveTab] = useState("1");
  const [roleName, setRoleName] = useState("");
  const [selectedActivities, setSelectedActivities] = useState(new Set([]));
  const [parentActivitiesChecked, setParentActivitiesChecked] = useState({});
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const saveRoles = useAddRole();

  const onCreateModal = () => {
    saveRoles.addRole({
      variables: {
        name: roleName,
        activities: [...selectedActivities],
      },
    });
    props.toggle();
  };

  const activities = util.transformActivities(
    useSelector((state) => (state.activities ? state.activities : {}))
  );

  const handleParentActivityClicked = (value) => {
    if (!parentActivitiesChecked[value]) {
      activities[value].map((activity) => {
        selectedActivities.add(activity._id);
      });
      parentActivitiesChecked[value] = 2;
    } else {
      activities[value].map((activity) => {
        selectedActivities.delete(activity._id);
      });
      parentActivitiesChecked[value] = 0;
    }
    setSelectedActivities(new Set([...selectedActivities]));

    

    setParentActivitiesChecked({ ...parentActivitiesChecked });
  };

  const handleChildActivityClicked = (value,featureName) => {
    if (selectedActivities.has(value)) {
      selectedActivities.delete(value);
      
    } else {
      selectedActivities.add(value);
     
    }
    let allExist = 0;
    let total = activities[featureName].length;
    activities[featureName].map((activity) => {
      if(selectedActivities.has(activity._id)){
        allExist++;
      }
    });

    if(allExist==total){
      parentActivitiesChecked[featureName] = 2;

     
    }else if(allExist==0){
      parentActivitiesChecked[featureName] = 0;

     
    }else{
        parentActivitiesChecked[featureName] = 1
    }
    setParentActivitiesChecked({ ...parentActivitiesChecked });
    setSelectedActivities(new Set([...selectedActivities]));
  };
  return (
    <Modal centered isOpen={props.isOpen} toggle={props.toggle} className="ml-modal custom-modal">
      <ModalHeader toggle={props.toggle}>
        Create Role
      </ModalHeader>

      <ModalBody className="py-1">
        <Nav tabs className="tabs-antstrap">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTab("1");
              }}
            >
              General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTab("2");
              }}
            >
              Permissions
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className="pt-4">
              <Col sm="12">
              <FormGroup>
                <Label>Role Name</Label>

                <Input
                  type="text"
                  defaultValue={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                />
                </FormGroup>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Form className="pt-4">
              {activities &&
                Object.keys(activities).map((key) => {
                  return (
                    <div className="mb-4">
                      <FormGroup>
                        {/* this checkbox is just for representation purpose when unchecked it will uncheck all below/ if checked it will check all below*/}
                        <CustomInput
                          type="checkbox"
                          key={key}
                          id={key}
                          label={key}
                          value={key}
                          inline
                          checked={parentActivitiesChecked[key]==2}
                          
                          indeterminate ={parentActivitiesChecked[key]==1}
                          onClick={(e) =>
                            handleParentActivityClicked(e.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <div className="pl-3">
                          {activities[key].map((activity) => {
                            return (
                              <CustomInput
                                type="checkbox"
                                key={`${activity._id}`}
                                id={`${activity._id}`}
                                label={`${activity.name}`}
                                inline
                                value={activity._id}
                                checked={selectedActivities.has(activity._id)}
                                onClick={(e) =>
                                  handleChildActivityClicked(e.target.value,key)
                                }
                              />
                            );
                          })}
                        </div>
                      </FormGroup>
                    </div>
                  );
                })}
            </Form>
          </TabPane>
        </TabContent>
      </ModalBody>

      <ModalFooter >
        <Button
          color="primary"
          onClick={() => onCreateModal()}
          disabled={selectedActivities.size === 0 || roleName === ""}
        >
          Create Role
        </Button>{" "}
        <Button color="link" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateRoleModal;
