import React from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useState } from "react";

const MoreButton = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  return (
    <ButtonDropdown direction="left" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        size={"sm"}
        onMouseOver={()=>setOpen(true)}
        onMouseLeave={()=>setOpen(false)}
        className=" text-dark border-0 p-0 font-weight-bold dropdown-button more-button "
      >
        {" "}
        <i className="material-icons">more_horiz</i>
      </DropdownToggle>
      <DropdownMenu className="pb-3 " >
      <DropdownItem header className="pb-2" >
           {props.title}
          </DropdownItem>
        {props.menuButtons.map((menu, index) => (
          <DropdownItem
          >
           <div className="badge badge-ant ">{menu.name}</div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default MoreButton;
