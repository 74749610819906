import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "antstrap";

import classnames from "classnames";
import { useSelector } from "react-redux";
import PostSettings from "./post-settings";
import SubscriberSettings from "./subscriber-settings";
import PrivacySettings from "./privacy-settings";
import { useFetchOrganizationDetails } from "../../../redux/actions/organization";
export const Settings = (props) => {
  const { fetchOrganizationDetails, loading } = useFetchOrganizationDetails();

  const settings = useSelector((state) => state.settings);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (Object.keys(settings).length == 0) {
      fetchOrganizationDetails();
    }
  }, [settings]);
  useEffect(() => {
		window.scrollTo(0, 0);
	  }, []);
  return (
    <div>
      <h2>Organization Settings Page</h2>
      <p>You have full control to manage your organization settings.</p>

      <Nav tabs className="tabs-antstrap">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Privacy
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Subscribers
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggle("3");
            }}
          >
            Posts
          </NavLink>
        </NavItem>
      </Nav>
      {loading ? (
        <h2>LOading...</h2>
      ) : Object.keys(settings).length == 0 ? (
        <h2>Error while fetchig please try again...</h2>
      ) : (
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <PrivacySettings privacy={settings.privacy} />
          </TabPane>
          <TabPane tabId="2">
            <SubscriberSettings subscriber={settings.subscriber} />
          </TabPane>

          <TabPane tabId="3">
            <PostSettings post={settings.post} />
          </TabPane>
        </TabContent>
      )}
    </div>
  );
};
