import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container
} from "antstrap";


export const Organizational = () => {
  return (
    <Container fluid>
    <Row className="py-4">
      <h5 className="w-100">Organizational Settings</h5>
      <p>These settings help you keep your Organization</p>
    </Row>
    <Row>
      <Col className="border">
        <Row className="border-bottom p-3 align-items-center">
          <Col md="7">
            <Row>
              <h6 className="pb-0">Roles</h6>
            </Row>
            <Row>
              <p className="mb-0">
                Set a unique password to protect your account.
              </p>
            </Row>
          </Col>
          <Col md="5">
            <Row className="d-flex justify-content-end">
                <p className="mb-0 color-light pt-1">
                  Teacher, Helpdesk Moderator
                </p>
            </Row>
          </Col>
        </Row>
        <Row className="p-3 align-items-center justify-content-between">
        <Col md="7">
        <Row>
              <h6 className="pb-0">Departments</h6>
            </Row>
            <Row>
              <p className="mb-0">Your department is Sub Organization.
              </p>
            </Row>
          </Col>
          <Col md="5">
            <Row className="d-flex justify-content-end">
            <p className="mb-0 color-light pt-1">
                  Computer Science and Engineering
                </p>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
  );
};