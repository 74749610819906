import React from "react";
import { Row, Col, Card } from "antstrap";
import PostMenuButton from "../common/post-menu-button";
import { CardFooter } from "reactstrap";
import PostTags from "./post-tags";
import moment from "moment";
import { useDisLikePost, useLikePost, useRemoveDisLikePost, useRemoveLikePost } from "../../redux/actions/post";
import { useSelector } from "react-redux";
import { useState } from "react";
import URLPreview from "../common/url-preview";
const Post = (props) => {
	const post = props.post;
	const likePostMethod = useLikePost();
	const dislikePostMethod = useDisLikePost();
	const removeLikePostMethod = useRemoveLikePost();
	const removeDislikePostMethod = useRemoveDisLikePost();
	let timeout = null;

	const urlRegex = /(https?:\/\/[^\s]+)/g;
	const user = useSelector((state) => state.user);
	let imgClass;
	let totalImages = post.media.length;
	if (totalImages === 1) {
		imgClass = "col-12";
	} else if (totalImages === 2) {
		imgClass = "col-6";
	} else imgClass = "col-4";
	const [url, setUrl] = useState(null)
	const postImage = post.media.map((img, index) => {
		if (index < 3) {
			return (
				<div className={`${imgClass} post-image`}>
					<img src={img} />
					{index == 2 && totalImages > 3 ? (
						<div className="overlay-extra">
							<span>+{totalImages - 3}</span>
						</div>
					) : null}
				</div>
			);
		}
	});

	const likePost = () => {
		// myVar = setTimeout(function(){ alert("Hello"); }, 3000);
		if (!post.isLiked) {
			likePostMethod.likePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		} else {
			removeLikePostMethod.removeLikePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		}
	};

	const dislikePost = () => {
		// myVar = setTimeout(function(){ alert("Hello"); }, 3000);
		if (!post.isDisliked) {
			dislikePostMethod.dislikePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		} else {
			removeDislikePostMethod.removeDislikePost({
				variables: {
					postId: post._id,
					userId: user._id,
				},
			});
		}
	};

	const renderURLPreview = (post) => {
		let postWords = post.split(" ");
		let url = null;
		postWords.map((word, index) => {
			if (urlRegex.test(word)) {
				if (url == null) {
					url = word
				}
			}
		})

		return (url ? <URLPreview url={url} /> : null)


	}
	return (
		<Row>
			<Col sm="12">
				<Card className="post">
					<div className="post-header">
						<div className="post-user">
							<img
								src={require("assets/images/no-photo.svg")}
								className="post-user-image"
							/>
							<div className="post-user-name">
								<h6>
									{post.user
										? post.user.firstName + " " + post.user.lastName
										: "NA"}
								</h6>
								<p>{moment(new Date(Number(post.createdAt))).format("lll")}</p>
								{post.visibility == 2 ? (
									<PostTags subOrganizations={["a", "b", "c", "d"]} />
								) : null}
							</div>
						</div>
						<div className="menu-button">
							<PostMenuButton menuButtons={[{
								name: "Report",
								onClick: () => {
									//     setCurrentCategory(category);
									//   toggle();
								},
							},]} />
						</div>
					</div>

					{post.description != "" ?
						<div className="post-caption">
							<p>{post.description}</p>
							{renderURLPreview(post.description)}
						</div> : null}
					<Row className="post-img no-gutters">{postImage}</Row>
					<div className="full-border"></div>
					<CardFooter className="post-actions-wrapper bg-white d-flex justify-content-around">
						<div
							className="d-flex post-actions  text-center cursor-pointer"
							onClick={likePost}
						>
							{post.isLiked ? (
								<i class="material-icons active">thumb_up</i>
							) : (
								<i class="material-icons ">thumb_up</i>
							)}
							<span>{post.likes} </span>
						</div>
						<div
							className="d-flex post-actions  text-center cursor-pointer"
							onClick={dislikePost}
						>
							{post.isDisliked ? (
								<i class="material-icons active">thumb_down</i>
							) : (
								<i class="material-icons ">thumb_down</i>
							)}
							<span>{post.dislikes} </span>
						</div>
						<div className="d-flex post-actions  text-center">
							<i class="material-icons ">comment</i>
							<span>{post.views} </span>
						</div>
					</CardFooter>
				</Card>
			</Col>
		</Row>
	);
};

export default Post;
