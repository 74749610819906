import React from "react";
import { Row, Col, Button } from "antstrap";

const Buttons = () => {
  return (
    <div>
      <Row>
        <Col md={12}>
          <h6>Solid Buttons</h6>
        </Col>
        <Col md={12}>
          <Button color="primary">primary</Button>{" "}
          <Button color="secondary">secondary</Button>{" "}
          <Button color="success">success</Button>{" "}
          <Button color="info">info</Button>{" "}
          <Button color="warning">warning</Button>{" "}
          <Button color="danger">danger</Button>{" "}
          <Button color="link">link</Button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h6>Outline Buttons</h6>
        </Col>
        <Col md={12}>
          <Button outline color="primary">
            primary
          </Button>{" "}
          <Button outline color="secondary">
            secondary
          </Button>{" "}
          <Button outline color="success">
            success
          </Button>{" "}
          <Button outline color="info">
            info
          </Button>{" "}
          <Button outline color="warning">
            warning
          </Button>{" "}
          <Button outline color="danger">
            danger
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h6>Large Buttons</h6>
        </Col>
        <Col md={12}>
          <Button size="lg" color="primary">
            primary
          </Button>{" "}
          <Button size="lg" color="secondary">
            secondary
          </Button>{" "}
          <Button size="lg" color="success">
            success
          </Button>{" "}
          <Button size="lg" color="info">
            info
          </Button>{" "}
          <Button size="lg" color="warning">
            warning
          </Button>{" "}
          <Button size="lg" color="danger">
            danger
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h6>Small Buttons</h6>
        </Col>
        <Col md={12}>
          <Button size="sm" color="primary">
            primary
          </Button>{" "}
          <Button size="sm" color="secondary">
            secondary
          </Button>{" "}
          <Button size="sm" color="success">
            success
          </Button>{" "}
          <Button size="sm" color="info">
            info
          </Button>{" "}
          <Button size="sm" color="warning">
            warning
          </Button>{" "}
          <Button size="sm" color="danger">
            danger
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h6>Block level Buttons</h6>
        </Col>
        <Col md={12}>
          <Button size="sm" color="primary" block>
            primary
          </Button>{" "}
          <Button size="sm" color="secondary" block>
            secondary
          </Button>{" "}
          <Button size="sm" color="success" block>
            success
          </Button>{" "}
          <Button size="sm" color="info" block>
            info
          </Button>{" "}
          <Button size="sm" color="warning" block>
            warning
          </Button>{" "}
          <Button size="sm" color="danger" block>
            danger
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Buttons;
