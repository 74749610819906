import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDeleteSubOrganization } from "../../../redux/actions/sub-organization";

const DeleteSubOrganization = (props) => {
  const { deleteSubOrganization } = useDeleteSubOrganization(props.toggle);
  const deleteSubOrg = () => {
    deleteSubOrganization({
      variables: {
        subOrganizationId: props.subOrganizationId,
      },
    });
  };

  return (
    <Modal centered isOpen={props.modal} toggle={props.toggle} className={"custom-modal"}>
      <ModalHeader toggle={props.toggle}>
        <FormattedMessage
          id="subOrganization.delete.title"
          defaultMessage={`Delete ${props.subOrganizationLabel}`}
        />
      </ModalHeader>
      <ModalBody>
      Are you sure you want to delete {`"${props.subOrganizationId?props.name:""}" ${props.subOrganizationLabel}`}?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={deleteSubOrg}>
          Delete
        </Button>{" "}
        <Button color="link" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteSubOrganization;
