import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Button,
  CardFooter,
  CardBody,
  Card,
  CardHeader,
} from "antstrap";
import { USERTYPE } from "./constants";

export const TagsInput = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const ticket = props.ticket;
  const [tag, setTag] = useState("");
  const toggle = () => setIsOpen(!isOpen);

  const handleReply = () => {
    if(ticket&&ticket.tags.indexOf(tag)!=-1){
      
      setTag("");
    }else{
      props.updateTagsList(tag)
   setTag("");
    }
   
  };

  return (
    <Card className="mt-3">
      <CardHeader className="d-flex py-0">
        
      </CardHeader>

      <CardBody>
        <FormGroup row>
          <Col sm={12} md={4}>
            <Input
              type="text"
              name="ticketmessage"
              id="ticketmessage"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              placeholder="Type your tag here"
            />
          </Col>
        </FormGroup>
        
        <Row>
          <div className="col-md-4 no-gutters">
            <Button color="primary" onClick={handleReply}>
             Add
            </Button>
         
          </div>
          <div className="col-md-8">
           
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};
