import React from "react";
import { Card, CardBody, CardFooter, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

const SectionButtonCard = (props) => {
  const history = useHistory();
  const onClick = (route) => {
    history.push(route);
  };
  return (
    <Card className="admin-card my-3">
      <CardBody>
        <div className="media">
          <img
            src={props.icon}
            className="media-img mr-4"
            alt=""
          />
          <div className="media-body">
            <h6 className="mt-0">{props.title}</h6>
            <p>{props.description}</p>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <Button color="link" size="sm" onClick={() => onClick(props.link)}>
          {props.buttonText}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default SectionButtonCard;
