import {
	Button,
	Col,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	
	Label,
	FormGroup
} from "antstrap";
import React, { useState } from "react";
import { useAddHelpdeskCategory } from "../../../redux/actions/helpdesk-categories";
const CreateCategory = (props) => {
    const [categoryName, setCategoryName] = useState("");
    const createCategoryMethod = useAddHelpdeskCategory();
	const onCreateCategory = () => {
		if (categoryName.trim() != "") {
           
			createCategoryMethod.addHelpdeskCategory({
			    variables: {
			      name: categoryName
			    },
			  });
			props.toggle();
		}
	};
	return (
		<Modal
			centered
			isOpen={props.isOpen}
			toggle={props.toggle}
			className="ml-modal custom-modal"
		>
			<ModalHeader toggle={props.toggle}>
				Create Category
			</ModalHeader>

			<ModalBody className=" py-1">
				<Row className="pt-4">
					<Col sm="12">
					<FormGroup>
						<Label>Category Name</Label>

						<Input
							type="text"
							defaultValue={categoryName}
							onChange={(e) => setCategoryName(e.target.value)}
						/>
						</FormGroup>
					</Col>
				</Row>
			</ModalBody>

			<ModalFooter >
				<Button
					color="primary"
					onClick={() => onCreateCategory()}
					disabled={ categoryName === ""}
				>
					Create
				</Button>{" "}
				<Button color="link" onClick={props.toggle}>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default CreateCategory;
