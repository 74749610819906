import React, { useState } from "react";
import EditWebsite from "./edit-website";
import ViewWebsite from "./view-website";
import SectionCard from "../section-card";

const WebsiteCard = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setIsEdit(!isEdit);
  return (
    <SectionCard toggle={toggle} title={"Website"} isEdit={isEdit}>
      {isEdit ? (
        <EditWebsite {...props} toggle={toggle} />
      ) : (
        <ViewWebsite {...props} />
      )}
    </SectionCard>
  );
};

export default WebsiteCard;
