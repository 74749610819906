import { Dashboard } from "../../components/admin-dashboard/dashboard/dashboard";
import { Chat } from "../../components/chat/chat";
import { Grievance } from "../../components/helpdesk/grievance";
import { Helpdesk } from "../../components/helpdesk/helpdesk";
import { MyComplaints } from "../../components/helpdesk/my-complaints";
import { MyRequests } from "../../components/helpdesk/my-requests";
import { InternalPosts } from "../../components/internal-posts/posts";
import { Posts } from "../../components/posts/posts";
import { SubscribersDashboard } from "../../components/subscribers/subscribers-dashboard";
import { Users } from "../../components/user-list/users";
import { UserProfile } from "../../components/user-profile/user-profile";


export const tabs = [
  {
    path: "/posts",
    name: "Posts",
    icon: "material-icons",
    iconName:"note",
    component: Posts,
    layout: "/basic",
  },
  //  {
  //   path: "/user-posts",
  //   name: "User Posts",
  //   icon: "material-icons",
  //   iconName:"note",
  //   component: InternalPosts,
  //   layout: "/basic",
  // },
  {
  path: "/chat/",
  name: "Chat",
  icon: "material-icons",
  iconName: "message",
  component: Chat,
  layout: "/chat",
},
{
  path: "/profile",
  name: "Profile",
  icon: "material-icons",
  iconName: "person",
  component: UserProfile,
  layout: "/basic",
},
]
let routes = [

  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "material-icons",
  //   component: Posts,
  //   layout: "/basic",
  //   iconName:"dashboard"
  // },
  {
    path: "/posts",
    name: "Posts",
    icon: "material-icons",
    iconName:"note",
    component: Posts,
    layout: "/basic",
    isHiddenInMobile:true
  },
  // {
  //   headerText: "Organization",
  //   path: "/user-posts",
  //   name: "User Posts",
  //   icon: "material-icons",
  //   iconName:"note",
  //   component: InternalPosts,
  //   layout: "/basic",
  // },
  {
   
    path: "/chat/",
    name: "Chat",
    icon: "material-icons",
    iconName: "message",
    component: Chat,
    layout: "/chat",
    isHiddenInMobile:true
  },
  
  

  // {
  //   headerText: "Subscribers",
  //   path: "/subscribers",
  //   name: "Subscribers",
  //   icon: "material-icons",
  //   iconName:"supervised_user_circle",
  //   component: SubscribersDashboard,
  //   layout: "/basic",
  // },
  // {
  //   headerText: "Helpdesk Moderation",
  //   path: "/helpdesk",
  //   name: "Helpdesk",
  //   icon: "material-icons",
  //   iconName:"help_center",
  //   component: Helpdesk,
  //   layout: "/basic",
  // },
  // {
  //   path: "/grievance",
  //   name: "Grievance",
  //   icon: "material-icons",
  //   iconName:"error",
  //   component: Grievance,
  //   layout: "/basic",
  // },

  // {
  //   headerText: "My space",
  //   path: "/my-requests",
  //   name: "My Requests",
  //   icon: "material-icons",
  //   iconName:"help_center",
  //   component: MyRequests,
  //   layout: "/basic",
  // },
  // {
  //   path: "/my-complaints",
  //   name: "My Complaints",
  //   icon: "material-icons",
  //   iconName:"error",
  //   component: MyComplaints,
  //   layout: "/basic",
  // },
  {
    headerText: "Admin Settings",
    path: "/dashboard",
    name: "Manage Organization",
    icon: "material-icons",
    component: Dashboard,
    layout: "/admin",
    iconName:"settings"
  },
  {
    path: "/users/",
    name: "Manage Users",
    icon: "material-icons",
    iconName: "people",
    component: Users,
    layout: "/basic",
  },

  // {
  //   path: "/signin",
  //   name: "SignIn",
  //   icon: "fa fa-th-large",
  //   component: SignIn,
  //   layout: "/signin",
  // },
  // {
  //   path: "/signup",
  //   name: "SignUp",
  //   icon: "fa fa-th-large",
  //   component: SignUp,
  //   layout: "/signup",
  // },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "fa fa-th-large",
  //   component: UserProfile,
  //   layout: "/basic",
  // },
  // {
  //   path: "/posts",
  //   name: "Posts",
  //   icon: "fa fa-th-large",
  //   component: Subscribers,
  //   layout: "/basic",
  // },
  // {
  //   path: "/posts",
  //   name: "Posts",
  //   icon: "fa fa-th-large",
  //   component: Subscribers,
  //   layout: "/basic",
  // },
  // {
  //   headerText: "Documentation",
  //   collapse: true,
  //   name: "Documentation",
  //   icon: "fa fa-book",
  //   views: [
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       icon: "fa fa-th-large",
  //       component: Typography,
  //       layout: "/basic",
  //     },

  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       icon: "fa fa-th-large",
  //       component: Buttons,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/alerts",
  //       name: "Alerts",
  //       icon: "fa fa-th-large",
  //       component: Alerts,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/breadcrumbs",
  //       name: "Breadcrumbs",
  //       icon: "fa fa-th-large",
  //       component: Breadcrumbs,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/forms",
  //       name: "Forms",
  //       icon: "fa fa-th-large",
  //       component: Forms,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/modals",
  //       name: "Modals",
  //       icon: "fa fa-th-large",
  //       component: Modals,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/tabs",
  //       name: "Tabs",
  //       icon: "fa fa-th-large",
  //       component: Tabs,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/tooltips",
  //       name: "ToolTips",
  //       icon: "fa fa-th-large",
  //       component: ToolTips,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/cards",
  //       name: "Cards",
  //       icon: "fa fa-th-large",
  //       component: Cards,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/badges",
  //       name: "Badges",
  //       icon: "fa fa-th-large",
  //       component: Badges,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/graphql",
  //       name: "GraphQL",
  //       icon: "fa fa-th-large",
  //       component: GraphqlView,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/redux",
  //       name: "Redux",
  //       icon: "fa fa-th-large",
  //       component: ReduxReference,
  //       layout: "/basic",
  //     },
  //     {
  //       path: "/badges",
  //       name: "Badges",
  //       icon: "fa fa-th-large",
  //       component: Badges,
  //       layout: "/basic",
  //     },
  //   ],
  // },
];

export default routes;
