import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";

import { notify } from "../../components/common/notification";
import {GET_LATEST_POSTS_QUERY,ADD_POST_MUTATION,ADD_POLL_MUTATION, LIKE_POST_MUTATION, DISLIKE_POST_MUTATION, SUBMIT_POLL_MUTATION, REMOVE_LIKE_POST_MUTATION, REMOVE_DISLIKE_POST_MUTATION} from "../services/post-services"
import { addPollAction, addPostAction, getLatestPostsAction, updatePost } from "./redux-actions/post";
export const useFetchLatestPosts = () => {
  const dispatch = useDispatch();
  const [fetchLatestPosts, { loading }] = useLazyQuery(
    GET_LATEST_POSTS_QUERY,
    {
      onCompleted(data) {
        if (data && data.getLatestPosts) {
          dispatch(getLatestPostsAction(data.getLatestPosts));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchLatestPosts, loading };
};

export const useAddPost = (handleSuccess,handleError) => {
  const dispatch = useDispatch();
  const [addPost, { loading }] = useMutation(ADD_POST_MUTATION, {
    onCompleted(data) {
      if (data && data.addPost) {
        if(handleSuccess){
          handleSuccess()
        }
        dispatch(addPostAction(data.addPost));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addPost, loading };
};

export const useAddPoll = () => {
  const dispatch = useDispatch();
  const [addPoll, { loading }] = useMutation(ADD_POLL_MUTATION, {
    onCompleted(data) {
      if (data && data.addPoll) {
        dispatch(addPollAction(data.addPoll));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addPoll, loading };
};

export const useLikePost = () => {
  const dispatch = useDispatch();
  const [likePost, { loading }] = useMutation(LIKE_POST_MUTATION, {
    onCompleted(data) {
      if (data && data.likePost) {
        dispatch(updatePost(data.likePost));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { likePost, loading };
};

export const useDisLikePost = () => {
  const dispatch = useDispatch();
  const [dislikePost, { loading }] = useMutation(DISLIKE_POST_MUTATION, {
    onCompleted(data) {
      if (data && data.dislikePost) {
        dispatch(updatePost(data.dislikePost));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { dislikePost, loading };
};

export const useRemoveLikePost = () => {
  const dispatch = useDispatch();
  const [removeLikePost, { loading }] = useMutation(REMOVE_LIKE_POST_MUTATION, {
    onCompleted(data) {
      if (data && data.removeLikePost) {
        dispatch(updatePost(data.removeLikePost));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { removeLikePost, loading };
};

export const useRemoveDisLikePost = () => {
  const dispatch = useDispatch();
  const [removeDislikePost, { loading }] = useMutation(REMOVE_DISLIKE_POST_MUTATION, {
    onCompleted(data) {
      if (data && data.removeDislikePost) {
        dispatch(updatePost(data.removeDislikePost));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { removeDislikePost, loading };
};

export const useSubmitPoll = () => {
  const dispatch = useDispatch();
  const [submitPoll, { loading }] = useMutation(SUBMIT_POLL_MUTATION, {
    onCompleted(data) {
      if (data && data.submitPoll) {
        dispatch(updatePost(data.submitPoll));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { submitPoll, loading };
};