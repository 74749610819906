import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import ReactTelInput from "react-telephone-input";
import "react-telephone-input/css/default.css";
import Files from "react-files";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "antstrap";
import { useAddOrganizationUser } from "redux/actions/user";

const AddUsers = ({ modal, toggle }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [rolesList, setRole] = useState([]);
  const [subOrganizationList, setSubOrganization] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [files, setFiles] = useState([]);

  const roles = useSelector((state) => state.roles);
  useEffect(() => {
    roles && roles[0] && setRole(roles[0]._id);
  }, [roles]);

  const subOrganizations = useSelector((state) => state.subOrganizations);

  useEffect(() => {
    subOrganizations.length > 0 && setSubOrganization(subOrganizations[0]._id);
  }, [subOrganizations]);

  const { addOrganizationUser } = useAddOrganizationUser();

  const onFilesChange = (file) => {
    setFiles(file);
    let image = document.getElementById("output");
    image.src = URL.createObjectURL(file[0]);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleAdd = () => {
    const [countryCode, mobileNumber] = phoneNumber.split(" ");
    addOrganizationUser({
      variables: {
        userId: undefined,
        firstName: firstName,
        lastName: lastName,
        email: email,
        countryCode: countryCode,
        mobileNumber: mobileNumber,
        subOrganizations: subOrganizationList,
        roles: rolesList,
      },
    });

    toggle();
  };

  const handleInputChange = (phone) => {
    setPhoneNumber(phone);
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} className={"custom-modal"}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="add.user" />
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">
              <FormattedMessage id="first_name" />
            </Label>
            <Input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Enter First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">
              <FormattedMessage id="last_name" />
            </Label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Enter Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">
              <FormattedMessage id="email" />
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="usernumber">
              <FormattedMessage id="phone_number" />
            </Label>
            <ReactTelInput
              defaultCountry="in"
              flagsImagePath={require("assets/images/flags.png")}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="role">
              <FormattedMessage id="role" />
            </Label>
            <Input
              type="select"
              name="role"
              id="role"
              onChange={(e) => {
                if (rolesList.indexOf(e.target.value) == -1) {
                  setRole([...rolesList], e.target.value);
                }
              }}
            >
              {roles &&
                roles.map((role) => {
                  return <option value={role._id}>{role.name}</option>;
                })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="suborganization">
              <FormattedMessage id="sub_organization" />
            </Label>
            <Input
              type="select"
              name="role"
              id="role"
              onChange={(e) => {
                if (subOrganizationList.indexOf(e.target.value) == -1) {
                  setSubOrganization([...subOrganizationList], e.target.value);
                }
              }}
            >
              {subOrganizations &&
                subOrganizations.map((subOrganization) => {
                  return (
                    <option value={subOrganization._id}>
                      {subOrganization.name}
                    </option>
                  );
                })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="suborganization">Logo</Label>
            <Files
              className="files-dropzone"
              onChange={onFilesChange}
              onError={onFilesError}
              accepts={["image/jpeg", "image/jpg", "image/png"]}
              multiple
              maxFiles={1}
              maxFileSize={5242880}
              clickable
            >
              <img id="output" />
              <div className="m-0">
                <i className="fa fa-plus" />
                <small className="text-muted">Upload</small>
              </div>
            </Files>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => handleAdd()}>
          Add User
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUsers;
