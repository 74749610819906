export const DELETE_ENTRY = "DELETE_ENTRY";
export const SET_ORGANIZATION_DETAILS = "SET_ORGANIZATION_DETAILS";
export const SET_ORGANIZATION_DETAILS_BY_ID = "SET_ORGANIZATION_DETAILS_BY_ID";
export const UPDATE_NAME_SUCCESS = "UPDATE_NAME_SUCCESS";
export const UPDATE_ABOUT_SUCCESS = "UPDATE_ABOUT_SUCCESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_WEBSITE_SUCCESS = "UPDATE_WEBSITE_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const GET_ROLES_BY_ORGANIZATION_ID = "GET_ROLES_BY_ORGANIZATION_ID";
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const SAVE_ROLES_SUCCESS = "SAVE_ROLES_SUCCESS";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const FETCH_ADMIN_SETTINGS = "FETCH_ADMIN_SETTINGS";
export const FETCH_SUB_ORGANIZATIONS = "FETCH_SUB_ORGANIZATIONS";
export const ADD_SUB_ORGANIZATION = "ADD_SUB_ORGANIZATION";
export const CHANGE_ORGANIZATION_USER_STATUS_SUCCESS =
  "CHANGE_ORGANIZATION_USER_STATUS_SUCCESS";
export const ADD_ORGANIZATION_USER_SUCCESS = "ADD_ORGANIZATION_USER_SUCCESS";
export const UPDATE_ORGANIZATION_USER_SUCCESS =
  "UPDATE_ORGANIZATION_USER_SUCCESS";
export const FETCH_ORGANIZATION_USERS = "FETCH_ORGANIZATION_USERS";
export const EDIT_SUB_ORGANIZATION = "EDIT_SUB_ORGANIZATION";
export const DELETE_SUB_ORGANIZATION = "DELETE_SUB_ORGANIZATION";
export const ADD_CHILD_SUB_ORGANIZATION = "ADD_CHILD_SUB_ORGANIZATION";
export const GET_CHILD_SUB_ORGANIZATION = "GET_CHILD_SUB_ORGANIZATION";
export const DELETE_CHILD_SUB_ORGANIZATION = "DELETE_CHILD_SUB_ORGANIZATION";
export const EDIT_CHILD_SUB_ORGANIZATION = "EDIT_CHILD_SUB_ORGANIZATION";
export const CHECKED_SUBSCRIBERS = "CHECKED_SUBSCRIBERS";
export const SET_USER = "SET_USER";
export const FETCH_SUBSCRIBERS = "FETCH_SUBSCRIBERS";
export const UPDATE_SUBSCRIBER_STATUS = "UPDATE_SUBSCRIBER_STATUS";
export const NOTIFY = "NOTIFY";
export const UPDATE_SUBSCRIBER_ACTIVE_STATUS =
  "UPDATE_SUBSCRIBER_ACTIVE_STATUS";
export const FETCH_TICKETS_BY_STATUS_AND_TYPE =
  "FETCH_TICKETS_BY_STATUS_AND_TYPE";
export const FETCH_COMMENTS_BY_TICKET_ID = "FETCH_COMMENTS_BY_TICKET_ID";
export const ADD_COMMENT = "ADD_COMMENT";
export const FETCH_TEAM_DISCUSSION_BY_TICKET_ID =
  "FETCH_TEAM_DISCUSSION_BY_TICKET_ID";
export const ADD_TEAM_DISCUSSION_COMMENT = "ADD_TEAM_DISCUSSION_COMMENT";
export const FETCH_PRIVATE_NOTES_BY_TICKET_ID =
  "FETCH_PRIVATE_NOTES_BY_TICKET_ID";
export const ADD_PRIVATE_NOTE = "ADD_PRIVATE_NOTE";
export const UPDATE_TAGS = "UPDATE_TAGS";
export const FETCH_TAGS_BY_TICKET_ID = "FETCH_TAGS_BY_TICKET_ID";
export const ASSIGN_TICKET = "ASSIGN_TICKET";
export const UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS";
export const UPDATE_TICKET_STATUS_WITH_COMMENT = "UPDATE_TICKET_STATUS_WITH_COMMENT";
export const UPDATE_TICKET_DETAILS = "UPDATE_TICKET_DETAILS";
export const UPDATE_SUB_ORGANIZATION_OF_TICKET =
  "UPDATE_SUB_ORGANIZATION_OF_TICKET";
export const UPDATE_DUE_DATE_STATUS_AND_PRIORITY =
  "UPDATE_DUE_DATE_STATUS_AND_PRIORITY";

export const SET_AUTH = "SET_AUTH";

export const FETCH_LATEST_POSTS = "FETCH_LATEST_POSTS";
export const ADD_POST = "ADD_POST";
export const ADD_POLL = "ADD_POLL";

export const UPDATE_POST = "UPDATE_POST";

export const FETCH_LATEST_INTERNAL_POSTS = "FETCH_LATEST_INTERNAL_POSTS";
export const ADD_INTERNAL_POST = "ADD_INTERNAL_POST";
export const ADD_INTERNAL_POLL = "ADD_INTERNAL_POLL";

export const UPDATE_INTERNAL_POST = "UPDATE_INTERNAL_POST";



export const FETCH_CHATS = "FETCH_CHATS";
export const FETCH_CHAT_MESSAGES = "FETCH_CHAT_MESSAGES";
export const FETCH_OLD_CHAT_MESSAGES = "FETCH_OLD_CHAT_MESSAGES";
export const SETUP_CHAT = "SETUP_CHAT";
export const FETCH_CHAT_BY_USER="FETCH_CHAT_BY_USER";

export const RECEIVE_MESSAGE="RECEIVE_MESSAGE";
export const RECEIVE_MESSAGE_SENT_STATUS="RECEIVE_MESSAGE_SENT_STATUS";
export const RECEIVE_MESSAGE_READ_STATUS="RECEIVE_MESSAGE_READ_STATUS";
export const RECEIVE_MESSAGE_DELIVERED_STATUS="RECEIVE_MESSAGE_DELIVERED_STATUS";
export const SEND_READ_ALL_RECEIPT ="SEND_READ_ALL_RECEIPT";
export const SEND_DELIEVRED_ALL_RECEIPT ="SEND_DELIEVRED_ALL_RECEIPT"
export const READ_CHAT = "READ_CHAT";

export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT"


export const FETCH_HELPDESK_CATEGORIES = "FETCH_HELPDESK_CATEGORIES";

export const SET_USER_ONLINE = "SET_USER_ONLINE";
export const FETCH_CHAT_USERS = "FETCH_CHAT_USERS";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";


export const FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER =
  "FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER";
export const FETCH_USER_COMMENTS_BY_TICKET_ID = "FETCH_USER_COMMENTS_BY_TICKET_ID";
export const ADD_USER_COMMENT = "ADD_USER_COMMENT";