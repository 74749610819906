import { useLazyQuery, useMutation } from "react-apollo";
import { notify } from "../../components/common/notification";
import {
  ADD_ROLE,
  GET_ACTIVITIES,
  GET_ROLES,
  REMOVE_ROLE,
  UPDATE_ROLE,
} from "../services/roles-services";
import {
  deleteRolesSuccess,
  getActivitiesSuccess,
  getRolesByOrganizationId,
  saveRolesSuccess,
  updateRolesSuccess,
} from "./redux-actions/roles";
import { useDispatch } from "react-redux";

export const useFetchRoles = () => {
  const dispatch = useDispatch();
  const [fetchRoles, { loading }] = useLazyQuery(GET_ROLES, {
    onCompleted(data) {
      if (data && data.getRoles) {
        dispatch(getRolesByOrganizationId(data.getRoles));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { fetchRoles, loading };
};

export const useAddRole = () => {
  const dispatch = useDispatch();

  const [addRole, { loading }] = useMutation(ADD_ROLE, {
    onCompleted(data) {
      if (data && data.addRole) {
        dispatch(saveRolesSuccess(data.addRole));
        notify("Role has been saved successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addRole, loading };
};

export const useUpdateRole = () => {
  const dispatch = useDispatch();

  const [updateRole, { loading }] = useMutation(UPDATE_ROLE, {
    onCompleted(data) {
      if (data && data.updateRole) {
        dispatch(updateRolesSuccess(data.updateRole));
        notify("Role has been updated successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { updateRole, loading };
};

export const useRemoveRole = (roleId) => {
  const dispatch = useDispatch();
  const [removeRole, { loading }] = useMutation(REMOVE_ROLE, {
    variables: { roleId: roleId },
    onCompleted(data) {
      if (data && data.removeRole) {
        dispatch(deleteRolesSuccess(data.removeRole));
        notify("Role is deleted successfully", "success");
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { removeRole, loading };
};

export const useFetchActivities = () => {
  const dispatch = useDispatch();
  const [fetchActivities, { loading }] = useLazyQuery(GET_ACTIVITIES, {
    onCompleted(data) {
      if (data && data.getActivities) {
        dispatch(getActivitiesSuccess(data.getActivities));
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { fetchActivities, loading };
};
