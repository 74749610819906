import _ from "lodash";
import { initState } from "../state";
import {
  UPDATE_ROLES_SUCCESS,
  GET_ACTIVITIES_SUCCESS,
  SAVE_ROLES_SUCCESS,
  GET_ROLES_BY_ORGANIZATION_ID,
  DELETE_ROLES_SUCCESS,
} from "../actions/types";

const rolesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ROLES_BY_ORGANIZATION_ID:
      return [...action.payload];
    case UPDATE_ROLES_SUCCESS: {
      return _.unionBy([{...action.payload}], state, "_id");
    }
    case SAVE_ROLES_SUCCESS: {
      return _.unionBy([{...action.payload}], state, "_id");
    }
    case DELETE_ROLES_SUCCESS: {
      return _.differenceBy(state, [{...action.payload}], "_id");
    }
    default:
      return state;
  }
};

export default rolesReducer;
