import {
	ADD_INTERNAL_POLL,
	ADD_INTERNAL_POST,


	FETCH_LATEST_INTERNAL_POSTS,

	UPDATE_INTERNAL_POST
} from "../actions/types";

const postsReducer = (state = [], action) => {
	let newState = action.payload;
	if (action.type === FETCH_LATEST_INTERNAL_POSTS) {
		newState.sort((a, b) => {
			if (Number(a.createdAt) >= Number(b.createdAt)) {
				return -1;
			} else {
				return 1;
			}
		});
		return newState;
	} else if (action.type === ADD_INTERNAL_POST) {
		let newState = [...state];
		newState.push(action.payload);
		newState.sort((a, b) => {
			if (Number(a.createdAt) <= Number(b.createdAt)) {
				return 1;
			} else {
				return -1;
			}
		});
		return newState;
	} else if (action.type === ADD_INTERNAL_POLL) {
		let newState = [...state];
		newState.push(action.payload);
		newState.sort((a, b) => {
			if (Number(a.createdAt) >= Number(b.createdAt)) {
				return -1;
			} else {
				return 1;
			}
		});
		return newState;
	} else if (action.type === UPDATE_INTERNAL_POST) {
		let newState = [...state];
		return newState.map((post) => {
			if (post._id == action.payload._id) {
				return { ...post, ...action.payload };
            }
            return post
		});
	}

	return state;
};

export default postsReducer;
