
import React, { useEffect,useRef } from "react";
import { useState } from "react";
import { Col, Row,Button } from "reactstrap";
import Categories from "./categories";
import CreateCategory from "./create-category";

const HelpdeskSetup = (props) => {
    const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const scrollRef = useRef(null)
	// useEffect(() => {
	// 		scrollRef.current.scrollIntoView()
	//   }, []);
	return (
		<div ref={scrollRef}>
				<Row className="align-items-baseline mb-3" >
					<Col sm="9">
						<h2 >Helpdesk Setup</h2>
						<p>Manage helpdesk categories and rules</p>
					</Col>
					<Col sm="3" className="text-right ">
						<Button color="primary"  onClick={toggle}>
							Create Category
						</Button>
					</Col>
				</Row>
				{/* <RolesData
					{...props}
					roles={roles}
					activitiesList={transformActivities}
					onUpdate={handleUpdate}
				/> */}

                <Categories/>

				{modal ? <CreateCategory isOpen={modal} toggle={toggle} /> : void 0}
		</div>
	);
};

export default HelpdeskSetup;
