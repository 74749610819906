import { FETCH_CHATS, FETCH_CHAT_BY_USER, FETCH_CHAT_MESSAGES, READ_CHAT, RECEIVE_MESSAGE, SETUP_CHAT, SET_USER_ONLINE } from "../actions/types";

const chatsReducer = (state = [], action) => {
	let newState = action.payload;
	if (action.type === FETCH_CHATS) {
		newState.sort((a, b) => {
			if (
				!a.updatedAt &&
				!b.updatedAt &&
				Number(a.createdAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				b.updatedAt &&
				Number(a.updatedAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				!b.updatedAt &&
				Number(a.updatedAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				!a.updatedAt &&
				b.updatedAt &&
				Number(a.createdAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else {
				return 1;
			}
		});
		return newState;
	} else if (action.type === SETUP_CHAT) {
		let newState = [...state];
		let isExist = false;
		newState.map((chat) => {
			if (chat._id == action.payload._id) {
				chat = { ...action.payload }
				isExist = true
			}
		})

		if (!isExist) {
			newState.push(action.payload);
		}

		newState.sort((a, b) => {
			if (
				!a.updatedAt &&
				!b.updatedAt &&
				Number(a.createdAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				b.updatedAt &&
				Number(a.updatedAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				!b.updatedAt &&
				Number(a.updatedAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				!a.updatedAt &&
				b.updatedAt &&
				Number(a.createdAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else {
				return 1;
			}
		});
		return newState;
	} else if (action.type === FETCH_CHAT_BY_USER) {
		let newState = [...state];
		let isExist = false;
		newState.map((chat) => {
			if (chat._id == action.payload._id) {
				chat = { ...action.payload }
				isExist = true
			}
		})

		if (!isExist) {
			newState.push(action.payload);
		}

		newState.sort((a, b) => {
			if (
				!a.updatedAt &&
				!b.updatedAt &&
				Number(a.createdAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				b.updatedAt &&
				Number(a.updatedAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				!b.updatedAt &&
				Number(a.updatedAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				!a.updatedAt &&
				b.updatedAt &&
				Number(a.createdAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else {
				return 1;
			}
		});
		return newState;
	}

	else if (action.type === RECEIVE_MESSAGE) {
		let newState = [...state];
		newState.map((chat) => {
			if (chat._id == action.payload.chatId) {
				chat.recentMessage = action.payload.message;
				chat.updatedAt = action.payload.message.createdAt
				chat.chatReports = action.payload.chatReports
			}
		});
		newState.sort((a, b) => {
			if (
				!a.updatedAt &&
				!b.updatedAt &&
				Number(a.createdAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				b.updatedAt &&
				Number(a.updatedAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				!b.updatedAt &&
				Number(a.updatedAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				!a.updatedAt &&
				b.updatedAt &&
				Number(a.createdAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else {
				return 1;
			}
		});



		return newState;
	} else if (action.type === SET_USER_ONLINE) {
		let newState = [...state];
		newState.map((chat) => {
			if (chat.userIds.indexOf(action.payload.userId) > -1) {
				chat.users.map((user) => {
					if (user._id == action.payload.userId) {
						user.isOnline = action.payload.isOnline;
					}
				})

			}
		});
		newState.sort((a, b) => {
			if (
				!a.updatedAt &&
				!b.updatedAt &&
				Number(a.createdAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				b.updatedAt &&
				Number(a.updatedAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				!b.updatedAt &&
				Number(a.updatedAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				!a.updatedAt &&
				b.updatedAt &&
				Number(a.createdAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else {
				return 1;
			}
		});
		return newState;
	} else if (action.type === READ_CHAT) {
		let newState = [...state];
		newState.map((chat) => {
			if (chat._id == action.payload.chatId) {
				console.log(chat.chatReports)
				chat.chatReports&&chat.chatReports.map((chatReport) => {
					if (chatReport.userId == action.payload.userId) {
						chatReport.unreadCount = 0;
					}
				})

			}
		});
		newState.sort((a, b) => {
			if (
				!a.updatedAt &&
				!b.updatedAt &&
				Number(a.createdAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				b.updatedAt &&
				Number(a.updatedAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else if (
				a.updatedAt &&
				!b.updatedAt &&
				Number(a.updatedAt) >= Number(b.createdAt)
			) {
				return -1;
			} else if (
				!a.updatedAt &&
				b.updatedAt &&
				Number(a.createdAt) >= Number(b.updatedAt)
			) {
				return -1;
			} else {
				return 1;
			}
		});
		return newState
	}

	return state;
};

export default chatsReducer;
