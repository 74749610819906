import React from "react";
import { Row, Col, CardText } from "antstrap";

const ViewLogo = ({ content }) => {
  //TODO use api logo
  return (
    <Row>
      <Col xs="2" sm="1">
        <img
          className="header-logo"
          src={require("../../../../assets/images/organization_default_thumbnail.svg")}
          alt="organization-logo"
          style={{ objectFit: "scale-down", width: "100%" }}
        />
      </Col>
      <Col xs="10" sm="11">
        <CardText>{content}</CardText>
      </Col>
    </Row>
  );
};

export default ViewLogo;
