import { useLazyQuery, useMutation } from "react-apollo";
import { useDispatch } from "react-redux";
import { notify } from "../../components/common/notification";
import {
	ADD_ORGANIZATION_USER,
	CHANGE_ORGANIZATION_USER_STATUS,
	GET_ORGANIZATION_USERS,
	GET_OTHER_ORGANIZATION_USERS,
	LOGOUT_USER_FROM_CURRENT_SESSION,
	UPDATE_ORGANIZATION_USER,
} from "../services/user-services";
import { changeOrganizationUserStatusSuccess } from "./action";
import {
	fetchOrganizationUsersAction,
	updateOrganizationUserSuccess,
	addOrganizationUserSuccess,
	fetchChatUsersAction,
} from "./redux-actions/users";
import { SET_AUTH, SET_USER } from "./types";
import {logout} from "../../services/auth-service"

export const useFetchOrganizationUsers = () => {
	const dispatch = useDispatch();
	const [fetchOrganizationUsers, { loading }] = useLazyQuery(
		GET_ORGANIZATION_USERS,
		{
			onCompleted(data) {
				if (data && data.getUsers) {
					dispatch(fetchOrganizationUsersAction(data.getUsers));
				}
			},
			onError(error) {
				if (error) {
					notify(error.message, "danger");
				}
			},
			fetchPolicy: "no-cache",
		}
	);
	return { fetchOrganizationUsers, loading };
};

export const useFetchOtherOrganizationUsers = () => {
	const dispatch = useDispatch();
	const [fetchOrganizationUsers, { loading }] = useLazyQuery(
		GET_OTHER_ORGANIZATION_USERS,
		{
			onCompleted(data) {
				if (data && data.getOtherUsers) {
					dispatch(fetchChatUsersAction(data.getOtherUsers));
				}
			},
			onError(error) {
				if (error) {
					notify(error.message, "danger");
				}
			},
			fetchPolicy: "no-cache",
		}
	);
	return { fetchOrganizationUsers, loading };
};

export const useAddOrganizationUser = (history) => {
	const dispatch = useDispatch();
	const [addOrganizationUser, { loading }] = useMutation(
		ADD_ORGANIZATION_USER,
		{
			onCompleted(data) {
				if (data && data.addUser) {
					dispatch(addOrganizationUserSuccess(data.addUser));
					notify("Added user successfully", "success");
					history.goBack();
				}
			},
			onError(error) {
				if (error) {
					notify(error.message, "danger");
				}
			},
			fetchPolicy: "no-cache",
		}
	);
	return { addOrganizationUser, loading };
};

export const useUpdateOrganizationUser = (history) => {
	const dispatch = useDispatch();
	const [updateOrganizationUser, { loading }] = useMutation(
		UPDATE_ORGANIZATION_USER,
		{
			onCompleted(data) {
				if (data && data.updateUserProfile) {
					dispatch(updateOrganizationUserSuccess(data.updateUserProfile));
					notify("User updated successfully", "success");
					history.goBack();
				}
			},
			onError(error) {
				if (error) {
					notify(error.message, "danger");
				}
			},
			fetchPolicy: "no-cache",
		}
	);
	return { updateOrganizationUser, loading };
};

export const useChangeOrganizationUserStatus = () => {
	const dispatch = useDispatch();
	const [changeOrganizationUserStatus, { loading }] = useMutation(
		CHANGE_ORGANIZATION_USER_STATUS,
		{
			onCompleted(data) {
				if (data && data.changeOrganizationUserStatus) {
					dispatch(
						changeOrganizationUserStatusSuccess(
							data.changeOrganizationUserStatus
						)
					);
					notify("User deleted successfully", "success");
				}
			},
			onError(error) {
				if (error) {
					notify(error.message, "danger");
				}
			},
			fetchPolicy: "no-cache",
		}
	);
	return { changeOrganizationUserStatus, loading };
};

export const setUser = (user, dispatch) =>
	dispatch({
		type: SET_USER,
		user,
	});

export const setAuth = (auth, dispatch) =>
	dispatch({
		type: SET_AUTH,
		auth,
	});

export const useLogoutUserFromCurrentSession = () => {
	const dispatch = useDispatch();
	const [logoutUser, { loading }] = useMutation(
		LOGOUT_USER_FROM_CURRENT_SESSION,
		{
			onCompleted(data) {
				if (data && data.logoutFromCurrentSession) {
					if (data.logoutFromCurrentSession.isExpired) {
            logout();
						notify("User Logged out successfully", "success");
					}
				}
			},
			onError(error) {
				if (error) {
					notify(error.message, "danger");
				}
			},
			fetchPolicy: "no-cache",
		}
	);
	return { logoutUser, loading };
};
