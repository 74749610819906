import { FETCH_CHATS, FETCH_CHAT_MESSAGES, FETCH_OLD_CHAT_MESSAGES, RECEIVE_MESSAGE, RECEIVE_MESSAGE_DELIVERED_STATUS, RECEIVE_MESSAGE_READ_STATUS, RECEIVE_MESSAGE_SENT_STATUS, SEND_DELIEVRED_ALL_RECEIPT, SEND_READ_ALL_RECEIPT, SETUP_CHAT } from "../actions/types";

const chatsMessagesReducer = (state = {}, action) => {
	let chat = action.payload;
	if (action.type === SETUP_CHAT) {
		let newState = { ...state };
		newState[action.payload._id] = { page: 1, messages: [], lastSyncedTime: 0 };

		return newState;
	} else if (action.type === FETCH_CHAT_MESSAGES) {
		let newState = { ...state };
		if (chat) {

			if (!newState[chat.chatId]) {
				newState[chat.chatId] = { page: 1, messages: [], lastSyncedTime: 0 }
			}

			if (newState.[chat.chatId].lastSyncedTime == 0) {

				newState[chat.chatId].messages = chat.messages
				newState[chat.chatId].lastSyncedTime = Number(chat.messages[0].createdAt)
			} else {
				let messages = [...newState[chat.chatId].messages]
				chat.messages.forEach((message) => {
					if (Number(message.createdAt) > newState[chat.chatId].lastSyncedTime) {
						messages.push(message);
						newState[chat.chatId].lastSyncedTime = Number(message.createdAt)
					}

				})
				newState[chat.chatId].messages = messages
			}
			newState[chat.chatId].page = newState[chat.chatId].page + 1
			console.log(newState[chat.chatId])
		}

		return newState;
	} else if (action.type === FETCH_OLD_CHAT_MESSAGES) {
		let newState = { ...state };
		let messages = [];
		messages.push(...chat.messages);
		let chatMessages = newState[chat.chatId] ? newState[chat.chatId].messages : [];
		messages.push(...chatMessages)
		newState[chat.chatId].messages = [...messages]
		newState[chat.chatId].page = newState[chat.chatId].page + 1
		// newState.lastSyncedTime[action.payload.chatId] = Number(action.payload.message.createdAt)
		console.log(newState[chat.chatId])
		return newState;
	} else if (action.type === RECEIVE_MESSAGE) {
		let newState = { ...state };
		let chatMessages = newState[chat.chatId] ? newState[chat.chatId].messages : [];
		chatMessages.push(chat.message)
		newState[chat.chatId].messages = [...chatMessages]
		newState[chat.chatId].lastSyncedTime = Number(chat.message.createdAt)

		return newState;
	} else if (action.type === RECEIVE_MESSAGE_SENT_STATUS) {
		let newState = { ...state };
		let newChat = newState[chat.chatId];
		if (newChat) {

			let messages = [];
			newChat.messages.forEach(message => {

				if (Number(message.createdAt) == Number(chat.message.createdAt) && message.message == chat.message.message) {
					// message = chat.message
					messages.push(chat.message)
				} else {

					messages.push(message)
				}
			});

			newChat.messages = [...messages]

			newState[chat.chatId] = { ...newChat }
		}

		return newState;
	} else if (action.type === RECEIVE_MESSAGE_READ_STATUS) {
		let newState = { ...state };
		let newChat = newState[chat.chatId];
		if (newChat) {

			let messages = [];
			newChat.messages.forEach(message => {
				if (message._id === chat.messageId) {
					// message = chat.message
					message.isDelivered = true;
					message.isRead = true;

					messages.push(message)

				} else {

					messages.push(message)
				}
			});

			newChat.messages = [...messages]

			newState[chat.chatId] = { ...newChat }
		}

		return newState;
	} else if (action.type === RECEIVE_MESSAGE_DELIVERED_STATUS) {
		let newState = { ...state };
		let newChat = newState[chat.chatId];
		if (newChat) {

			let messages = [];
			newChat.messages.forEach(message => {

				if (message._id === chat.messageId) {
					// message = chat.message
					message.isDelivered = true;

					messages.push(message)

				} else {

					messages.push(message)
				}
			});

			newChat.messages = [...messages]

			newState[chat.chatId] = { ...newChat }
		}

		return newState;
	} else if (action.type === SEND_READ_ALL_RECEIPT) {
		console.log("here", action)
		let newState = { ...state };
		let newChat = newState[chat.chatId];
		if (newChat) {

			let messages = [];
			newChat.messages.forEach(message => {

				message.isRead = true;
				messages.push(message)
			});

			newChat.messages = [...messages]

			newState[chat.chatId] = { ...newChat }
		}

		return newState;
	} else if (action.type === SEND_DELIEVRED_ALL_RECEIPT) {
		console.log("here", action)
		let newState = { ...state };
		let newChat = newState[chat.chatId];
		if (newChat) {

			let messages = [];
			newChat.messages.forEach(message => {

				message.isDelivered = true;
				messages.push(message)
			});

			newChat.messages = [...messages]

			newState[chat.chatId] = { ...newChat }
		}

		return newState;
	}



	return state;
};

export default chatsMessagesReducer;
