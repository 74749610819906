import React, { useState, useEffect } from "react";
import {
	FormGroup,
	Input,
	Col,
	Row,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	InputGroup,
	InputGroupAddon,
	Button,
} from "antstrap";
import { useSelector } from "react-redux";
import { useFetchSubOrganizations } from "../../redux/actions/sub-organization";

import DropdownSearchBox from "./dropdown-search";

import PerfectScrollbar from "react-perfect-scrollbar";
const SubOrganizationsDropdown = (props) => {
	const [dropdownDept, setDropdownDept] = useState(false);
	const [isFetched, setIsFetched] = useState(false);
	const toggleDept = () => setDropdownDept((prevState) => !prevState);
	const { fetchSubOrganizations, loading } = useFetchSubOrganizations();
	const [searchString, setSearchString] = useState("");
	const subOrganizations = useSelector((state) => state.subOrganizations);
	const settings = useSelector((state) => state.settings);
	const subOrganizationLabel = settings.subOrganization
		? settings.subOrganization.label.plural
		: "SubOrganizations";

	useEffect(() => {
		// console.log("i am here forfetch sub too dropdown")
		// if (subOrganizations.length == 0 || !isFetched) {
		// 	setIsFetched(true)
		// 	fetchSubOrganizations();
		// }
	}, [subOrganizations]);
	return (
		<Dropdown
			isOpen={dropdownDept}
			toggle={toggleDept}
			className="custom-dropdown mr-3"
		>
			<DropdownToggle color="light" className="border" caret>
				{props.isAdd ? (
					<Button type="button" color="primary" size="xs ">
						<i className="material-icons">add</i>
					</Button>
				) : (
					<p>
						{props.value != ""
							? props.value.name
							: `All ${subOrganizationLabel}`}
					</p>
				)}
			</DropdownToggle>
			<DropdownMenu className=" border-bottom py-3">
				<DropdownItem header className="py-0">{subOrganizationLabel}
					<Button className="ml-3" color="secondary" size="xs " onClick={() => fetchSubOrganizations()}>
						<i className="material-icons">refresh</i>
					</Button>
				</DropdownItem>
				<DropdownSearchBox
					searchString={searchString}
					setSearchString={setSearchString}
				/>
				<div className="border-bottom my-2"></div>
				<PerfectScrollbar>
					<div className="dropdown-menu-items">
						<DropdownItem onClick={() => props.onSelect(false)}>
							All{" "}
						</DropdownItem>
						{subOrganizations &&
							subOrganizations.map((subOrganization) => {
								if (searchString != "") {
									if (
										subOrganization.name
											.toLowerCase()
											.search(searchString.toLowerCase()) > -1
									) {
										return (
											<DropdownItem
												onClick={() => props.onSelect(subOrganization)}
												value={subOrganization}
											>
												{subOrganization.name}
											</DropdownItem>
										);
									} else {
										return null;
									}
								} else {
									return (
										<DropdownItem
											onClick={() => props.onSelect(subOrganization)}
											value={subOrganization}
										>
											{subOrganization.name}
										</DropdownItem>
									);
								}
							})}
					</div>
				</PerfectScrollbar>
			</DropdownMenu>
		</Dropdown>
	);
};

export default SubOrganizationsDropdown;
