import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	Input,
	Row,
} from "antstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAddUserComment } from "../../../redux/actions/my-space";
import {
	useUpdateTicketStatus,
	useUpdateTicketStatusWithComment,
} from "../../../redux/actions/helpdesk-tickets";
import { TICKET_STATUS } from "../ticket-constants";

export const TicketMessage = (props) => {
	const postNoteRef = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const user = useSelector((state) => state.user);
	const ticket = props.ticket;
	const [ticketMessage, setTicketMessage] = useState("");

	const toggle = () => setIsOpen(!isOpen);
	const { addUserComment } = useAddUserComment();
	const { updateTicketStatus } = useUpdateTicketStatus();
	const { updateTicketStatusWithComment } = useUpdateTicketStatusWithComment();
	const handleReply = () => {
		addUserComment({
			variables: {
				isUserComment: true,
				comment: ticketMessage,
				_id: `${props.ticketId}`,
				userId: user._id,
			},
		});
		setTicketMessage("");
	};

	useEffect(() => {
		console.log(postNoteRef.current.clientHeight);
		postNoteRef.current.style.height = "60px";
		postNoteRef.current.style.height = `${postNoteRef.current.scrollHeight}px`;
	}, [ticketMessage]);

	const handleSolved = () => {
		if (ticketMessage != "") {
			updateTicketStatusWithComment({
				variables: {
					_id: `${props.ticketId}`,
					userId: user._id,
					status: TICKET_STATUS.SOLVED,
					comment: ticketMessage,
				},
			});
			setTicketMessage("");
		} else {
			updateTicketStatus({
				variables: {
					_id: `${props.ticketId}`,
					userId: user._id,
					status: TICKET_STATUS.SOLVED,
				},
			});
		}
	};

	const handleClosed = () => {
		updateTicketStatus({
			variables: {
				_id: `${props.ticketId}`,
				userId: user._id,
				status: TICKET_STATUS.CLOSED,
			},
		});
	};

	const handleHold = () => {
		updateTicketStatus({
			variables: {
				_id: `${props.ticketId}`,
				userId: user._id,
				status: TICKET_STATUS.ONHOLD,
			},
		});
	};

	const handleOpen = () => {
		updateTicketStatus({
			variables: {
				_id: `${props.ticketId}`,
				userId: user._id,
				status: TICKET_STATUS.OPEN,
			},
		});
	};
	return (
		<Card className="mt-3 mb-5">
			<CardHeader className="d-flex py-0">
				<i className="material-icons mr-2">reply</i>
				<p className="mb-0 mt-2">
				{ticket && ticket.assignedToId && ticket.assignedToId != ""?
					"Replying to ":"Reply "}
					<b>
						{ticket && ticket.assignedToId && ticket.assignedToId != ""
							? " " +
							  ticket.assignedUser.firstName +
							  ticket.assignedUser.lastName
							: ""}
					</b>
				</p>
			</CardHeader>

			<CardBody>
				<FormGroup row>
					<Col sm={12}>
						<div className="d-flex input-area ">
							<FormGroup>
								<Input
									innerRef={postNoteRef}
									type="textarea"
									name="text"
									id="exampleText"
									placeholder="Type your comment here"
									value={ticketMessage}
									onChange={(e) => setTicketMessage(e.target.value)}
								/>
							</FormGroup>
						</div>
					</Col>
				</FormGroup>
				{/* <Row>
					<div className="badge badge-ant ml-3 mb-2">Lorem ipsm.pdf(10kb)</div>
				</Row> */}
				<Row>
					<div className="col-md-4 no-gutters">
						<Button color="primary" size="sm" onClick={handleReply}>
							Reply
						</Button>
						<Button color="link" size="sm" className="py-0">
							<i className="material-icons mr-2">attachment</i>
						</Button>
					</div>
					<div className="col-md-8 ">
						<Row className="justify-content-end">
							{ticket && ticket.status == 4 ? (
								<>
									<Button
										className=" mr-2"
										color="secondary"
										onClick={handleClosed}
									>
										Mark as Closed
									</Button>
									{/* <Button
										className=" mr-2"
										color="warning"
										onClick={handleOpen}
									>
										Reply & Mark as Reopened
									</Button> */}
								</>
							) : null}
						</Row>
					</div>
				</Row>
			</CardBody>
		</Card>
	);
};
