import { Col, Row, ListGroup } from "antstrap";
import React, { useState,useEffect } from "react";
import { Spinner } from "reactstrap";
import LoadingIndicator from "../../common/loading-indicator";
import ChatUserListSingle from "./chat-user-list-single";
import SingleUser from "./single-user";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
const ChatList = (props) => {
	const [chats, setChats] = useState([]);
	const [users, setUsers] = useState({});
	
	useEffect(() => {
		if (props.chats && props.chats.length) {
			// if (props.searchText != "") {
			// 	let filteredChats = props.chats.filter(
			// 		(chat) =>
			// 			(chat.users.firstName &&
			// 				chat.user.firstName
			// 					.toLowerCase()
			// 					.search(props.searchText.toLowerCase()) > -1) ||
			// 			(chat.user.lastName &&
			// 				chat.user.lastName
			// 					.toLowerCase()
			// 					.search(props.searchText.toLowerCase()) > -1)
			// 	);
			// 	setChats(filteredChats);
			// } else {
			// 	setChats(props.chats);
			// }

			setChats(props.chats);
		}
	}, [props.chats, props.searchText,props.users]);
	return (
		<div className="py-1 chat-user-list d-flex">
			<ListGroup flush className="w-100">
				<PerfectScrollbar>
					{props.chatLoading ? (
						<div className={"text-center m-auto p-3"}>
							<LoadingIndicator />
						</div>
					) : !chats || chats.length == 0 ? (
						<div className={"text-center m-auto p-3"}>
							<span>No Chats found</span>
						</div>
					) : (
						chats.map((chat, index) => (
							<ChatUserListSingle key={chat._id} chat={chat} currentUser={props.currentUser} />
						))
					)}
				</PerfectScrollbar>
			</ListGroup>
		</div>
	);
};

export default ChatList;
