import React, { useState, useEffect } from "react";
import {
	FormGroup,
	Input,
	Col,
	Row,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	InputGroup,
	InputGroupAddon,
	Button,
} from "antstrap";
const DropdownSearchBox = (props) => {
    return (
        <Row className={"dropdown-search-box"}>
        <Col sm="12" className="p-0">
        <FormGroup className="form-inline justify-content-center mt-2">
            <div className="input-group">
                <div className="input-group-prepend ">
                    <i className="material-icons input-group-text  h6">
                        search
                    </i>
                </div>
                <Input
                    type="text"
                    className="form-control border-left-0"
                    placeholder="Search..."
                    value={props.searchString}
                    onChange={(e) => {
                        props.setSearchString(e.target.value);
                    }}
                />
            </div>
        </FormGroup>
        </Col>
    </Row>
    );
};

export default DropdownSearchBox;