import { gql } from "apollo-boost";

export const GET_ORGANIZATION_DETAILS = gql`
  query getOrganizationDetails {
    getOrganizationDetails {
      _id
      name
      about
      logo
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        countryCode
        mobileNumber
        fullNumber
        email
      }
      website
      organizationUrl
      settings {
        organizationId
        post {
          isAllowLikeAndDislike
          isAllowComments
        }
        subscriber {
          isJoinOnApproval
        }
        subOrganization {
          label{
            _id
            name
            plural
          }
          childLabel{
            _id
            name
            plural
          }
          isSubOrganization
          isChildSubOrganization
        }
        privacy {
          isPrivate
        }
      }
    }
  }
`;

export const UPDATE_NAME = gql`
  mutation updateOrgName($name: String) {
    updateOrgName(name: $name) {
      _id
      name
      about
      logo
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        countryCode
        mobileNumber
        fullNumber
        email
      }
      website
      organizationUrl
    }
  }
`;

export const UPDATE_ABOUT = gql`
  mutation updateOrgAbout($about: String) {
    updateOrgAbout(about: $about) {
      _id
      name
      about
      logo
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        countryCode
        mobileNumber
        fullNumber
        email
      }
      website
      organizationUrl
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateOrgAddress($address: AddressInput) {
    updateOrgAddress(address: $address) {
      _id
      name
      about
      logo
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        countryCode
        mobileNumber
        fullNumber
        email
      }
      website
      organizationUrl
    }
  }
`;

export const UPDATE_WEBSITE = gql`
  mutation updateOrgWebsite($website: String) {
    updateOrgWebsite(website: $website) {
      _id
      name
      about
      logo
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        countryCode
        mobileNumber
        fullNumber
        email
      }
      website
      organizationUrl
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateOrgContact($contact: ContactInput) {
    updateOrgContact(contact: $contact) {
      _id
      name
      about
      logo
      address {
        addressLine
        city
        state
        postalCode
      }
      contact {
        countryCode
        mobileNumber
        fullNumber
        email
      }
      website
      organizationUrl
    }
  }
`;
