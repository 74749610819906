import React, { useState } from "react";
import { Collapse } from "reactstrap";

const PostTags = (props) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	const { subOrganizations } = props;
	return (
		<div className="post-tags" onClick={toggle}>
			{subOrganizations.length > 1 ? (
				<>
					<div className="light mr-2 d-inline-flex tags badge badge-ant" pill >
						<span>{" Computer Science and Engineering Engineering Engineering Engineering Engineering Engineering Engineering Engineering Engineering Engineering"}</span>{" "}
					</div>
                    {isOpen?null:
					<div className="light mr-2 d-inline-flex tags badge badge-ant" pill >
						<span>{`+${subOrganizations.length - 1}`}</span>{" "}
					</div>}
				</>
			) : (
				<div className="light mr-2 d-inline-flex tags badge badge-ant" pill>
					<span>{" Computer Science and Engineering"}</span>{" "}
				</div>
			)}
			<Collapse isOpen={isOpen}>
				<div className="light mr-2 d-inline-flex tags badge badge-ant" pill>
					<span>{" Computer Science and Engineering"}</span>{" "}
				</div>
			</Collapse>
		</div>
	);
};

export default PostTags;
