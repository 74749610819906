import React, { createRef, useEffect } from "react";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";

export const notificationRef = createRef(null);

export const notify = (message, type = "success") => {
  const notification = {
    place: "tc",
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 7,
    message: message,
    type: type,
  };
  notificationRef.current && notificationRef.current.notificationAlert(notification);
};


const Notification = () => {
  return (
    <div>
      <NotificationAlert ref={notificationRef} />
    </div>
  );
};

export default Notification;
