import Buttons from "../../views/temp-views/buttons";
import { Alerts } from "../../views/temp-views/alerts";

import UserRoles from "../../components/admin-dashboard/user-roles/user-roles";
import { Settings } from "../../components/admin-dashboard/settings/settings";
import { SubOrganization } from "../../components/admin-dashboard/sub-organization";
import { Dashboard } from "../../components/admin-dashboard/dashboard/dashboard";
import OrganizationProfile from "../../components/admin-dashboard/orgnization/profile";
import HelpdeskSetup from "../../components/admin-dashboard/helpdesk/helpdesk-setup";
import PaymentHistory from "../../components/admin-dashboard/payment";
import Subscription from "../../components/admin-dashboard/subscription";
import Invoices from "../../components/admin-dashboard/invoices";
let routes = [
  {
    path: "/buttons",
    name: "Back to organization",
    icon: "material-icons",
    iconName: "keyboard_backspace",
    class: "actlolve",
    component: Buttons,
    layout: "/basic",
  },
  {
    headerText: "Account",
    path: "/dashboard",
    name: "Dashboard",
    icon: "material-icons",
    iconName: "dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/organization-profile",
    name: "Organization Profile",
    icon: "material-icons",
    iconName: "business",
    component: OrganizationProfile,
    layout: "/admin",
  },
  {
    path: "/user-roles",
    name: "User Roles",
    icon: "material-icons",
    iconName: "person",
    component: UserRoles,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "material-icons",
    iconName: "settings",
    component: Settings,
    layout: "/admin",
  },
  {
    path: "/sub-organizations",
    name: "Sub Organizations",
    icon: "material-icons",
    iconName: "account_tree",
    component: SubOrganization,
    layout: "/admin",
  },
  {
    path: "/helpdesk-setup",
    name: "Helpdesk ",
    icon: "material-icons",
    iconName:"help_center",
    component: HelpdeskSetup,
    layout: "/admin",
  },
  {
    headerText: "Billing",
    path: "/billings",
    name: "Subscription",
    icon: "material-icons",
    iconName: "text_snippet",
    component: Subscription,
    layout: "/admin",
  },
  {
    path: "/payment-history",
    name: "Payment History",
    icon: "material-icons",
    iconName: "payment",
    component: PaymentHistory,
    layout: "/admin",
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: "material-icons",
    iconName: "receipt",
    component: Invoices,
    layout: "/admin",
  },
];

export default routes;
