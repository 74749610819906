import {
  SHOW_NOTIFICATION,
  UPDATE_ROLES_SUCCESS,
  SAVE_ROLES_SUCCESS,
  DELETE_ROLES_SUCCESS,
  GET_ACTIVITIES_SUCCESS,
} from "../types";

export const showNotification = (data) => {
  return {
    type: SHOW_NOTIFICATION,
    notification: data,
  };
};

export const hideNotification = (roles) => {
  return {
    type: SHOW_NOTIFICATION,
    notification: false,
  };
};


