import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { useFetchHelpdeskCategories, useRemoveHelpdeskCategory } from '../../../redux/actions/helpdesk-categories';
import MenuButton from '../../common/menu-button';
import EditCategory from './edit-category';

const Categories = (props) => {
    const helpdeskCategories =useSelector((state)=>state.helpdeskCategories);
    const [modal, setModal] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const toggle = () => setModal(!modal);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const toggleConfirmationModal = () =>
      setIsConfirmationModalOpen(!isConfirmationModalOpen);

      const fetchHelpdeskCategoriesMethod = useFetchHelpdeskCategories()
      const removeHelpdeskCategoriesMethod = useRemoveHelpdeskCategory()
      useEffect(()=>{
          fetchHelpdeskCategoriesMethod.getHelpdeskCategories()
      },[])

      const handleDelete =()=>{
        removeHelpdeskCategoriesMethod.removeHelpdeskCategory({
            variables:{
                categoryId:currentCategory._id
            }
        })
        toggleConfirmationModal()
      }
    return (
        <Fragment>
      <Table className="px-2 border table-antstrap">
        <thead className="bg-light">
          <tr>
            <th>Category Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {helpdeskCategories && helpdeskCategories._id&&
            helpdeskCategories.categories.map((category) => {
              return (
                <tr>
                  <td>{category.name}</td>
                  <td className={"text-right pr-2"}>
                  <MenuButton
                        menuButtons={[
                          {
                            name: "Edit",
                            onClick: () => {
                                setCurrentCategory(category);
                              toggle();
                            },
                          },
                          {
                            name: "Delete",
                            onClick: () => {
                              setCurrentCategory(category);
                              toggleConfirmationModal();
                            },
                          },
                          
                        ]}
                      />
                  </td>
                </tr>
              );
            })}
             {!helpdeskCategories||!helpdeskCategories._id||helpdeskCategories.categories.length==0?<td colspan={2}>
                No categories added yet
             </td>:null} 
        </tbody>
      </Table>
      <EditCategory category={currentCategory} isOpen={modal} toggle={toggle} />
      <ConfirmationModal
        category={currentCategory}
        isOpen={isConfirmationModalOpen}
        toggle={toggleConfirmationModal}
        onConfirm={handleDelete}
      />
    </Fragment>
    );
};

const ConfirmationModal = (props) => {
    return (
      <Modal centered isOpen={props.isOpen} toggle={props.toggle} className="custom-modal">
        <ModalHeader  toggle={props.toggle}>
          Delete
        </ModalHeader>
  
        <ModalBody className="py-1">
          Are you sure you want to delete {`"${props.category?props.category.name:""}" category`}?
        </ModalBody>
  
        <ModalFooter>
          <Button color="primary" onClick={props.onConfirm}>
            Delete
          </Button>{" "}
          <Button color="link" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

export default Categories;