import React from "react";

const EmptyStateSection = () => {
	return (
		<div className={"empty-state-container"}>
			<div className="empty-state-image">
				<img
					src={require("../../assets/images/img_org_empty_state.jpg")}
					className="empty-state-img mr-4"
					alt=""
				/>
			</div>
			<div className="empty-state-description">
				<p>Oops.. Nothing found here!</p>
			</div>
		</div>
	);
};

export default EmptyStateSection;
