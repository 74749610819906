import React, { useState } from "react";
import EditLogo from "./edit-logo";
import ViewLogo from "./view-logo";
import SectionCard from "../section-card";

const LogoCard = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setIsEdit(!isEdit);
  return (
    <SectionCard
      toggle={toggle}
      title={"Organization Logo (or Photo)"}
      isEdit={isEdit}
    >
      {isEdit ? (
        <EditLogo {...props} toggle={toggle} />
      ) : (
        <ViewLogo {...props} />
      )}
    </SectionCard>
  );
};

export default LogoCard;
