import {
  ADD_USER_COMMENT, FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER,
  FETCH_USER_COMMENTS_BY_TICKET_ID
} from "../types";

export const getTicketsByStatusAndTypeForUser = (data) => {
  return {
    type: FETCH_TICKETS_BY_STATUS_AND_TYPE_FOR_USER,
    payload: data,
  };
};

export const fetchUserCommentsByTicketIdSuccess = (data) => {
  return {
    type: FETCH_USER_COMMENTS_BY_TICKET_ID,
    payload: data,
  };
};

export const addUserCommentSuccess = (data) => {
  return {
    type: ADD_USER_COMMENT,
    payload: data,
  };
};