import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "react-apollo";
import {
  ADD_SUBORGANIZATION,
  GET_SUBORGANIZATIONS,
  EDIT_SUBORGANIZATION,
  ADD_CHILD_SUBORGANIZATION,
  DELETE_SUBORGANIZATION,
  EDIT_CHILD_SUB_ORGANIZATION,
  DELETE_CHILD_SUB_ORGANIZATION,
} from "../services/sub-organization";
import {
  fetchSubOrganizations,
  addSubOrganizationAction,
  addChildSubOrganizationAction,
  fetchChildSubOrganizationAction,
  deleteSubOrganizationAction,
  editSubOrganizationAction,
  editChildSubOrganizationAction,
  deleteChildSubOrganizationAction,
} from "./redux-actions/sub-organization";
import { notify } from "../../components/common/notification";

export const useAddSubOrganization = (toggle) => {
  const dispatch = useDispatch();
  const [addSubOrganization, { loading }] = useMutation(ADD_SUBORGANIZATION, {
    onCompleted(data) {
      if (data && data.addSubOrganization) {
        dispatch(addSubOrganizationAction(data.addSubOrganization));
        toggle();
      }
    },
    onError(error) {
      if (error) {
        notify(error.message, "danger");
      }
    },
    fetchPolicy: "no-cache",
  });
  return { addSubOrganization, loading };
};

export const useFetchSubOrganizations = () => {
  const dispatch = useDispatch();
  const [fetchSuborganizations, { loading }] = useLazyQuery(
    GET_SUBORGANIZATIONS,
    {
      onCompleted(data) {
        if (data && data.getSubOrganizations) {
          dispatch(fetchSubOrganizations(data.getSubOrganizations));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { fetchSubOrganizations: fetchSuborganizations, loading };
};

export const useEditSubOrganization = (toggle) => {
  const dispatch = useDispatch();

  const [updateSubOrganization, { loading }] = useMutation(
    EDIT_SUBORGANIZATION,
    {
      onCompleted(data) {
        if (data && data.updateSubOrganization) {
          dispatch(editSubOrganizationAction(data.updateSubOrganization));
          toggle();
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { updateSubOrganization, loading };
};

export const useDeleteSubOrganization = (toggle) => {
  const dispatch = useDispatch();
  const [deleteSubOrganization, { loading }] = useMutation(
    DELETE_SUBORGANIZATION,
    {
      onCompleted(data) {
        if (data && data.removeSubOrganization) {
          dispatch(deleteSubOrganizationAction(data.removeSubOrganization));
          toggle();
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { deleteSubOrganization, loading };
};

export const useAddChildSubOrganization = (toggle) => {
  const dispatch = useDispatch();
  const [addChildSubOrganization, { loading }] = useMutation(
    ADD_CHILD_SUBORGANIZATION,
    {
      onCompleted(data) {
        if (data && data.addChildSubOrganization) {
          dispatch(addChildSubOrganizationAction(data.addChildSubOrganization));
          toggle();
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { addChildSubOrganization, loading };
};

export const useEditChildSubOrganization = (toggle) => {
  const dispatch = useDispatch();
  const [updateChildSubOrganization, { loading }] = useMutation(
    EDIT_CHILD_SUB_ORGANIZATION,
    {
      onCompleted(data) {
        if (data && data.updateChildSubOrganization) {
          dispatch(
            editChildSubOrganizationAction(data.updateChildSubOrganization)
          );
          toggle();
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { updateChildSubOrganization, loading };
};

export const useDeleteChildSubOrganization = (toggle) => {
  const dispatch = useDispatch();
  const [deleteChildSubOrganization, { loading }] = useMutation(
    DELETE_CHILD_SUB_ORGANIZATION,
    {
      onCompleted(data) {
        if (data && data.removeChildSubOrganization) {
          dispatch(
            deleteChildSubOrganizationAction(data.removeChildSubOrganization)
          );
          toggle();
        }
      },
      onError(error) {
        if (error) {
          error = false;
          //this.notify('Error');
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { deleteChildSubOrganization, loading };
};

export const useFetchChildSubOrganization = (toggle) => {
  const dispatch = useDispatch();
  const [getChildSubOrganizations, { loading }] = useMutation(
    ADD_SUBORGANIZATION,
    {
      onCompleted(data) {
        if (data && data.getChildSubOrganizations) {
          dispatch(
            fetchChildSubOrganizationAction(data.getChildSubOrganizations)
          );
          toggle();
        }
      },
      onError(error) {
        if (error) {
          error = false;
          //this.notify('Error');
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { getChildSubOrganizations, loading };
};
