import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Button,
	Container,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "antstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SingleTicketCard } from "../single-ticket-card";
import classnames from "classnames";
import { TicketDetailsComment, TicketUpdates } from "../ticket-updates1";
import { TicketMessage } from "./ticket-message";
import { generateTicketNUmberToDisplay } from "../constants";
import {
	useFetchTicketByStatusAndTypeForUser,
	useFetchUserCommentsByTicketId,
} from "../../../redux/actions/my-space";

export const UserRequest = (props) => {
	const [activeTab, setActiveTab] = useState("1");
	const [ticket, setTicket] = useState(null);
	const {
		getTicketByStatusAndTypeForUser,
	} = useFetchTicketByStatusAndTypeForUser();
	const { fetchUserCommentsByTicketId } = useFetchUserCommentsByTicketId();
	const userId = useSelector((state) => state.user._id);

	const tickets = useSelector((state) =>
		state.myRequests ? state.myRequests : []
	);

	const type = !isNaN(props.match.params.type)
		? Number(props.match.params.type)
		: 0;

	useEffect(() => {
		//fix status and type
		getTicketByStatusAndTypeForUser({
			variables: { status: 1, type: type, userId: userId },
		});
	}, [userId]);

	useEffect(() => {
		setTicket(
			tickets.filter((ticket) => ticket._id === props.match.params.id)[0]
		);
	}, [tickets]);

	useEffect(() => {
		fetchUserCommentsByTicketId({
			variables: { _id: props.match.params.id },
		});
	}, [ticket]);

	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	return (
		<Row className="mainWrapper helpdesk-wrapper">
			<Col md={12} className="border-right">
				<Row className="border-bottom mb-3 helpdesk-header bg-white">
					<Button
						className="btn-left btn-light border-0 px-4 bg-white"
						onClick={() => props.history.goBack()}
					>
						<i className="material-icons">arrow_back</i>
					</Button>
					<h5 className="ticket-title py-3 pl-4 mb-0">
						{`${
							ticket && generateTicketNUmberToDisplay(ticket.ticketNumber)
						} - ${ticket && ticket.title}`}
					</h5>
					<div className="user-info-toggler">
						{/* <Button
          size="sm"
          className={userInfo?"toggle-active btn-light border-0  toggle-button":"btn-light border-0  toggle-button"}
            onClick={() => toggleUserInfo()}
          >
           <InfoOutlinedIcon/>
          </Button> */}
					</div>
				</Row>
				<Container fluid>
					{ticket && <SingleTicketCard ticket={ticket} type={type} isSelfTicket={true}/>}

					<Nav tabs className="tabs-antstrap">
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggleTab("1");
								}}
							>
								<FormattedMessage id="updates" />
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1" className="py-4">
							{ticket ? (
								<TicketDetailsComment
									ticket={ticket}
									index={0}
									lastIndex={false}
								/>
							) : null}
							{ticket &&
								ticket.comments &&
								ticket.comments.map((comment, index) => (
									<TicketUpdates
										comment={comment}
										index={index}
										lastIndex={index == ticket.comments.length - 1}
									/>
								))}

							<TicketMessage ticketId={props.match.params.id} ticket={ticket} />
						</TabPane>
					</TabContent>
				</Container>
			</Col>
		</Row>
	);
};
