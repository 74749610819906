import { ADD_POLL, ADD_POST, FETCH_LATEST_POSTS, UPDATE_POST } from "../types";

export const getLatestPostsAction = (data) => {
  return {
    type: FETCH_LATEST_POSTS,
    payload: data,
  };
};

export const addPostAction = (data) => {
  return {
    type: ADD_POST,
    payload: data,
  };
};


export const addPollAction = (data) => {
  return {
    type: ADD_POLL,
    payload: data,
  };
};


export const updatePost = (data) => {
  return {
    type: UPDATE_POST,
    payload: data,
  };
};

