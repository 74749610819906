import { Button, Card, CardBody, Col, Row } from "antstrap";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import ConfigureSubOrganizationModal from "./configure-suborganization";
const EnableSubOrganization = (props) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const toggle = () => setOpenModal(!isOpenModal);
  return (
    <>
      <Col sm="12" className="text-center">
        <h2>Sub Organizations</h2>
        <p>
          <FormattedMessage
            id="{subOrganization.hierarchy}"
            defaultMessage="Sub organization define your organization hierarchy"
          />
        </p>
      </Col>

      <Card className="mt-4 m-2">
        <CardBody className="p-5 text-center">
          <Row>
            <img
              className="col-sm-8 mx-auto"
              src={require("assets/images/Sub_Organization_Image.svg")}
              alt="organization hierarchy"
              height={110}
            />
          </Row>
          <p className="p-4">
            <FormattedMessage
              id="{subOrganization.divisons}"
              defaultMessage="Does your organization have multiple departments or divisions?"
            />

            <br />
            <FormattedMessage
              id="{subOrganization.postWarning}"
              defaultMessage="Posts can be targeted to individual sub programs."
            />

            <br />
            <FormattedMessage
              id="{subOrganization.subscriberWarning}"
              defaultMessage="Subscriber can filter the posts as per their preference."
            />
          </p>
          <Button color="primary" className="mx-auto " onClick={toggle}>
            <FormattedMessage id="{enable}" defaultMessage="Enable" /> and{" "}
            <FormattedMessage id="{configure}" defaultMessage="Configure" />
          </Button>
        </CardBody>
      </Card>

      <Row>
        <p className="px-5 pt-3 pb-5 text-center mx-auto">
          <FormattedMessage
            id="{subOrganization.configureMessage1}"
            defaultMessage="Once Sub Organizations are enabled, it cannot be undone. "
          />
          <br />
          <FormattedMessage
            id="{subOrganization.configureMessage2}"
            defaultMessage="If you have any questions, please contact our support team"
          />

          <a href="mailto:info@antridge.com">info@antridge.com</a>
        </p>
      </Row>
      <ConfigureSubOrganizationModal isOpen={isOpenModal} toggle={toggle} />
    </>
  );
};

export default EnableSubOrganization;
