import React from "react";
import { Card, CardBody, CardFooter, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

const SectionButtonFullCard = (props) => {
  const history = useHistory();
  const onClick = (route) => {
    history.push(route);
  };
  return (
    <Card className="admin-card my-3">
      <CardBody>
        <div className="media">
        
          <img
            src={props.icon}
            className="media-img-full mr-4"
            alt=""
          />
            <div className="media-content">
          <div className="media-body">
            <h6 className="mt-0">{props.title}</h6>
            <p>{props.description}</p>
          </div>
          
          <Button color="primary" outline>
											Get support
										</Button>
                    </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SectionButtonFullCard;
