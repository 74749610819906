import { Col, Row, Button } from "antstrap";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

export const UserInfo = ({ user, chat, currentUser, toggleUserInfo }) => {
  const [users, setUsers] = useState([]);



  useEffect(() => {
    if (chat) {
      
      let filteredUsers = chat.users.filter(user => user._id != currentUser._id);
      console.log(filteredUsers)
      setUsers(filteredUsers)
    }

  }, [chat])

  return (
    <Row className="m-auto  bg-white py-3  user-info">
      <Col sm="12" className="text-right">
        <Button
          className="btn-light border-0 px-3 bg-white back-button box-shadow-none"
          onClick={() => toggleUserInfo()}
        >
          <i className="material-icons">close</i>
        </Button>
      </Col>
      <Col sm="12" className="mx-auto text-center py-3">
        <Row>
          <Col sm="12">
            <img
              className="rounded-circle mx-auto col-sm-6"
              src={require("assets/images/no-photo.svg")}
            />
            <h5 className="font-weight-bold mt-2 mb-0">
              {" "}
              {user ? (user.firstName +
                " " +
                user.lastName) : (users.length ? users[0].firstName +
                  " " +
                  users[0].lastName : "")
              }
            </h5>
            <p>

              <div className="badge badge-ant mt-2"><FormattedMessage id="orgUser" /></div>

            </p>
          </Col>


        </Row>
      </Col>
      <Row className="ml-0 border-top mx-auto section-profile p-4">

        <div class="section-profile-item">
          <i className="material-icons mr-2">local_phone</i>
          <p >
            {" "}
            {user ? user.fullNumber : users.length ? users[0].fullNumber : "NA"}
          </p>
        </div>
        <div class="section-profile-item">
          <i className="material-icons mr-2">email</i>
          <p >
            {" "}
            {user ? user.email : users.length ? users[0].email : "NA"}
          </p>
        </div>
      </Row>
    </Row>
  );
};
