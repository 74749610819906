import { Col, Row, ListGroup } from "antstrap";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import LoadingIndicator from "../../common/loading-indicator";
import ChatUserListSingle from "./chat-user-list-single";

import PerfectScrollbar from 'react-perfect-scrollbar'
import SingleUser from "./single-user";
const ChatUserList = (props) => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		if (props.users && props.users.length) {
			if (props.searchText != "") {
				let filteredUsers = props.users.filter((user) => user.user.firstName && user.user.firstName.toLowerCase().search(props.searchText.toLowerCase()) > -1 || user.user.lastName && user.user.lastName.toLowerCase().search(props.searchText.toLowerCase()) > -1)
				setUsers(filteredUsers)
			} else {
				setUsers(props.users)
			}

		}
	}, [props.users, props.searchText])
	return (
		<div className="py-1 chat-user-list d-flex">

			<ListGroup flush className="w-100">
				<PerfectScrollbar>
					{props.userLoading ? (
						<div className={"text-center m-auto p-3"}>
							<LoadingIndicator />
						</div>
					) : !users || users.length == 0 ? (
						<div className={"text-center m-auto p-3"}>
							<span>No People found</span>
						</div>
					) : (
						users.map((user, index) => (
							<SingleUser
								key={user._id}
								user={user}
								setUser={props.setUser}
								currentUser={props.currentUser}
								activeUser={props.activeUser}
							/>
						))
					)}
				</PerfectScrollbar>
			</ListGroup>

		</div>
	);
};

export default ChatUserList;
