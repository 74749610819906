import React, { useState } from "react";
import { Row, Col, CardText, Button, Form, Input, Label } from "antstrap";
import { FormattedMessage } from "react-intl";
import { useUpdateAddress } from "../../../../redux/actions/organization";

const EditAddress = (props) => {
  let addressToEdit = { ...props.address };
  delete addressToEdit.__typename;
  const [address, setAddress] = useState(addressToEdit);

  const updateAddress = useUpdateAddress(address);

  const onSave = () => {
    updateAddress.updateAddress();
    props.toggle();
  };

  return (
    <Form>
      <Row>
        <Col sm="12">
          <CardText>
            <Row>
              <Col sm={12}>
                <Label>
                  {" "}
                  <small>
                    <FormattedMessage
                      id="address_line_1"
                      defaultMessage="Address Line 1"
                    />
                  </small>
                </Label>
                <Input
                  type="text"
                  value={address.addressLine}
                  onChange={(e) =>
                    setAddress({ ...address, addressLine: e.target.value })
                  }
                />
              </Col>
              <Col sm={12} md={6} lg={4}>
                <Label>
                  {" "}
                  <small>
                    <FormattedMessage id="city" defaultMessage="City" />
                  </small>
                </Label>
                <Input
                  type="text"
                  value={address.city}
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                />
              </Col>

              <Col sm={12} md={6} lg={4}>
                <Label>
                  {" "}
                  <small>
                    <FormattedMessage id="state" defaultMessage="State" />
                  </small>
                </Label>
                <Input
                  type="text"
                  value={address.state}
                  onChange={(e) =>
                    setAddress({ ...address, state: e.target.value })
                  }
                />
              </Col>

              <Col sm={12} md={6} lg={4}>
                <Label>
                  {" "}
                  <small>
                    <FormattedMessage
                      id="edit_postal_code"
                      defaultMessage="Postal Code"
                    />
                  </small>
                </Label>
                <Input
                  type="text"
                  value={address.postalCode}
                  onChange={(e) =>
                    setAddress({ ...address, postalCode: e.target.value })
                  }
                />
              </Col>
            </Row>
          </CardText>
          <Button color="primary" onClick={() => onSave()}>
            <FormattedMessage id="common.save" defaultMessage="Save" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditAddress;
