import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import MainApp from "./app";
import "./assets/scss/_app.scss";
import "./antstrap/scss/bootstrap.scss";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-datepicker/dist/react-datepicker.css";
import * as serviceWorker from "./serviceWorker";
import label_en from "assets/intl/label_en.json";
import message_en from "assets/intl/message_en.json";
import errorMessage_en from "assets/intl/errorMessage_en.json";
import label_mr from "assets/intl/label_mr.json";
import message_mr from "assets/intl/message_mr.json";
import errorMessage_mr from "assets/intl/errorMessage_mr.json";
import 'react-perfect-scrollbar/dist/css/styles.css';
const messages = {
  en: { ...label_en, message_en, errorMessage_en },
  mr: { ...label_mr, message_mr, errorMessage_mr },
};

ReactDOM.render(
  <IntlProvider
    locale={navigator.language}
    messages={messages[navigator.language]}
  >
    <React.StrictMode>
      <MainApp />
    </React.StrictMode>
  </IntlProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
