import { gql } from "apollo-boost";

export const GET_LATEST_POSTS_QUERY = gql`
	query getLatestPosts($userId: String) {
		getLatestPosts(userId:$userId) {
			_id
			title
			description
			media
			suborgs
			organizationId
			status
			visibility
			type
			likes
			dislikes
			views
			shares
			postedBy
			expiryDate
			createdAt
			updatedAt
			isLiked
			isDisliked
			totalVotes
			isExpired
			options {
				_id
				option
				media
				votes
				isVoted
			}
			user {
				_id
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const ADD_POST_MUTATION = gql`
	mutation addPost($post: PostDetailsInput) {
		addPost(post: $post) {
			_id
			title
			description
			media
			suborgs
			organizationId
			status
			visibility
			type
			likes
			dislikes
			views
			shares
			postedBy
			isLiked
			isDisliked
			expiryDate
			isExpired
			createdAt
			updatedAt
			totalVotes
			options {
				_id
				option
				media
				votes
				isVoted
			}
			user {
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const ADD_POLL_MUTATION = gql`
	mutation addPoll($poll: PollDetailsInput) {
		addPoll(poll: $poll) {
			_id
			title
			description
			media
			suborgs
			organizationId
			status
			visibility
			type
			likes
			dislikes
			views
			shares
			postedBy
			expiryDate
			createdAt
			updatedAt
			isLiked
			isDisliked
			isExpired
			totalVotes
			options {
				_id
				option
				media
				votes
				isVoted
			}
			user {
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const LIKE_POST_MUTATION = gql`
	mutation likePost($postId: String, $userId: String) {
		likePost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;

export const DISLIKE_POST_MUTATION = gql`
	mutation dislikePost($postId: String, $userId: String) {
		dislikePost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;

export const REMOVE_LIKE_POST_MUTATION = gql`
	mutation removeLikePost($postId: String, $userId: String) {
		removeLikePost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;

export const REMOVE_DISLIKE_POST_MUTATION = gql`
	mutation removeDislikePost($postId: String, $userId: String) {
		removeDislikePost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;


export const SUBMIT_POLL_MUTATION = gql`
	mutation submitPoll($postId: String, $userId: String,$optionId: String) {
		submitPoll(postId: $postId, userId: $userId,optionId:$optionId) {
			_id
			isExpired
			totalVotes
			options{
				_id
				option
				media
				votes
				isVoted
			}
		}
	}
`;