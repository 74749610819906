import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import MenuButton from "../common/menu-button";
import ConfirmationModal from "./delete-user-modal";
import { useChangeOrganizationUserStatus } from "redux/actions/user";
import { USER_STATUS } from "../appConstants";
import EditUser from "./edit-user-modal";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import MoreButton from "../common/more-button";

export const UserProfiles = ({ user,subOrgs,subOrganizationLabel,settings }) => {
	const [modal, setModal] = useState(false);
	const organizationId = useSelector((state) => state.user.organizationId);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

	const { changeOrganizationUserStatus } = useChangeOrganizationUserStatus();
	const history = useHistory();
	const toggle = () => {
		history.push({
			pathname: "/user/edit-user",
			state: { user },
		});
	};
	const toggleConfirmationModal = () =>
		setIsConfirmationModalOpen(!isConfirmationModalOpen);

	const handleDelete = () => {
		changeOrganizationUserStatus({
			variables: {
				organizationId: organizationId,
				status: USER_STATUS.DELETED,
				_id: user._id,
			},
		});
		toggleConfirmationModal();
	};

	let userRole =
		user.rolesDetails.length > 1 ? (
			<>
				<div className="badge badge-ant mr-1 mwp-100">
					<span>{user.rolesDetails[0].name}</span>
				</div>
				<MoreButton
					title={"Roles"}
					menuButtons={user.rolesDetails.map((role) => ({
						name: role.name,
					}))}
				/>
			</>
		) : (
			<div className="badge badge-ant mwp-100">{<span>no role assigned</span>}</div>
		);

	let userSubOrgs =Object.keys(subOrgs).length? (user.subOrganizations.length > 1 ? (
		<>
			<div className="badge badge-ant mr-1 mwp-100">
			<span>{subOrgs[user.subOrganizations[0]].name}</span>
			</div>
			<MoreButton
				title={subOrganizationLabel}
				menuButtons={user.subOrganizations.map((subOrg) => ({
					name: subOrgs[subOrg].name,
				}))}
			/>
		</>
	) : (
		user.subOrganizations.length?<div className="badge badge-ant mwp-100"><span>{subOrgs[user.subOrganizations[0]].name}</span></div>:null
	)):'';
	// uncomment when dept has value
	// let userDept = user.department.map(
	//   (role)=> {return <div className="badge badge-ant">{role.name}</div>}
	//   );

	return (
		<Fragment>
			<tr>
				<td className="text-right">
					<img
						className="image-rounded ml-2 mr-1"
						src={require("assets/images/no-photo.svg")}
					/>
				</td>
				<td>
					{" "}
					{user.user.firstName === "" && user.user.lastName === ""
						? "No Name"
						: `${user.user.firstName} ${user.user.lastName}`}
				</td>
				<td>
					{user.user.countryCode === null && user.user.mobileNumber === null
						? "No Number"
						: `${user.user.countryCode}-${user.user.mobileNumber}`}
				</td>
				<td>{user.user.email}</td>
				<td>{userSubOrgs}</td>
				{settings&&settings.subOrganization&&settings.subOrganization.isSubOrganization?
				<td>{userRole}</td>:null}
				<td>
					<MenuButton
						menuButtons={[
							{
								name: "Edit",
								onClick: () => {
									toggle();
								},
							},
							{
								name: "Delete",
								onClick: () => {
									toggleConfirmationModal();
								},
							},
						]}
					/>
				</td>
			</tr>

			<ConfirmationModal
				isOpen={isConfirmationModalOpen}
				toggle={toggleConfirmationModal}
				onConfirm={handleDelete}
			/>
			<EditUser user={user} modal={modal} toggle={toggle} />
		</Fragment>
	);
};
