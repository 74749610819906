import { FETCH_HELPDESK_CATEGORIES } from "../actions/types";

const helpdeskCategoriesReducer = (state = {}, action) => {
	if (action.type === FETCH_HELPDESK_CATEGORIES) {
		return action.payload;
	}
	return state;
};

export default helpdeskCategoriesReducer;
