import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
	FormGroup,
	Input,
	Col,
	Row,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	Button,
	DropdownToggle,
} from "antstrap";

import { USER_STATUS } from "../appConstants";
import { UserProfiles } from "./user-profiles";
import SubOrganizationsDropdown from "../common/sub-organization";
import RolesDropdown from "../common/roles-dropdown";

export const UserTable = ({ user }) => {
	const [selectedSubOrganization, setSubOrganization] = useState(false);
	const [selectedRole, setRole] = useState(false);
	const [subOrgsObject, setSubOrgsObject] = useState({});
	const [dropdownRole, setDropdownRole] = useState(false);
	const toggleRole = () => setDropdownRole((prevState) => !prevState);
	const roles = useSelector((state) => state.roles);
	const subOrganizations = useSelector((state) => state.subOrganizations);
	const [searchString, setSearchString] = useState("");
	const [roleFilter, setRoleFilter] = useState("");
	const [subOrgFilter, setSubOrgFilter] = useState("");

	const settings = useSelector((state) => state.settings);
	const subOrganizationLabel = settings.subOrganization
		? settings.subOrganization.label.plural
		: "SubOrganizations";

	useEffect(() => {
		let subOrgs = {};
		if (subOrganizations && subOrganizations.length) {
			subOrganizations.map((subOrganization) => {
				subOrgs[subOrganization._id] = subOrganization;
			});

			setSubOrgsObject(subOrgs);
		}
	}, [subOrganizations]);
	const isPresent = (user) => {
		if (
			searchString.trim() === "" &&
			!selectedRole &&
			!selectedSubOrganization
		) {
			return true;
		} else {
			let result = true;
			if (searchString.trim() != "") {
				if (
					(user.user.firstName &&
						user.user.firstName
							.toLowerCase()
							.includes(searchString.toLowerCase())) ||
					(user.user.lastName &&
						user.user.lastName
							.toLowerCase()
							.includes(searchString.toLowerCase())) ||
					user.user.email.includes(searchString.toLowerCase()) ||
					user.user.mobileNumber
						.replace("-", "")
						.includes(searchString.toLowerCase())
				) {
					result = true;
				} else {
					result = false;
				}
			}
			if (selectedRole && selectedRole._id) {
				if (user.roles.indexOf(selectedRole._id) > -1) {
					result = result && true;
				} else {
					result = false;
				}
			}

			if (selectedSubOrganization && selectedSubOrganization._id) {
				if (user.subOrganizations.indexOf(selectedSubOrganization._id) > -1) {
					result = result && true;
				} else {
					result = false;
				}
			}

			return result;
		}
	};
	return (
		<Fragment>
			<div className="pt-3 row m-0">
				<Col sm="12" md="8">
					<Row>
						<Col sm="12" className="pl-0 d-flex ">
							{settings &&
							settings.subOrganization &&
							settings.subOrganization.isSubOrganization ? (
								<SubOrganizationsDropdown
									value={selectedSubOrganization}
									onSelect={(value) => setSubOrganization(value)}
								/>
							) : null}
							<RolesDropdown
								value={selectedRole}
								onSelect={(value) => setRole(value)}
							/>
							{selectedSubOrganization || selectedRole ? (
								<Button
									color="link"
									className={"px-0"}
									onClick={() => {
										setRole(false);
										setSubOrganization(false);
									}}
								>
									Clear Filter
								</Button>
							) : null}
						</Col>
					</Row>
				</Col>
				<Col sm="12" md="4" className="pr-0">
					<FormGroup className="form-inline float-right">
						<div className="input-group">
							<div className="input-group-prepend ">
								<i className="material-icons input-group-text bg-white h6">
									search
								</i>
							</div>
							<Input
								type="text"
								className="form-control border-left-0"
								placeholder="Search User"
								value={searchString}
								onChange={(e) => {
									setSearchString(e.target.value);
								}}
							/>
						</div>
					</FormGroup>
				</Col>
			</div>
			<div>
				<table className="px-2 border table-antstrap table">
					<thead className="bg-gray">
						<tr>
							<th></th>
							<th>NAME</th>
							<th>PHONE NUMBER</th>
							<th>EMAIL</th>
							{settings &&
							settings.subOrganization &&
							settings.subOrganization.isSubOrganization ? (
								<th>{subOrganizationLabel.toUpperCase()}</th>
							) : null}
							<th>ROLE</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{user.map(
							(user) =>
								user.status === USER_STATUS.ACTIVE &&
								isPresent(user) && (
									<UserProfiles
										user={user}
										subOrgs={subOrgsObject}
										settings={settings}
										subOrganizationLabel={subOrganizationLabel}
									/>
								)
						)}
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};
