import {
  DELETE_ENTRY,
  SET_ORGANIZATION_DETAILS,
  SET_ORGANIZATION_DETAILS_BY_ID,
  UPDATE_NAME_SUCCESS,
  UPDATE_ABOUT_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_WEBSITE_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_ROLES_SUCCESS,
  GET_ROLES_BY_ORGANIZATION_ID,
  SAVE_ROLES_SUCCESS,
  GET_ACTIVITIES_SUCCESS,
  FETCH_ADMIN_SETTINGS,
  FETCH_SUB_ORGANIZATIONS,
  ADD_SUB_ORGANIZATION,
  EDIT_SUB_ORGANIZATION,
  DELETE_SUB_ORGANIZATION,
  ADD_CHILD_SUB_ORGANIZATION,
  GET_CHILD_SUB_ORGANIZATION,
  DELETE_CHILD_SUB_ORGANIZATION,
  EDIT_CHILD_SUB_ORGANIZATION,
  DELETE_ROLES_SUCCESS,
  CHANGE_ORGANIZATION_USER_STATUS_SUCCESS,
  ADD_ORGANIZATION_USER_SUCCESS,
  UPDATE_ORGANIZATION_USER_SUCCESS,
  FETCH_ORGANIZATION_USERS,
  CHECKED_SUBSCRIBERS,
  FETCH_SUBSCRIBERS,
  UPDATE_SUBSCRIBER_STATUS,
  NOTIFY,
  UPDATE_SUBSCRIBER_ACTIVE_STATUS,
  FETCH_TICKETS_BY_STATUS_AND_TYPE,
  FETCH_COMMENTS_BY_TICKET_ID,
  ADD_COMMENT,
  UPDATE_TICKET_STATUS,
  ASSIGN_TICKET,
  UPDATE_SUB_ORGANIZATION_OF_TICKET,
  UPDATE_DUE_DATE_STATUS_AND_PRIORITY,
} from "./types";

export const deleteEntry = (id) => {
  return {
    type: DELETE_ENTRY,
    id,
  };
};
export const setOrganizationDetails = (organizationDetails) => {
  return {
    type: SET_ORGANIZATION_DETAILS,
    payload: organizationDetails,
  };
};

export const setOrganizationDetailsById = (organizationDetails) => {
  return {
    type: SET_ORGANIZATION_DETAILS_BY_ID,
    payload: organizationDetails,
  };
};

export const updateNameSuccess = (name) => {
  return {
    type: UPDATE_NAME_SUCCESS,
    payload: name,
  };
};

export const updateAboutSuccess = (about) => {
  return {
    type: UPDATE_ABOUT_SUCCESS,
    payload: about,
  };
};

export const updateAddressSuccess = (address) => {
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    payload: address,
  };
};

export const updateWebsiteSuccess = (website) => {
  return {
    type: UPDATE_WEBSITE_SUCCESS,
    payload: website,
  };
};

export const updateContactSuccess = (contact) => {
  return {
    type: UPDATE_CONTACT_SUCCESS,
    payload: contact,
  };
};

export const getRolesByOrganizationId = (roles) => {
  return {
    type: GET_ROLES_BY_ORGANIZATION_ID,
    payload: roles,
  };
};

export const updateRolesSuccess = (roles) => {
  return {
    type: UPDATE_ROLES_SUCCESS,
    payload: roles,
  };
};

export const saveRolesSuccess = (roles) => {
  return {
    type: SAVE_ROLES_SUCCESS,
    payload: roles,
  };
};

export const deleteRolesSuccess = (roles) => {
  return {
    type: DELETE_ROLES_SUCCESS,
    payload: roles,
  };
};

export const getActivitiesSuccess = (activities) => {
  return {
    type: GET_ACTIVITIES_SUCCESS,
    payload: activities,
  };
};

export const fetchAdminSettings = (settings) => {
  return {
    type: FETCH_ADMIN_SETTINGS,
    payload: settings,
  };
};

export const addSubOrganizationAction = (subOrganization) => {
  return {
    type: ADD_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const fetchSubOrganizations = (subOrganizations) => {
  return {
    type: FETCH_SUB_ORGANIZATIONS,
    payload: subOrganizations,
  };
};

export const changeOrganizationUserStatusSuccess = (roles) => {
  return {
    type: CHANGE_ORGANIZATION_USER_STATUS_SUCCESS,
    payload: roles,
  };
};

export const addOrganizationUserSuccess = (roles) => {
  return {
    type: ADD_ORGANIZATION_USER_SUCCESS,
    payload: roles,
  };
};

export const updateOrganizationUserSuccess = (roles) => {
  return {
    type: UPDATE_ORGANIZATION_USER_SUCCESS,
    payload: roles,
  };
};

export const getOrganizationUser = (roles) => {
  return {
    type: FETCH_ORGANIZATION_USERS,
    payload: roles,
  };
};
export const editSubOrganizationAction = (subOrganization) => {
  return {
    type: EDIT_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const deleteSubOrganizationAction = (subOrganization) => {
  return {
    type: DELETE_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const addChildSubOrganizationAction = (childSubOrganization) => {
  return {
    type: ADD_CHILD_SUB_ORGANIZATION,
    payload: childSubOrganization,
  };
};

export const fetchChildSubOrganizationAction = (childSubOrganization) => {
  return {
    type: GET_CHILD_SUB_ORGANIZATION,
    payload: childSubOrganization,
  };
};

export const deleteChildSubOrganizationAction = (subOrganization) => {
  return {
    type: DELETE_CHILD_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const editChildSubOrganizationAction = (subOrganization) => {
  return {
    type: EDIT_CHILD_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const selectSubscriber = (name) => {
  return {
    type: CHECKED_SUBSCRIBERS,
    name,
  };
};

export const fetchSubscriberAction = (subscriberData) => {
  return {
    type: FETCH_SUBSCRIBERS,
    subscriberData,
  };
};

export const updateSubscriberStatusAction = (subscriberData) => {
  return {
    type: UPDATE_SUBSCRIBER_STATUS,
    subscriberData,
  };
};

export const updateSubscriberActiveStatus = (status) => {
  return {
    type: UPDATE_SUBSCRIBER_ACTIVE_STATUS,
    payload: status,
  };
};
export const getTicketsByStatusAndType = (data) => {
  return {
    type: FETCH_TICKETS_BY_STATUS_AND_TYPE,
    payload: data,
  };
};

export const fetchCommentsByTicketIdSuccess = (data) => {
  return {
    type: FETCH_COMMENTS_BY_TICKET_ID,
    payload: data,
  };
};

export const addCommentSuccess = (data) => {
  return {
    type: ADD_COMMENT,
    payload: data,
  };
};

export const updateTicketStatusSuccess = (data) => {
  return {
    type: UPDATE_TICKET_STATUS,
    payload: data,
  };
};

export const assignTicketSuccess = (data) => {
  return {
    type: ASSIGN_TICKET,
    payload: data,
  };
};

export const updateSubOrganizationOfTicketSuccess = (data) => {
  return {
    type: UPDATE_SUB_ORGANIZATION_OF_TICKET,
    payload: data,
  };
};

export const updateDueDateStatusAndPrioritySuccess = (data) => {
  return {
    type: UPDATE_DUE_DATE_STATUS_AND_PRIORITY,
    payload: data,
  };
};
