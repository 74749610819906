import { gql } from "apollo-boost";

export const GET_LATEST_POSTS_QUERY = gql`
	query getLatestInternalPosts($userId: String) {
		getLatestInternalPosts(userId:$userId) {
			_id
			title
			description
			media
			suborgs
			organizationId
			status
			visibility
			type
			likes
			dislikes
			views
			shares
			postedBy
			expiryDate
			createdAt
			updatedAt
			isLiked
			isDisliked
			totalVotes
			isExpired
			options {
				_id
				option
				media
				votes
				isVoted
			}
			user {
				_id
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const ADD_POST_MUTATION = gql`
	mutation addInternalPost($post: PostDetailsInput) {
		addInternalPost(post: $post) {
			_id
			title
			description
			media
			suborgs
			organizationId
			status
			visibility
			type
			likes
			dislikes
			views
			shares
			postedBy
			isLiked
			isDisliked
			expiryDate
			isExpired
			createdAt
			updatedAt
			totalVotes
			options {
				_id
				option
				media
				votes
				isVoted
			}
			user {
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const ADD_POLL_MUTATION = gql`
	mutation addInternalPoll($poll: PollDetailsInput) {
		addInternalPoll(poll: $poll) {
			_id
			title
			description
			media
			suborgs
			organizationId
			status
			visibility
			type
			likes
			dislikes
			views
			shares
			postedBy
			expiryDate
			createdAt
			updatedAt
			isLiked
			isDisliked
			isExpired
			totalVotes
			options {
				_id
				option
				media
				votes
				isVoted
			}
			user {
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const LIKE_POST_MUTATION = gql`
	mutation likeInternalPost($postId: String, $userId: String) {
		likeInternalPost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;

export const DISLIKE_POST_MUTATION = gql`
	mutation dislikeInternalPost($postId: String, $userId: String) {
		dislikeInternalPost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;

export const REMOVE_LIKE_POST_MUTATION = gql`
	mutation removeLikeInternalPost($postId: String, $userId: String) {
		removeLikeInternalPost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;

export const REMOVE_DISLIKE_POST_MUTATION = gql`
	mutation removeDislikeInternalPost($postId: String, $userId: String) {
		removeDislikeInternalPost(postId: $postId, userId: $userId) {
			_id
			likes
			dislikes
			isLiked
			isDisliked
		}
	}
`;

export const SUBMIT_POLL_MUTATION = gql`
	mutation submitInternalPoll($postId: String, $userId: String,$optionId: String) {
		submitInternalPoll(postId: $postId, userId: $userId,optionId:$optionId) {
			_id
			isExpired
			totalVotes
			options{
				_id
				option
				media
				votes
				isVoted
			}
		}
	}
`;