import React from "react";
import EditName from "./edit-name";
import ViewName from "./view-name";
import SectionCard from "../section-card";
import { useState } from "react";

const NameCard = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setIsEdit(!isEdit);
  return (
    <SectionCard toggle={toggle} title={"Organization Name"} isEdit={isEdit}>
      {isEdit ? (
        <EditName {...props} toggle={toggle} />
      ) : (
        <ViewName {...props} />
      )}
    </SectionCard>
  );
};

export default NameCard;
