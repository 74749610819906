import React, { useState, useEffect } from "react";
import {
	FormGroup,
	Input,
	Col,
	Row,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	InputGroup,
	InputGroupAddon,
	Button,
} from "antstrap";
import { useSelector } from "react-redux";
import { useFetchRoles } from "../../redux/actions/roles";
import DropdownSearchBox from "./dropdown-search";

import PerfectScrollbar from "react-perfect-scrollbar";
const RolesDropdown = (props) => {
	const [dropdown, setDropdown] = useState(false);
	const toggle = () => setDropdown((prevState) => !prevState);
	const { fetchRoles, loading } = useFetchRoles();
	const roles = useSelector((state) => state.roles);
	const [searchString, setSearchString] = useState("");
	useEffect(() => {
		if (roles.length == 0) {
			fetchRoles();
		}
	}, [roles]);
	return (
		<Dropdown
			isOpen={dropdown}
			toggle={toggle}
			className="custom-dropdown mr-3"
		>
			<DropdownToggle color="light" className="border" caret>
				{props.isAdd ? (
					<Button type="button" color="primary" size="xs ">
						<i className="material-icons">add</i>
					</Button>
				) : (
					<p>{props.value != "" ? props.value.name : `All Roles`}</p>
				)}
			</DropdownToggle>
			<DropdownMenu className=" border-bottom py-3">
				<DropdownItem header className="py-0">Role</DropdownItem>

				<DropdownSearchBox
					searchString={searchString}
					setSearchString={setSearchString}
				/>
				<div className="border-bottom my-2"></div>
				<PerfectScrollbar>
					<div className="dropdown-menu-items">
						<DropdownItem onClick={() => props.onSelect(false)}>
							All{" "}
						</DropdownItem>
						{roles &&
							roles.map((role) => {
								if (searchString != "") {
									if (
										role.name.toLowerCase().search(searchString.toLowerCase()) >
										-1
									) {
										return (
											<DropdownItem
												onClick={() => props.onSelect(role)}
												value={role}
											>
												{role.name}
											</DropdownItem>
										);
									} else {
										return null;
									}
								} else {
									return (
										<DropdownItem
											onClick={() => props.onSelect(role)}
											value={role}
										>
											{role.name}
										</DropdownItem>
									);
								}
							})}
					</div>
				</PerfectScrollbar>
			</DropdownMenu>
		</Dropdown>
	);
};

export default RolesDropdown;
