import { GET_ACTIVITIES_SUCCESS } from "../actions/types";

const activityReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ACTIVITIES_SUCCESS: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default activityReducer;
