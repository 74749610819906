import { FETCH_ADMIN_SETTINGS } from "../actions/types";

const settingsReducer = (state = {}, action) => {
  if (action.type === FETCH_ADMIN_SETTINGS) {
    let newState = action.payload;
    return newState;
  }
  return state;
};

export default settingsReducer;
