import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, CardFooter, Col, FormGroup, Input, Row } from "reactstrap";
import { useAddPost } from "../../redux/actions/post";

const CreateNewPost = (props) => {
	const postNoteRef = useRef(null);
    const [postDetails, setPostDetails] = useState("");
    const [media, setMedia] = useState([]);
    const user = useSelector(state=>state.user)

	

	const handleSuccess = ()=>{
		if(props.isMobile){
			props.handleBackNavigation();
		}else{
			
		}
	  
	}

	const {addPost,loading} = useAddPost(handleSuccess);
	useEffect(() => {
        console.log(postNoteRef.current.clientHeight)
        postNoteRef.current.style.height="60px";
			postNoteRef.current.style.height = `${postNoteRef.current.scrollHeight}px`;
    }, [postDetails]);
    
    const createPost = ()=>{
        if(postDetails!="" ||media.length>0){
            
            let post = {
                userId:user._id,
                title:"",
                description:postDetails,
                media,
                visibility:1,
                suborgs:[]

            }
			addPost({variables:{post}})
			
			setPostDetails("");
			setMedia([]);
        }
    }
	return (
		<>
			<Row>
				<Col>
					<div className="d-flex py-2 post-input">
						<img
							className="image-rounded-md "
							src={require("assets/images/no-photo.svg")}
						/>
						<div className="d-flex input-area ">
							{/* <FormattedMessage id="assigned_to" /> */}
							<FormGroup>
								<Input
									innerRef={postNoteRef}
									type="textarea"
									name="text"
									id="exampleText"
									placeholder="Write something here..."
									value={postDetails}
									onChange={(e) => setPostDetails(e.target.value)}
								/>
							</FormGroup>
						</div>
					</div>
				</Col>
			</Row>
			<CardFooter className="bg-white d-flex justify-content-between border-0">
				<div className="d-flex post-actions  text-center ">
					<i class="material-icons mr-3 ">image</i>
					<i class="material-icons mr-3">attachment</i>
					<i class="material-icons mr-3">mood</i>
				</div>

				<div className="d-flex text-right">
					<i class="material-icons mr-3 ">visibility</i>
					<Button color="primary" onClick={() => {createPost()}}>
						Post
					</Button>{" "}
				</div>
			</CardFooter>
		</>
	);
};

export default CreateNewPost;
