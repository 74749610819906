import React, { useState } from "react";
import EditContact from "./edit-contact";
import ViewContact from "./view-contact";
import SectionCard from "../section-card";

const ContactCard = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const toggle = () => setIsEdit(!isEdit);
  return (
    <SectionCard toggle={toggle} title="Contact" isEdit={isEdit}>
      {isEdit ? (
        <EditContact {...props} toggle={toggle} />
      ) : (
        <ViewContact {...props} />
      )}
    </SectionCard>
  );
};

export default ContactCard;
