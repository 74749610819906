import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "antstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { SingleTicketCard } from "./single-ticket-card";
import { TicketSideMenu } from "./ticket-sidemenu";
import classnames from "classnames";
import { TicketDetailsComment, TicketUpdates } from "./ticket-updates1";
import { TicketMessage } from "./ticket-message";
import {
  useFetchTicketByStatusAndType,
  useFetchCommentsByTicketId,
} from "redux/actions/ticket";

import {
  useFetchPrivateNotesByTicketId,
  useFetchTeamDiscussionByTicketId,
  useAddTeamDiscussionComment,
  useAddPrivateNote
} from "redux/actions/helpdesk-tickets";
import { generateTicketNUmberToDisplay } from "./constants";
import { TicketInputMessage } from "./ticket-input-message";
import EditTicketPopup from "./edit-ticket-popup";
import { useFetchTagsByTicketId, useUpdateTags } from "../../redux/actions/helpdesk-tickets";
import { TagsInput } from "./tags-input";
import { Badge } from "reactstrap";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
export const Ticket = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [userInfo, setToggleUserInfo] = useState(false);
  const toggleUserInfo = ()=>{
    setToggleUserInfo(!userInfo)
  }
  const [ticket, setTicket] = useState(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const user = useSelector((state) => state.user);
  const { getTicketByStatusAndType } = useFetchTicketByStatusAndType();
  const { fetchCommentsByTicketId } = useFetchCommentsByTicketId();
  const { fetchPrivateNotesByTicketId } = useFetchPrivateNotesByTicketId();
  const { fetchTeamDiscussionByTicketId } = useFetchTeamDiscussionByTicketId();
  const { addTeamDiscussionComment } = useAddTeamDiscussionComment();
  const { addPrivateNote } = useAddPrivateNote();
  const { fetchTagsByTicketId } = useFetchTagsByTicketId();
  const { updateTags } = useUpdateTags();
  
  const userId = useSelector((state) => state.user._id);

  const tickets = useSelector((state) => (state.ticket ? state.ticket : []));

  const type=!isNaN(props.match.params.type)?Number(props.match.params.type):0;

  const updateTagsList=(tag)=>{
    let tags = ticket.tags?ticket.tags:[];
    tags.push(tag)
    updateTags({
      variables: {
        tags:tags,
        _id: `${props.match.params.id}`,
      },
    });
  
  }

  const removeTag=(index)=>{
    let tags = ticket.tags;
    tags.splice(index,1)
    updateTags({
      variables: {
        tags:tags,
        _id: `${props.match.params.id}`,
      },
    });
  }
  const handleTeamDiscussionReply=(message)=>{
    addTeamDiscussionComment({
      variables: {
        comment: message,
        _id: `${props.match.params.id}`,
        userId: user._id,
      },
    });
  
  }

  const handlePrivateMessageReply=(message)=>{
    addPrivateNote({
      variables: {
        comment: message,
        _id: `${props.match.params.id}`,
        userId: user._id,
      },
    });
  
  }
  useEffect(() => {
    //fix status and type
    getTicketByStatusAndType({
      variables: { status: 1, type: type },
    });
  }, []);

  useEffect(() => {
    setTicket(
      tickets.filter((ticket) => ticket._id === props.match.params.id)[0]
    );
    
  }, [tickets]);

  useEffect(() => {
    fetchCommentsByTicketId({
      variables: { _id: props.match.params.id },
    });
    fetchPrivateNotesByTicketId({
      variables: { _id: props.match.params.id, userId: userId },
    });
    fetchTeamDiscussionByTicketId({
      variables: { _id: props.match.params.id },
    });
    fetchTagsByTicketId({
      variables: { _id: props.match.params.id },
    });
  }, [ticket]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Row className="mainWrapper helpdesk-wrapper">
      <Col md={userInfo?9:12} className="border-right">
        <Row className="border-bottom mb-3 helpdesk-header bg-white">
          <Button
            className="btn-left btn-light border-0 px-4 bg-white"
            onClick={() => props.history.goBack()}
          >
            <i className="material-icons">arrow_back</i>
          </Button>
          <h5 className="ticket-title py-3 pl-4 mb-0">
            {`${
              ticket && generateTicketNUmberToDisplay(ticket.ticketNumber)
            } - ${ticket && ticket.title}`}
          </h5>
          <div className="user-info-toggler">
          <Button
          size="sm"
          className={userInfo?"toggle-active btn-light border-0  toggle-button":"btn-light border-0  toggle-button"}
            onClick={() => toggleUserInfo()}
          >
           <InfoOutlinedIcon/>
          </Button>
          </div>
        </Row>
        <Container fluid>
          {ticket && <SingleTicketCard ticket={ticket} toggle={toggle} type={type}/>}

          <Nav tabs className="tabs-antstrap">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                <FormattedMessage id="updates" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                <FormattedMessage id="team_discussion" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                <FormattedMessage id="private_notes" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                onClick={() => {
                  toggleTab("4");
                }}
              >
                <FormattedMessage id="tags" />
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="py-4">
              {ticket?<TicketDetailsComment ticket={ticket} index={0} lastIndex={false}/>:null}
              {ticket &&
                ticket.comments &&
                ticket.comments.map((comment,index) => (
                  <TicketUpdates comment={comment} index={index} lastIndex={index==ticket.comments.length-1}/>
                ))}

<TicketMessage ticketId={props.match.params.id} ticket={ticket} />
            </TabPane>
            <TabPane tabId="2" className="py-4">
              {ticket &&
                ticket.teamNotes &&
                ticket.teamNotes.map((comment,index) => (
                  <TicketUpdates comment={comment} index={index} lastIndex={index==ticket.teamNotes.length-1}/>
                ))}
                <TicketInputMessage ticketId={props.match.params.id} ticket={ticket} handleReply={handleTeamDiscussionReply} />
            </TabPane>
            <TabPane tabId="3" className="py-4">
              {ticket &&
                ticket.privateNotes &&
                ticket.privateNotes.map((comment,index) => (
                  <TicketUpdates comment={comment} index={index} lastIndex={index==ticket.privateNotes.length-1}/>
                ))}
                   <TicketInputMessage ticketId={props.match.params.id} ticket={ticket} handleReply={handlePrivateMessageReply} isPrivateNote={true}/>
            </TabPane>
            <TabPane tabId="4" className="py-4">
            {ticket &&
                ticket.tags &&
                ticket.tags.map((tag,index) => (
                  <Badge color="light mr-2 d-inline-flex ticket-tags tags" pill><span>{tag} </span> <i className="material-icons ml-2" onClick={()=>{removeTag(index)}}>close</i></Badge>
                ))}
             <TagsInput ticketId={props.match.params.id} ticket={ticket} updateTagsList={updateTagsList} ></TagsInput>
            </TabPane>
          </TabContent>
          
        </Container>
      </Col>
      <Col md={userInfo?3:0} className={"px-0"}>
        <TicketSideMenu ticket={ticket} />
      </Col>
      <EditTicketPopup toggle={toggle} modal={modal} ticket={ticket}/>
    </Row>
  );
};
