import { useLazyQuery, useMutation } from "react-apollo";
import { useDispatch } from "react-redux";
import { notify } from "../../components/common/notification";
import { ADD_HELPDESK_CATEGORY_MUTATION, EDIT_HELPDESK_CATEGORY_MUTATION, GET_ALL_HELPDESK_CATEGORIES_QUERY, REMOVE_HELPDESK_CATEGORY_MUTATION } from "../services/helpdesk-categories";
import { getHelpdeskCategoriesActions } from "./redux-actions/helpdesk-categories";


export const useFetchHelpdeskCategories = () => {
  const dispatch = useDispatch();
  const [getHelpdeskCategories, { loading }] = useLazyQuery(
    GET_ALL_HELPDESK_CATEGORIES_QUERY,
    {
      onCompleted(data) {
        if (data && data.getHelpdeskCategories) {
          dispatch(getHelpdeskCategoriesActions(data.getHelpdeskCategories));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { getHelpdeskCategories, loading };
};

export const useAddHelpdeskCategory = () => {
  const dispatch = useDispatch();
  const [addHelpdeskCategory, { loading }] = useMutation(
    ADD_HELPDESK_CATEGORY_MUTATION,
    {
      onCompleted(data) {
        if (data && data.addHelpdeskCategory) {
          dispatch(getHelpdeskCategoriesActions(data.addHelpdeskCategory));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { addHelpdeskCategory, loading };
};

export const useEditHelpdeskCategory = () => {
  const dispatch = useDispatch();
  const [editHelpdeskCategory, { loading }] = useMutation(
    EDIT_HELPDESK_CATEGORY_MUTATION,
    {
      onCompleted(data) {
        if (data && data.editHelpdeskCategory) {
          dispatch(getHelpdeskCategoriesActions(data.editHelpdeskCategory));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { editHelpdeskCategory, loading };
};

export const useRemoveHelpdeskCategory = () => {
  const dispatch = useDispatch();
  const [removeHelpdeskCategory, { loading }] = useMutation(
    REMOVE_HELPDESK_CATEGORY_MUTATION,
    {
      onCompleted(data) {
        if (data && data.removeHelpdeskCategory) {
          dispatch(getHelpdeskCategoriesActions(data.removeHelpdeskCategory));
        }
      },
      onError(error) {
        if (error) {
          notify(error.message, "danger");
        }
      },
      fetchPolicy: "no-cache",
    }
  );
  return { removeHelpdeskCategory, loading };
};
