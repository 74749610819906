import { Button, Col, FormGroup, Input, Row,Label } from "antstrap";
import React from "react";
import { FormattedMessage } from "react-intl";


const StepOne = (props) => {
  let { setSuborganizationLabel, subOrganizationLabel, toggleTab,data } = props;
  return (
    <>
      <Row className="pt-4">
        <Col sm="12">
        
          <FormGroup>
          <Label>What do you want your sub organizations to be called?</Label>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              value={JSON.stringify(subOrganizationLabel)}
              onChange={(e) =>{
                setSuborganizationLabel(JSON.parse(e.target.value))
              } }
            >
              {data.map((item)=>(
                <option value={JSON.stringify(item)}>{item.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Col className="py-2 pr-0 text-right border-top-0">
        <Button
          color="primary"
          onClick={() => {
            toggleTab("2");
          }}
        >
          <FormattedMessage id="next" defaultMessage="Next" />
        </Button>{" "}
      </Col>
    </>
  );
};

export default StepOne;
