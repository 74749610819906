import {
  FETCH_ORGANIZATION_USERS,
  ADD_ORGANIZATION_USER_SUCCESS,
  UPDATE_ORGANIZATION_USER_SUCCESS,
  FETCH_CHAT_USERS
} from "../types";

export const fetchOrganizationUsersAction = (users) => {
  return {
    type: FETCH_ORGANIZATION_USERS,
    payload: users,
  };
};

export const addOrganizationUserSuccess = (users) => {
  return {
    type: ADD_ORGANIZATION_USER_SUCCESS,
    payload: users,
  };
};

export const updateOrganizationUserSuccess = (users) => {
  return {
    type: UPDATE_ORGANIZATION_USER_SUCCESS,
    payload: users,
  };
};


export const fetchChatUsersAction = (users) => {
  return {
    type: FETCH_CHAT_USERS,
    payload: users,
  };
};
