import React from 'react';

const MobileActionButton = (props) => {
  return (
    <div className="mobile-action-button-container">
      <button className="mobile-action-button" onClick={props.onClick}><i className='material-icons'>add</i></button>
    </div>
  );
};

export default MobileActionButton;