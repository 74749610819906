import {
	Button,
	Col,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from "antstrap";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFetchRolesByOrganizationId } from "redux/actions/graphql-action";
import { useFetchOrganizationUsers } from "redux/actions/user";
import { useFetchSubOrganizations } from "../../redux/actions/sub-organization";
import AddUsers from "./add-user-modal";
import { UserTable } from "./user-table";
import { useFetchRoles } from "../../redux/actions/roles";
import { useHistory } from "react-router-dom";
import EmptyStateSection from "../common/empty-state-section";
import LoadingIndicator from "../common/loading-indicator";

export const Users = () => {
	const organizationId = useSelector((state) => state.user.organizationId);

	const { fetchOrganizationUsers,loading } = useFetchOrganizationUsers();

	const { fetchRoles } = useFetchRoles();
	const { fetchSubOrganizations } = useFetchSubOrganizations();

	useEffect(() => {
		fetchOrganizationUsers();
		fetchRoles();
		fetchSubOrganizations({
			variables: { organizationId: organizationId },
		});
	}, []);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);
	const [activeTab, setActiveTab] = useState("1");
	const toggleTab = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const users = useSelector((state) => state.users);

	let history = useHistory();
	const newUser = () => {
		history.push(`/user/new-user`);
	};
	return (
		<div className="bg-white subscriber-wrapper py-3 px-5">
			<Row className="py-3">
				<Col sm="6">
					<div className="d-flex align-items-center">
						<i className="material-icons page-icon">people_outline</i>
						<div>
							<h3 className="font-weight-normal mb-0">Users</h3>
							<small className="font-size-small">
								{users && users.length} users
							</small>
						</div>
					</div>
				</Col>
				<Col sm="6">
					<Button
						color="primary"
						className="ml-auto float-right"
						onClick={newUser}
					>
						Add User
					</Button>
				</Col>
			</Row>
			<Row>
				<Col sm="12">
					<Nav tabs className="tabs-antstrap">
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggleTab("1");
								}}
							>
								All Users
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggleTab("2");
								}}
							>
								Pending for Approval
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							{loading ? (
								<LoadingIndicator />
							) : users.length == 0 ? (
								<EmptyStateSection />
							) : (
								<UserTable user={users} />
							)}
						</TabPane>
						<TabPane tabId="2">
							<EmptyStateSection />
						</TabPane>
					</TabContent>
				</Col>
			</Row>

			<AddUsers modal={modal} toggle={toggle} />
		</div>
	);
};
