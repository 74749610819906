import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  CustomInput,
  FormGroup,
  Container,
} from "antstrap";

import UpdatePassword from "./update-password";

export const AccountSettings = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Container fluid>
      <Row className="py-4">
        <h5 className="w-100">Security Settings</h5>
        <p>These settings help you keep your account secure</p>
      </Row>
      <Row>
        <Col className="border">
          <Row className="border-bottom p-3 align-items-center">
            <Col md="6">
              <Row>
                <h6 className="pb-0">Change Password</h6>
              </Row>
              <Row>
                <p className="mb-0">
                  Set a unique password to protect your account.
                </p>
              </Row>
            </Col>
            <Col md="6">
              <Row className="d-flex justify-content-end">
               
                  <p className="mb-0 font-italic small color-light pt-1 mr-2">
                    Last Updated: 10 August 2000, 11:41 PM
                  </p>
        
               
                  <Button color="primary" onClick={toggle}>
                    Change Password
                  </Button>
               
              </Row>
            </Col>
          </Row>
          <Row className="p-3 align-items-center justify-content-between">
            <Col sm={10 } className="px-3 ">
              <Row>
                <h6 className="pb-0">2FA Authentication</h6>
              </Row>
              <Row>
                <p className="mb-0">When it is activated, 
                you will need to enter an sent to your email address 
                every time you login along with your password.
                </p>
              </Row>
            </Col>
            <Col sm={2} className="d-flex ">
              <CustomInput
                type="switch"
                id="exampleCustomSwitch"
                name="customSwitch"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <UpdatePassword modal={modal} toggle={toggle} />
    </Container>
  );
};
