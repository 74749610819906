import React from "react";
import { Row, Col, CardText } from "antstrap";

const ViewWebsite = ({ website }) => {
  return (
    <Row>
      <Col sm="12">
        <CardText>{website}</CardText>
      </Col>
    </Row>
  );
};

export default ViewWebsite;
