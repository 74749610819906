import {
  ADD_SUB_ORGANIZATION,
  FETCH_SUB_ORGANIZATIONS,
  EDIT_SUB_ORGANIZATION,
  DELETE_SUB_ORGANIZATION,
  ADD_CHILD_SUB_ORGANIZATION,
  GET_CHILD_SUB_ORGANIZATION,
  DELETE_CHILD_SUB_ORGANIZATION,
  EDIT_CHILD_SUB_ORGANIZATION,
} from "../types";

export const addSubOrganizationAction = (subOrganization) => {
  return {
    type: ADD_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const fetchSubOrganizations = (subOrganizations) => {
  return {
    type: FETCH_SUB_ORGANIZATIONS,
    payload: subOrganizations,
  };
};
export const editSubOrganizationAction = (subOrganization) => {
  return {
    type: EDIT_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const deleteSubOrganizationAction = (subOrganization) => {
  return {
    type: DELETE_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const addChildSubOrganizationAction = (childSubOrganization) => {
  return {
    type: ADD_CHILD_SUB_ORGANIZATION,
    payload: childSubOrganization,
  };
};

export const fetchChildSubOrganizationAction = (childSubOrganization) => {
  return {
    type: GET_CHILD_SUB_ORGANIZATION,
    payload: childSubOrganization,
  };
};

export const deleteChildSubOrganizationAction = (subOrganization) => {
  return {
    type: DELETE_CHILD_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};

export const editChildSubOrganizationAction = (subOrganization) => {
  return {
    type: EDIT_CHILD_SUB_ORGANIZATION,
    payload: subOrganization,
  };
};
