import { gql } from "apollo-boost";

export const GET_ALL_CHATS_QUERY = gql`
	query fetchChats($userId: String) {
		fetchChats(userId: $userId) {
			_id
			name
			userIds
			organizationId
			createdBy
			isPrivate
			createdAt
			updatedAt

			recentMessage {
				_id
				chatId
				userId
				message
				isRead
				isDelivered
				createdAt
				updatedAt
			}
			users {
				_id
				firstName
				lastName
				image
				isOnline
				countryCode
				mobileNumber
				fullNumber
				email
				status
			}
			chatReports{
				userId,
				unreadCount,
				lastReadMessageTime
			}
		}
	}
`;

export const GET_CHAT_BY_USERIDS_QUERY = gql`
	query fetchChatByUserIds($userIds: [String]) {
		fetchChatByUserIds(userIds: $userIds) {
			_id
			name
			userIds
			organizationId
			createdBy
			isPrivate
			createdAt
			updatedAt

			recentMessage {
				_id
				chatId
				userId
				message
				isRead
				isDelivered
				createdAt
				updatedAt
			}
			users {
				_id
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
			chatReports{
				userId,
				unreadCount,
				lastReadMessageTime
			}
		}
	}
`;

export const FETCH_CHAT_MESSAGES_QUERY = gql`
	query fetchChatMessages($userId: String, $chatId: String,$page:Int,$perPage:Int) {
		fetchChatMessages(userId: $userId, chatId: $chatId,page:$page,perPage:$perPage) {
			_id
			chatId
			userId
			message
			isRead
			isDelivered
			createdAt
			updatedAt
		}
	}
`;

export const SETUP_CHAT_MUTATION = gql`
	mutation setupChat($userId: String, $userIds: [String]) {
		setupChat(userId: $userId, userIds: $userIds) {
			_id
			name
			userIds
			organizationId
			createdBy
			isPrivate
			createdAt
			updatedAt

			recentMessage {
				_id
				chatId
				userId
				message
				isRead
				isDelivered
				createdAt
				updatedAt
			}
			users {
				_id
				firstName
				lastName
				image
				isOnline
				countryCode
				mobileNumber
				email
				status
			}

			chatReports{
				userId,
				unreadCount,
				lastReadMessageTime
			}
		}
	}
`;
