import React, { useRef, useEffect, useState } from "react";
import Sidebar from "./sidebar/main-sidebar";
import Navbar from "./navbar/main-navbar";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import routes, { tabs } from "./routes/routes";
import PerfectScrollbar from "perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setAuth } from "../redux/actions/user";
import { Ticket } from "../components/helpdesk/ticket";
import { checkIfSessionActive } from "../services/auth-service";
import { useFetchOrganizationDetails } from "../redux/actions/organization";
import { SET_USER } from "../redux/actions/types";
import NewUser from "../components/user-list/new-user";
import { UserProfile } from "../components/user-profile/user-profile";
import EditUser from "../components/user-list/edit-user";
import { useClearSocketConnection, useSocketListener } from "../redux/actions/socket";
import { UserRequest } from "../components/helpdesk/my-space/ticket";
import { Nav, NavItem, NavLink } from "reactstrap";
import MobileBottomTabs from "./bottom-tabs/mobile-bottom-tabs";
import MobileNavbar from "./navbar/mobile-navbar";
import { MobileCreatePost } from "../components/posts/mobile-create-post";

let ps;

const MobileDashboard = (props) => {
  const mainPanelRef = useRef(null);

  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //const userFromState = useSelector((state) => state.user);
  const { fetchOrganizationDetails, loading } = useFetchOrganizationDetails();

  const socketListener = useSocketListener();
  const socketDisconnector = useClearSocketConnection();

  useEffect(() => {
    if (Object.keys(user).length == 0) {
      let user = checkIfSessionActive();
      fetchOrganizationDetails();
      if (user && user.token && user.token != "") {
        dispatch({ type: SET_USER, user: user.user });
      } else {
        history.push("/auth");
      }
    }
  }, [user]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      if (mainPanelRef.current != null) {
        ps = new PerfectScrollbar(mainPanelRef.current);
      }
    }
    console.log("i am here ")
    socketListener()

    return function cleanup() {
      socketDisconnector()
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);

  // useEffect(() => {
  //   if (userFromState._id) {
  //     // fetchOrganizationDetails();
  //     console.log(userFromState);
  //   }
  // }, [userFromState]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      // if (prop.layout === '/admin') {
      //   return (
      //     <Route
      //       path={prop.layout + prop.path}
      //       component={prop.component}
      //       key={key}
      //     />
      //   );
      // } else {
      //   return null;
      // }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  return (
    <div className="main-wrapper mobile-wrapper">
      <Sidebar {...props} routes={routes} isMobile={true}></Sidebar>
      <div className="main-panel" ref={mainPanelRef}>
        <MobileNavbar></MobileNavbar>
        <div className="content">
          <Switch>
            {getRoutes(routes)}
            <Route
              exact
              path="/basic/ticket/:type/:id"
              render={(props) => <Ticket {...props} />}
            ></Route>
            <Route
              exact
              path="/basic/my-space/:type/:id"
              render={(props) => <UserRequest {...props} />}
            ></Route>
            <Route
              exact
              path="/user/new-user"
              render={(props) => <NewUser {...props} />}
            ></Route>
            <Route
              exact
              path="/user/edit-user"
              render={(props) => <EditUser {...props} />}
            ></Route>
            <Route
              exact
              path="/basic/profile"
              render={(props) => <UserProfile {...props} />}
            ></Route>
            <Route
              exact
              path="/basic/new-post"
              render={(props) => <MobileCreatePost {...props} />}
            ></Route>
            <Redirect to="/basic/posts"></Redirect>
          </Switch>
        </div>
        <MobileBottomTabs tabs={tabs}/>
      </div>
    </div>
  );
};

export default MobileDashboard;
