import { Col } from "antstrap";
import React, { useEffect, useState } from "react";
import PostMenuButton from "../common/post-menu-button";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Button, Row } from "reactstrap";
import LoadingIndicator from "../common/loading-indicator";

const ChatHeader = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (props.chat && props.chat.users) {
      let filteredUsers = props.chat.users.filter(
        (user) => user._id != props.currentUser._id
      );
      setUsers(filteredUsers);
    }
  }, [props.chat]);

  const renderChatName = () => {
    if (props.chat == null) {
      return props.user.firstName + " " + props.user.lastName;
    } else if (props.chat.name != "") {
      return props.chat.name;
    } else if (props.chat.users.length > 2) {
      return (
        users[0].firstName + " " + users[0].lastName + ", +" + users.length
      );
    } else {
      return users[0].firstName + " " + users[0].lastName;
    }
  };
  return (
    <>
      <Row className="bg-white border-bottom border-top d-flex align-items-center no-gutters w-100 chat-header">
        <Col sm={6} className="d-flex flex-row align-items-center cursor-pointer left-section" onClick={() => props.toggleUserInfo()}>
          <Button
            className="btn-light border-0 px-3 bg-white back-button"
            onClick={() => props.closeChat()}
          >
            <i className="material-icons">arrow_back</i>
          </Button>
          <div className="chat-thumbnail">
            <img
              className="chat-user-image ml-3 mr-1"
              src={require("assets/images/no-photo.svg")}
            />
            <div className={users && users.length && users[0].isOnline ? 'online' : 'offline'}></div>
          </div>
          <h6 className="m-0 ml-2 font-weight-bold">
            {users.length || props.chat == null ? (
              renderChatName()
            ) : (
              <LoadingIndicator />
            )}
          </h6>
        </Col>

        <Col sm={6} className="m-0 d-flex justify-content-end align-items-center right-section">
          {/* <div className=""> */}
          <div className="mr-3 d-flex">
            <PostMenuButton menuButtons={[]} />
          </div>


          <div className="user-info-toggler d-flex">
            <Button
              size="sm"
              className={
                props.userInfo
                  ? "toggle-active btn-light border-0 toggle-button"
                  : "btn-light border-0  toggle-button"
              }
              onClick={() => props.toggleUserInfo()}
            >
              <InfoOutlinedIcon />
            </Button>
          </div>
          {/* </div> */}
        </Col>
      </Row>
    </>
  );
};

export default ChatHeader;
