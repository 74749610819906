import React from "react";
import { createHashHistory, createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./layout/dashboard";
import ApolloClient from "apollo-boost";
import { Provider } from "react-redux";
import { ApolloProvider } from "react-apollo";
import NotificationAlert from "./components/common/notification";
import { configureStore } from "./redux/store/store";
import AdminDashboard from "./layout/admin-dashboard";
import AuthContainer from "./views/auth";
import { checkIfSessionActive, logout } from "./services/auth-service";
import WebNotification from "./components/common/web-notification";
import { isMobile } from 'react-device-detect';
import "./App.css";
import "./App.mobile.css"
import MobileDashboard from "./layout/mobile-dashboard";
const getApolloClient = () => {
  const user = checkIfSessionActive();

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_URL}/org/v1/graphql`,
    headers: {
      authorization: user && user.token ? `Bearer ${user.token}` : "",
    },
    onError: (data) => {
      console.log("sfsfsdfdsfdsfdsfdsffdfsfsdfdsfsdfsdf", data);

      if (
        data &&
        data.response &&
        data.response.errors &&
        data.response.errors[0].message == "Error: Invalid Credentials"
      ) {
        console.log("herer ererere");
        logout();
        window.location.replace("/");
      }
    },
  });
  return client;
};
const store = configureStore();
const hist = createBrowserHistory();
const MainApp = () => {
  return (
    <div className="App">
      <Router history={hist}>
        <Switch>
          <Route
            path="/auth"
            render={(props) => (
              <Provider store={store}>
                <AuthContainer {...props} />
              </Provider>
            )}
          ></Route>
          <Route
            path="/admin"
            render={(props) => (
              <Provider store={store}>
                <ApolloProvider client={getApolloClient()}>
                  <AdminDashboard {...props} />
                  <NotificationAlert />
                </ApolloProvider>
              </Provider>
            )}
          ></Route>
          <Route
            path="/"
            render={(props) => (
              <Provider store={store}>
                <ApolloProvider client={getApolloClient()}>
                  {!isMobile ?
                    <Dashboard {...props} /> : <MobileDashboard {...props} />}
                  <NotificationAlert />
                  <WebNotification />
                </ApolloProvider>
              </Provider>
            )}
          ></Route>
        </Switch>
      </Router>
    </div>
  );
};

export default MainApp;
