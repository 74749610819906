import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSetActiveChat } from "../../../redux/actions/chat";
import LoadingIndicator from "../../common/loading-indicator";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import { useDeliveredMessagesHandler } from "../../../redux/actions/socket";
const ChatUserListSingle = (props) => {
  let messageClasses = " ";
  if (props.read) {
    messageClasses = "m-0 message-light";
  } else {
    messageClasses = "m-0 message-light";
  }

  const [users, setUsers] = useState([]);
  const activeChat = useSelector(state => state.activeChat);
  const setActiveChat = useSetActiveChat();
  const sendDeliveryReport = useDeliveredMessagesHandler()
  useEffect(() => {
    let filteredUsers = props.chat.users.filter(user => user._id != props.currentUser._id);
    setUsers(filteredUsers)
  }, [props.chat])

  useEffect(() => {
    sendDeliveryReport(props.chat._id, props.currentUser._id)
  }, [])

  const renderChatName = () => {

    if (props.chat.name != "") {
      return props.chat.name
    } else if (props.chat.users.length > 2) {
      return users[0].firstName + " " + users[0].lastName + ", +" + users.length
    } else {
      return users[0].firstName + " " + users[0].lastName
    }
  }

  const renderUnreadCount = () => {
    if (props.chat.chatReports && props.chat.chatReports.length) {
      let report = props.chat.chatReports.filter((id) => id.userId == props.currentUser._id);
      if (report && report.length && report[0].unreadCount > 0) {
        return <span className="badge badge-new">{report[0].unreadCount}</span>
      }
    }
    return null
  }

  return (
    <Row className={classnames("chat-user-list-item m-0", {
      active: props.chat && activeChat && props.chat._id == activeChat._id,
    })} onClick={() => setActiveChat(props.chat)}>
      <Col className={"d-flex flex-row flex-1 p-1 left-data"}>
        <div className="chat-thumbnail">
          <img
            className="chat-user-image mr-3"
            src={require("assets/images/no-photo.svg")}
          />
          <div className={users && users.length && users[0].isOnline ? 'online' : 'offline'}></div>
        </div>

        <div className="d-flex flex-column chat-user-name">
          <p className="m-0  user-name text-ellipsis">{users.length ? renderChatName() : <LoadingIndicator />}</p>
          <p className={messageClasses}>{props.chat.recentMessage ? props.chat.recentMessage.message : ""}</p>
        </div>
      </Col>
      <Col className={"d-flex flex-column right-data p-1 justify-content-end"}>
        <div className="px-2 text-right">
          <p className="m-0 wrap-text">
            <small>{props.chat.recentMessage ? moment(new Date(Number(props.chat.recentMessage.createdAt))).format("LT") : ""}</small>
          </p>
          {/* <span className="badge badge-new">{props.count}</span> */}
          {renderUnreadCount()}
        </div>
      </Col>
    </Row>
  );
};

export default ChatUserListSingle;
