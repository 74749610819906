import React, { useState } from "react";
import { CardBody, Col, FormGroup, CustomInput, Row } from "reactstrap";

const SettingsSection = (props) => {
  return (
    <CardBody className={props.className}>
      <Row className="align-items-center">
        <Col xs={10}>
          <h6>{props.title}</h6>
          <p>{props.subTitle}</p>
        </Col>
        <Col xs={2} className="text-right">
          <FormGroup>
            <CustomInput
              type="switch"
              className="custom-antstrap-switch"
              id={props.id}
              name="customSwitch"
              checked={props.value}
              onChange={(e) => props.onChange(props.id, e.target.checked)}
            />
          </FormGroup>
        </Col>
      </Row>
    </CardBody>
  );
};

export default SettingsSection;
