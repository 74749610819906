import { gql } from "apollo-boost";

export const GET_ORGANIZATION_USERS = gql`
	query getUsers {
		getUsers {
			_id
			userId
			rolesDetails {
				name
				_id
			}
			roles
			isOrganizationEmail
			isSuperAdmin
			subOrganizations
			organizationId
			status
			user {
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const GET_OTHER_ORGANIZATION_USERS = gql`
	query getOtherUsers($userId:String) {
		getOtherUsers(userId:$userId) {
			_id
			userId
			isOnline
			user {
        _id
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;

export const ADD_ORGANIZATION_USER = gql`
	mutation addUser(
		$userId: String
		$subOrganizations: [String]
		$roles: [String]
		$firstName: String!
		$lastName: String!
		$countryCode: String
		$mobileNumber: String
		$email: String
	) {
		addUser(
			userId: $userId
			subOrganizations: $subOrganizations
			roles: $roles
			firstName: $firstName
			lastName: $lastName
			countryCode: $countryCode
			mobileNumber: $mobileNumber
			email: $email
		) {
			_id
			userId
			rolesDetails {
				name
				_id
			}
			roles
			isOrganizationEmail
			isSuperAdmin
			subOrganizations
			organizationId
			status
			user {
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;
export const UPDATE_ORGANIZATION_USER = gql`
	mutation updateUserProfile(
		$userId: String!
		$subOrganizations: [String]
		$roles: [String]
		$firstName: String!
		$lastName: String!
		$countryCode: String
		$mobileNumber: String
	) {
		updateUserProfile(
			userId: $userId
			subOrganizations: $subOrganizations
			roles: $roles
			firstName: $firstName
			lastName: $lastName
			countryCode: $countryCode
			mobileNumber: $mobileNumber
		) {
			_id
			userId
			rolesDetails {
				name
				_id
			}
			roles
			isOrganizationEmail
			isSuperAdmin
			subOrganizations
			organizationId
			status
			user {
				firstName
				lastName
				image
				countryCode
				mobileNumber
				email
				status
			}
		}
	}
`;
// export const UPDATE_ORGANIZATION_USER = gql`
//   mutation updateOrganizationUser(
//     $organizationId: String
//     $subOrganizationId: String
//     $_id: String
//     $roleId: String
//     $firstName: String
//     $lastName: String
//     $image: String
//     $countryCode: String
//     $mobileNumber: String
//     $email: String
//   ) {
//     updateOrganizationUser(
//       organizationId: $organizationId
//       subOrganizationId: $subOrganizationId
//       _id: $_id
//       roleId: $roleId
//       firstName: $firstName
//       lastName: $lastName
//       image: $image
//       countryCode: $countryCode
//       mobileNumber: $mobileNumber
//       email: $email
//     ) {
//       _id
//       subOrganizationId
//       role {
//         name
//       }
//       firstName
//       lastName
//       image
//       countryCode
//       mobileNumber
//       email
//       status
//     }
//   }
// `;

export const CHANGE_ORGANIZATION_USER_STATUS = gql`
	mutation changeOrganizationUserStatus(
		$organizationId: String
		$_id: String
		$status: Int
	) {
		changeOrganizationUserStatus(
			organizationId: $organizationId
			_id: $_id
			status: $status
		) {
			organizationId
			_id
		}
	}
`;

export const LOGOUT_USER_FROM_CURRENT_SESSION = gql`
	mutation logoutFromCurrentSession{
		logoutFromCurrentSession{
			_id
			sessionId
			isExpired
			organizationId
			sessionId
		}
	}
`;
