import { gql } from "apollo-boost";

export const GET_SUBORGANIZATIONS = gql`
  query getSubOrganizations {
    getSubOrganizations {
      _id
      organizationId
      name
      description
      childSubOrganization {
        _id
        name
        description
      }
    }
  }
`;

export const ADD_SUBORGANIZATION = gql`
  mutation addSubOrganization(
    $name: String
    $description: String
    $childSubOrganization: [ChildSubOrganizationInput]
  ) {
    addSubOrganization(
      name: $name
      description: $description
      childSubOrganization: $childSubOrganization
    ) {
      _id
      organizationId
      name
      description
      childSubOrganization {
        name
        description
      }
    }
  }
`;

export const ADD_CHILD_SUBORGANIZATION = gql`
  mutation addChildSubOrganization(
    $subOrganizationId: String
    $childSubOrganization: ChildSubOrganizationInput
  ) {
    addChildSubOrganization(
      subOrganizationId: $subOrganizationId
      childSubOrganization: $childSubOrganization
    ) {
      organizationId
      _id
      name
      description
      childSubOrganization {
        _id
        name
        description
      }
    }
  }
`;

export const EDIT_SUBORGANIZATION = gql`
  mutation updateSubOrganization(
    $subOrganizationId: String
    $name: String
    $description: String
  ) {
    updateSubOrganization(
      subOrganizationId: $subOrganizationId
      name: $name
      description: $description
    ) {
      organizationId
      _id
      name
      description
      childSubOrganization {
        _id
        name
        description
      }
    }
  }
`;

export const DELETE_SUBORGANIZATION = gql`
  mutation removeSubOrganization($subOrganizationId: String) {
    removeSubOrganization(subOrganizationId: $subOrganizationId) {
      _id
      organizationId
    }
  }
`;

export const EDIT_CHILD_SUB_ORGANIZATION = gql`
  mutation updateChildSubOrganization(
    $subOrganizationId: String
    $childSubOrganization: ChildSubOrganizationInput
  ) {
    updateChildSubOrganization(
      subOrganizationId: $subOrganizationId
      childSubOrganization: $childSubOrganization
    ) {
      _id
      organizationId
      name
      description
      childSubOrganization {
        _id
        name
        description
      }
    }
  }
`;

export const DELETE_CHILD_SUB_ORGANIZATION = gql`
  mutation removeChildSubOrganization(
    $subOrganizationId: String
    $childSubOrganizationId: String
  ) {
    removeChildSubOrganization(
      subOrganizationId: $subOrganizationId
      childSubOrganizationId: $childSubOrganizationId
    ) {
      _id
      organizationId
      name
      description
      childSubOrganization {
        _id
        name
        description
      }
    }
  }
`;
